
<div class=" ">
    <app-header></app-header>
    <p style="margin-bottom: 13px;text-align: center;color: black;background: black;
    padding: 10px;color: white;"><span style="font-size: 28px;margin-bottom: 5px;
    font-weight: 500;border-radius: 10px;">Planned Outage Details</span>
 </p>
</div>
<!-- ================== filter block ================== -->
<div class="roaster-main">
    <div class="example-container">
        <form [formGroup]="range">
            <!-- ============ discom ============== -->
            <mat-form-field appearance="fill">
                <mat-label>DISCOM</mat-label>
                <mat-select formControlName="discom_name" (selectionChange)="getZone($event)">
                  <mat-option *ngFor="let item of discomList" value="{{item.value}}">{{item.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <!-- ============ ZONE ================ -->
            <mat-form-field appearance="fill">
                <mat-label>ZONE</mat-label>
                <mat-select formControlName="zone_name" (selectionChange)="selectedzone($event)">
                  <mat-option value="ALL">ALL</mat-option>
                  <mat-option *ngFor="let item of zoneData" value="{{item}}">{{item}}</mat-option>
                </mat-select>
            </mat-form-field>
            <!-- ============ CIRCLE ================ -->
            <mat-form-field appearance="fill">
                <mat-label>CIRCLE</mat-label>
                <mat-select formControlName="circle_name" (selectionChange)="selectedcircle($event)">
                  <mat-option value="ALL">ALL</mat-option>
                  <mat-option *ngFor="let item of cirlcleListData" value="{{item}}">{{item}}</mat-option>
                </mat-select>
            </mat-form-field>
            <!-- ============ DIVISION =============== -->
            <mat-form-field appearance="fill">
                <mat-label>DIVISION</mat-label>
                <mat-select formControlName="division_name" (selectionChange)="selecteddivision($event)">
                  <mat-option value="ALL">ALL</mat-option>
                  <mat-option *ngFor="let item of divisionListData" value="{{item}}">{{item}}</mat-option>
                </mat-select>
            </mat-form-field>
            <!-- ================= SUB DIVISION =================== -->
            <mat-form-field appearance="fill">
                <mat-label>SUB DIVISION</mat-label>
                <mat-select formControlName="sub_division_name" (selectionChange)="selectedsubdivision($event)">
                  <mat-option value="ALL">ALL</mat-option>
                  <mat-option *ngFor="let item of subdivisionListData" value="{{item}}">{{item}}</mat-option>
                </mat-select>
            </mat-form-field>
            <!-- ============ SUBSTATION ============ -->
            <mat-form-field appearance="fill">
                <mat-label>SUBSTATION</mat-label>
                <mat-select formControlName="substation_name" (selectionChange)="selectedstation($event)">
                  <mat-option value="ALL">ALL</mat-option>
                  <mat-option *ngFor="let item of substationListData" value="{{item}}">{{item}}</mat-option>
                </mat-select>
            </mat-form-field>
            <!-- =========== FEEDER =========== -->
            <mat-form-field appearance="fill">
                <mat-label>FEEDER</mat-label>
                <mat-select formControlName="feeder_name">
                  <mat-option value="ALL">ALL</mat-option>
                  <mat-option *ngFor="let item of feederData" value="{{item}}">{{item}}</mat-option>
                </mat-select>
            </mat-form-field>
            <!-- =========== OUTAGE TYPE ========== -->
            <mat-form-field appearance="fill">
                <mat-label>OUTAGE TYPE</mat-label>
                <mat-select formControlName="outage_type">
                  <mat-option value="ALL">ALL</mat-option>
                  <mat-option value="MAINTAINENCE">MAINTAINENCE</mat-option>
                  <mat-option value="OTHERS">OTHERS</mat-option>
                  
                </mat-select>
            </mat-form-field>
            <!-- ============ FROM DATE =============== -->
            <mat-form-field appearance="fill">
              <mat-label>FROM DATE END TIME</mat-label>
              <input matInput [matDatepicker]="picker" formControlName="start">
              <mat-datepicker-toggle matSuffix [for]="picker" ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <!-- =========== TO DATE ============== -->
            <mat-form-field appearance="fill">
              <mat-label>TO DATE END TIME</mat-label>
              <input matInput [matDatepicker]="picker1" formControlName="end">
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
              <button mat-stroked-button color="primary" style="margin-left:10px" (click)="getRoasterDetails()">SHOW</button>
        </form>
      </div>
</div>

<!-- ========================== add new record button ================== -->
<button mat-raised-button color="primary" style="background-color: #34495E;margin-left:10px" (click)="addRoaster()">ADD NEW PLANNED OUTAGE</button>
<!-- ========================== show roaster list ====================== -->
<div class="roasterList" style="margin:10px;width: 97%; max-width: 100vw; overflow-x: scroll;" *ngIf="dataShow">
  <button mat-raised-button color="primary" style="background-color: #34495E; margin-bottom: 5px;" (click)="downloadexce()">Export Excel</button>
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="DISCOM">
        <th mat-header-cell *matHeaderCellDef> DISCOM </th>
        <td mat-cell *matCellDef="let element"> {{element.DISCOM}} </td>
      </ng-container>
      <!-- Position Column -->
      <ng-container matColumnDef="ZONE">
        <th mat-header-cell *matHeaderCellDef> ZONE </th>
        <td mat-cell *matCellDef="let element"> {{element.ZONE}} </td>
      </ng-container>
  
      <!-- Name Column -->
      <ng-container matColumnDef="CIRCLE">
        <th mat-header-cell *matHeaderCellDef> CIRCLE </th>
        <td mat-cell *matCellDef="let element"> {{element.CIRCLE}} </td>
      </ng-container>
  
      <!-- Weight Column -->
      <ng-container matColumnDef="DIVISION">
        <th mat-header-cell *matHeaderCellDef> DIVISION </th>
        <td mat-cell *matCellDef="let element"> {{element.DIVISION}} </td>
      </ng-container>
  
      <!-- Symbol Column -->
      <ng-container matColumnDef="SUB_DIVISION">
        <th mat-header-cell *matHeaderCellDef> SUB_DIVISION </th>
        <td mat-cell *matCellDef="let element"> {{element.SUB_DIVISION}} </td>
      </ng-container>

      <!-- SUBSTATION -->
      <ng-container matColumnDef="SUB_STATION">
        <th mat-header-cell *matHeaderCellDef> SUB_STATION </th>
        <td mat-cell *matCellDef="let element"> {{element.SUB_STATION}} </td>
      </ng-container>

      <!-- METER NUMBER -->
      <ng-container matColumnDef="METER_NUMBER">
        <th mat-header-cell *matHeaderCellDef> METER_NUMBER </th>
        <td mat-cell *matCellDef="let element"> {{element.METER_NUMBER}} </td>
      </ng-container>
      <!-- OUTAGE TYPE -->
      <ng-container matColumnDef="TYPE">
        <th mat-header-cell *matHeaderCellDef> OUTAGE TYPE </th>
        <td mat-cell *matCellDef="let element"> {{element.TYPE}} </td>
      </ng-container>
      <!-- FROM TIME -->
      <ng-container matColumnDef="FROM_DATE">
        <th mat-header-cell *matHeaderCellDef> FROM_DATE </th>
        <td mat-cell *matCellDef="let element"> {{element.FROM_DATE}} </td>
      </ng-container>

      <!-- TO TIME -->
      <ng-container matColumnDef="TO_DATE">
        <th mat-header-cell *matHeaderCellDef> TO_DATE </th>
        <td mat-cell *matCellDef="let element"> {{element.TO_DATE}} </td>
      </ng-container>

      <!-- REMARK -->
      <ng-container matColumnDef="REMARK">
        <th mat-header-cell *matHeaderCellDef> REMARK </th>
        <td mat-cell *matCellDef="let element"> {{element.REMARK}} </td>
      </ng-container>
      <!-- PROFILE ID -->
      <ng-container matColumnDef="PROFILE_ID">
        <th mat-header-cell *matHeaderCellDef> PROFILE_ID </th>
        <td mat-cell *matCellDef="let element"> {{element.PROFILE_ID}} </td>
      </ng-container>
      <!-- REASON -->
      <ng-container matColumnDef="REASON">
        <th mat-header-cell *matHeaderCellDef> REASON </th>
        <td mat-cell *matCellDef="let element"> {{element.REASON}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
    </table>
    <mat-paginator  #paginatorPageSize   [pageSizeOptions]="pageSizes"   showFirstLastButtons></mat-paginator>
  </div>
</div>