import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Chart } from 'angular-highcharts';
import { CommonService } from '../common.service';
import { AuthService} from '../auth.service';
import { FeederfilterllistComponent } from '../feederfilterllist/feederfilterllist.component';

@Component({
  selector: 'app-serviceprovider-chart',
  templateUrl: './serviceprovider-chart.component.html',
  styleUrls: ['./serviceprovider-chart.component.scss']
})
export class ServiceproviderChartComponent implements OnInit {
  zone_name: any;
  division_name: any;
  circle_name: any;
  subdivision_name: any;
  substation_name: any;
  project_name: any;
  chart_content_type: any;
  RECArray: any = [];
  SECUREArray: any = [];
  RADIUSArray: any = [];
  chart_name: any;
  popupData: any = [];

  constructor(public dialogRef: MatDialogRef<ServiceproviderChartComponent>,public Service:CommonService,public AuthService:AuthService,public dialog:MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      this.chart_content_type = data
    }
    useDefault: boolean = false;
    serviceproviderType='ALL';
    serviceProvider = [
      { name: "ALL", value: "ALL" },
      { name: "REC", value: "REC" },
      { name: "SECURE", value: "SECURE" },
      { name: "RADIUS", value: "RADIUS" }
    ]
    discomType=this.Service.getdiscom
    selecttype='OUTGOING'
    chart1 = new Chart({
      chart: {
        margin: 0,
        marginRight: 120,
        renderTo: 'chart',
        type: 'pie',
        height: 220,
        backgroundColor: 'transparent'
      },
      title: {
        useHTML: true,
        text: '800',
        verticalAlign: 'middle',
        margin: 0,
        style: {
          color: '#fff',
          font: 'bold 16px "Trebuchet MS", Verdana, sans-serif',
          paddingRight: '120px'
        },
      },
      xAxis: {
        minPadding: 0,
        maxPadding: 0
      },
      yAxis: {
        title: {
          text: '',
        }
      },
      legend: {
        align: 'right',
        verticalAlign: 'middle',
        layout: 'vertical',
        itemStyle: {
          color: '#fff',
          width: 100
        }, labelFormatter: function () {
          var p = 22 + '<br>' + this.name;
          return p;
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          colors: [
            '#6EBB49',
            '#ED2F0D',
            '#F4FA10',
            '#3258DD'
          ],
        }
      },
      tooltip: {
        formatter: function () {
          return '<b>' + this.point.name + '</b>: ' + this.y + ' %';
        }
      },
      series: [{
        type: 'pie',
        name: 'Browsers',
        data: [["REC", 6], ["SECURE", 7], ["RADIUS", 7]],
        size: '100%',
        innerSize: '50%',
        showInLegend: true,
        dataLabels: {
          enabled: false
        },
        point: {
          events: {
            click: function (event) {
              alert("dsad");
            }
          }
        }
      }]
    });

    customSeries: any;
  chart = new Chart({
    chart: {
      margin: 0,
      marginRight: 120,
      renderTo: 'chart',
      type: 'pie',
      height: 220,
      backgroundColor: 'transparent'
    },
    title: {
      useHTML: true,
      text: '',
      verticalAlign: 'middle',
      margin: 0,
      style: {
        color: '#fff',
        font: 'bold 16px "Trebuchet MS", Verdana, sans-serif',
        paddingRight: '120px'
      },
    },
    xAxis: {
      minPadding: 0,
      maxPadding: 0
    },
    yAxis: {
      title: {
        text: '',
      }
    },
    legend: {
      align: 'right',
      verticalAlign: 'middle',
      layout: 'vertical',
      itemStyle: {
        color: '#fff',
        width: 100
      }, labelFormatter: function () {
        var p = 22 + '<br>' + this.name;
        return p;
      },
    },
    plotOptions: {
      pie: {
        shadow: false,
        colors: [
          '#6EBB49',
          '#ED2F0D',
          '#F4FA10',
          '#3258DD'
        ],
      }
    },
    tooltip: {
      formatter: function () {
        return '<b>' + this.point.name + '</b>: ' + this.y;
      }
    },
    series: []
  });

  ngOnInit(): void {
    this.dialogRef.updatePosition({ top: `20px`,
    left: `30px`});
    /**
     * define hierarchies for service provider api
     */
    this.discomType=this.Service.getdiscom
    this.zone_name=this.Service.getzone
    this.circle_name=this.Service.getcircle
    this.division_name=this.Service.getdivision
    this.subdivision_name=this.Service.getsubdivision
    this.substation_name=this.Service.getsubstation
    this.project_name=this.Service.getscheme
    this.selecttype='ALL'
    if(this.chart_content_type == 'DOWN'){
      this.chart_name = 'DOWN FEEDERS 1Hr'
      this.getDownFeederChart()
    }
    else if(this.chart_content_type == 'SERVICE'){
      this.getServiceProviderCount()
      this.chart_name = 'SERVICE PROVIDER'
    }
   
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  
  getServiceProviderCount() {
    this.AuthService.getServiceProviderCount(this.discomType,this.zone_name,this.circle_name,this.division_name,this.subdivision_name,this.substation_name,this.project_name,this.selecttype).subscribe(res => {
      let data: any = []
      if (this.useDefault) {
        data = [["UP Feeders", res.count.UP], ["Down Feeders", res.count.DN], ["Modem <br>Not Communicating ", res.count.NC], ["Not In <br> Service ", res.count.NP]]
      } else {
        data = [["UP Feeders", res.count.UP], ["Down Feeders", res.count.DN], ["Modem <br>Not Communicating ", res.count.NC]]
      }
      this.customSeries = [{
        type: 'pie',
        name: 'Feeders',
        data: data,
        size: '100%',
        innerSize: '50%',
        showInLegend: true,
        dataLabels: {
          enabled: false
        }, events: {
          click: (event: any) => {
            let dataJson: any
            if (event.point.name == "UP Feeders") {
              dataJson = {
                serviceprovider:this.selecttype,
                state: 'UP',
                from: 'service'
              }
            } else if (event.point.name == "Down Feeders") {
              dataJson = {
                serviceprovider:this.selecttype,
                state: 'DN',
                from: 'service'
              }
            } else {
              dataJson = {
                serviceprovider:this.selecttype,
                state: 'NC',
                from: 'service'
              }
            }
            this.openDialogfilter(dataJson);
          }
        }
      }]
      if (this.useDefault) {
        this.chart1Function(this.customSeries, res.count.UP + res.count.DN + res.count.NC + res.count.NP)

      } else {
        this.chart1Function(this.customSeries, res.count.UP + res.count.DN + res.count.NC)
      }
      /**
       * call scheme donut chart
       */
    })
  }
  /**
   * convert keys in upper ccase
   */
  UpCase(obj:any){
    var key, keys = Object.keys(obj);
    var n = keys.length;
    var newobj :any={}
    while (n--) {
      key = keys[n];
      newobj[key.toUpperCase()] = obj[key];
    }
    console.log(newobj)
    return newobj 
  }
  /**
   * get down feeder list
   */
   getDownFeederChart() {
    this.AuthService.getDownFeederList(this.discomType,this.zone_name,this.circle_name,this.division_name,this.subdivision_name,this.substation_name,this.project_name,this.selecttype).subscribe(res => {
      console.log(res.Data.length)
      /**
       * reset previously array
       */
       this.RADIUSArray = []
       this.SECUREArray = []
       this.RECArray = []
      /**
       * create count for down feeder
       */
      for(let i=0;i<res.Data.length;i++){
      if(res.Data[i]['SERVICE_PROVIDER'] == 'REC'){
       this.RECArray.push(this.UpCase(res.Data[i]))
      }
      else if(res.Data[i]['SERVICE_PROVIDER'] == 'SECURE'){
        this.SECUREArray.push(res.Data[i])
      }
      else if(res.Data[i]['SERVICE_PROVIDER'] == 'RADIUS'){
        this.RADIUSArray.push(res.Data[i])
      }
      }
      let data: any = []
      if(this.selecttype == 'REC'){
        if (this.useDefault) {
          data = [["UP Feeders",0],["Down Feeders",  this.RECArray.length],["Modem <br>Not Communicating ", 0]]
        } else {
          data = [["UP Feeders",0],["Down Feeders", this.RECArray.length],["Modem <br>Not Communicating ", 0]]
        }
      }
      else if(this.selecttype == 'RADIUS'){
          if (this.useDefault) {
            data = [["UP Feeders",0],["Down Feeders",  this.RADIUSArray.length],["Modem <br>Not Communicating ", 0]]
          } else {
            data = [["UP Feeders",0],["Down Feeders", this.RADIUSArray.length],["Modem <br>Not Communicating ", 0]]
          }
      }
      else if(this.selecttype == 'SECURE'){
          if (this.useDefault) {
            data = [["UP Feeders",0],["Down Feeders",  this.SECUREArray.length],["Modem <br>Not Communicating ", 0]]
          } else {
            data = [["UP Feeders",0],["Down Feeders", this.SECUREArray.length],["Modem <br>Not Communicating ", 0]]
          }
      }
      else{
        if (this.useDefault) {
          data = [["UP Feeders",0],["Down Feeders",  res.Data.length],["Modem <br>Not Communicating ", 0]]
        } else {
          data = [["UP Feeders",0],["Down Feeders", res.Data.length],["Modem <br>Not Communicating ", 0]]
        }
      }
      this.popupData = []
      if(this.selecttype == 'REC'){
        this.popupData = this.RECArray
      }
      else if(this.selecttype == 'RADIUS'){
        this.popupData = this.RADIUSArray
      }
      else if(this.selecttype == 'SECURE'){
        this.popupData = this.SECUREArray
      }
      else{
        this.popupData = res.Data
      }

      this.customSeries = [{
        type: 'pie',
        name: 'Feeders',
        data: data,
        size: '100%',
        innerSize: '50%',
        showInLegend: true,
        dataLabels: {
          enabled: false
        }, events: {
          click: (event: any) => {
            let dataJson: any ;
            if (event.point.name == "Down Feeders") {
              dataJson = {
                serviceprovider:this.selecttype,
                state: 'DOWNFEEDERS',
                from: 'DOWN',
                resultData : this.popupData
              }
            } 
            this.openDialogfilter(dataJson);
          }
        }
      }]
      if (this.useDefault) {
        if(this.selecttype == 'REC'){
          this.chart1Function(this.customSeries,this.RECArray.length)
        }
        else if(this.selecttype == 'RADIUS'){
          this.chart1Function(this.customSeries,this.RADIUSArray.length)
        }
        else if(this.selecttype == 'SECURE'){
          this.chart1Function(this.customSeries,this.SECUREArray.length)
        }
        else{
          this.chart1Function(this.customSeries,res.Data.length)
        }

      } else {
        if(this.selecttype == 'REC'){
          this.chart1Function(this.customSeries,this.RECArray.length)
        }
        else if(this.selecttype == 'RADIUS'){
          this.chart1Function(this.customSeries,this.RADIUSArray.length)
        }
        else if(this.selecttype == 'SECURE'){
          this.chart1Function(this.customSeries,this.SECUREArray.length)
        }
        else{
          this.chart1Function(this.customSeries,res.Data.length)
        }
      }
      /**
       * call scheme donut chart
       */
    })
  }
  /**
   * 
   */
   openDialogfilter(data: any) {
    const dialogRef = this.dialog.open(FeederfilterllistComponent, {
      width: '80%',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  /**
   * Function For selection of Service Provider
   */
   serviceProviderSelect(data:string){
    this.serviceproviderType=data
    this.selecttype=data
    console.log(this.serviceproviderType)
    if(this.chart_content_type == 'DOWN'){
      this.getDownFeederChart()
    }
    else if(this.chart_content_type == 'SERVICE'){
      this.getServiceProviderCount()
    }
   }
   /**
    * declare function for plotting chart 1 function
    */
  chart1Function(data: any, count: any) {
    this.chart1 = new Chart({
      chart: {
        margin: 0,
        marginRight: 120,
        renderTo: 'chart',
        type: 'pie',
        height: 220,
        backgroundColor: 'transparent'
      },
      title: {
        useHTML: true,
        text: count,
        verticalAlign: 'middle',
        margin: 0,
        style: {
          color: '#fff',
          font: 'bold 16px "Trebuchet MS", Verdana, sans-serif',
          paddingRight: '120px'
        },
      },
      xAxis: {
        minPadding: 0,
        maxPadding: 0
      },
      yAxis: {
        title: {
          text: '',
        }
      },
      legend: {
        align: 'right',
        verticalAlign: 'middle',
        layout: 'vertical',
        itemStyle: {
          color: '#fff',
          width: 100
        }, labelFormatter: function () {
          var p = JSON.stringify(this.options).split(":")[2].split("}")[0] + '<br>' + this.name;
          return p;
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          colors: [
            '#6EBB49',
            '#ED2F0D',
            '#F4FA10',
            '#3258DD'
          ],
        }
      },
      tooltip: {
        formatter: function () {
          return '<b>' + this.point.name + '</b>: ' + this.y;
        }
      },
      series: data
    });
  }
}
