import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class CommonService {
  private data = new BehaviorSubject('default data');
  private updatedata = new BehaviorSubject('ALL');
  updatedata$ = this.updatedata.asObservable();
  data$ = this.data.asObservable();
  discom:any=localStorage.getItem("discom")
  scheme:any = "ALL";
  district:any = "ALL";
  zone:any = localStorage.getItem("zone");
  circle:any = localStorage.getItem("circle");
  division:any = localStorage.getItem("division");
  subdivision:any = localStorage.getItem("subdivision");
  substation:any = localStorage.getItem("substation");
  changeData(data: string) {
    this.data.next(data)
  }
  updateData(data: string) {
    this.updatedata.next(data)
  }
  setdiscom(data:string){
    this.discom = data;
  }
  setscheme(data:string){
    this.scheme = data
  }
  setdistrict(data:string){
    this.district = data;
  }
  setzone(data:string){
    if(localStorage.getItem("zone")!='ALL'){
      this.zone=localStorage.getItem("zone")
    }
    else{
      this.zone=data
    }
  }
  setcircle(data:string){
    if(localStorage.getItem("circle")!='ALL'){
      this.circle=localStorage.getItem("circle")
    }
    else{
      this.circle=data
    }
  }
  setdivision(data:string){
    if(localStorage.getItem("division")!='ALL'){
      this.division=localStorage.getItem("division")
    }
    else{
      this.division=data
    }
  }
  setsubdivision(data:string){
    if(localStorage.getItem("subdivision")!='ALL'){
      this.subdivision=localStorage.getItem("subdivision")
    }
    else{
      this.subdivision=data
    }
  }
  setsubstation(data:string){
    if(localStorage.getItem("substation")!='ALL'){
      this.substation=localStorage.getItem("substation")
    }
    else{
      this.substation=data
    }
  }
  get getdiscom(){
    return this.discom;
  }
  get getscheme(){
    return this.scheme;
  }
  get getdistrict(){
    return this.district;
  }
  get getzone(){
    return this.zone;
  }
  get getcircle(){
    return this.circle;
  }
  get getdivision(){
    return this.division;
  }
  get getsubdivision(){
    return this.subdivision;
  }
  get getsubstation(){
    return this.substation;
  }
}
