import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-feeder-search-dialog',
  templateUrl: './feeder-search-dialog.component.html',
  styleUrls: ['./feeder-search-dialog.component.scss']
})
 
export class FeederSearchDialogComponent implements OnInit {
  dataSource: any;

  constructor(
    public dialogRef: MatDialogRef<FeederSearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(JSON.stringify(data))
    }
    ELEMENT_DATA = []
  
    displayedColumns: string[] = ['position', 'meter_sr_no', 'discom_name', 'zone_name','circle_name','feeder_name','project','device_state'];
   
    @ViewChild(MatPaginator) paginator: MatPaginator | any;
  
    meterOpen(meterId:string,projectId:string){
      // if(projectId == 'RURAL' || projectId == 'URBAN'|| projectId == 'AG'){
        
      //   window.open(`https://feeder.myxenius.com/FeederApp/vendorFeederDetails/${meterId}`,"_blank")
      // }else{
      //   window.open(`https://feeder.myxenius.com/FeederApp/singleFeederDetails/${meterId}`,"_blank")

      // }
    let currentURL = window.location.href; 
    let tmp=currentURL.split("/")
      if (this.data.vendor == 'O') {
        window.open(tmp[0]+"//"+tmp[2]+"/vendorDetails/" + meterId)
      } else {
        window.open(tmp[0]+"//"+tmp[2]+"/vendorDetails/" + meterId)
      }
      
    }
  ngOnInit(): void {
    for (var i = 0; i < this.data.length; i++) {
      this.data[i].position = i + 1;
    }
    this.dataSource = [];

setTimeout(()=>{
  this.dataSource = new MatTableDataSource<any>(this.data);

  this.dataSource.paginator = this.paginator;

},200)
  }

}
