

<div class=" ">
    
  <app-header></app-header>

    <p style="       margin-bottom: 13px;
    text-align: center;
    color: black;
    background: black;
    padding: 10px;
    color: white;"><span style="        font-size: 28px;
        
        margin-bottom: 5px;
        font-weight: 500;;
        border-radius: 10px;;">UPPCL Feeder Monitoring Dashboard</span>
       
    </p>
    <hr>

    <div fxLayout="row wrap"  >
        <div fxFlex="100"  >
            <mat-card class ="box">

              <div class="flex-container"  fxLayout="row wrap" fxLayoutAlign="space-between center"  >
                <div fxFlex   >
                     <span  >Supply Graph ( {{date1}})</span>  
             
                <mat-form-field appearance="outline" style="float: right;">
                    <mat-label>Select Duration</mat-label>
                    <mat-select matNativeControl [(ngModel)]="supplyhr" [ngModelOptions]="{standalone: true}" (selectionChange) ="getGraphData()">
                      <mat-option *ngFor ="let list of hour" [value]="list.value">{{list.namevalue}}</mat-option>
                     </mat-select>
                  </mat-form-field>           
                  
                  <div [chart]="chart1" style="width:100%"></div>

                </div>
 
 <div fxFlex   >
  <span  >Outage Graph</span>  

  <div [chart]="chart2" ></div>
</div>
</div>
            </mat-card>
            </div>
            <!-- <div fxFlex="50"  >
                <mat-card  class ="box">
    
                    Dsad
                </mat-card>
                </div> -->
    </div>
    <ngx-ui-loader></ngx-ui-loader>