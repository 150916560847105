<h2 mat-dialog-title>Feeder List</h2>
<mat-dialog-content>


        <table mat-table [dataSource]="dataSource">
      
          <!-- Position Column -->
          <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef> No. </th>
            <td mat-cell *matCellDef="let element"> {{element.position}} </td>
          </ng-container>
      
          <!-- Name Column -->
          <ng-container matColumnDef="meter_sr_no">
            <th mat-header-cell *matHeaderCellDef> Meter No. </th>
            <td mat-cell *matCellDef="let element" (click) ="meterOpen(element.id,element.project_id)"  style="color: #293368;cursor: pointer;"> {{element.serial_no}} </td>
          </ng-container>
      
          <!-- Weight Column -->
          <ng-container matColumnDef="discom_name">
            <th mat-header-cell *matHeaderCellDef> Discom Name </th>
            <td mat-cell *matCellDef="let element"> {{element.discom_name}} </td>
          </ng-container>
      
          <!-- Symbol Column -->
          <ng-container matColumnDef="zone_name">
            <th mat-header-cell *matHeaderCellDef> Zone </th>
            <td mat-cell *matCellDef="let element"> {{element.zone_name}} </td>
          </ng-container>
          <ng-container matColumnDef="circle_name">
            <th mat-header-cell *matHeaderCellDef> Circle </th>
            <td mat-cell *matCellDef="let element" > {{element.circle_name}} </td>
          </ng-container>
          <!-- <ng-container matColumnDef="division_name">
            <th mat-header-cell *matHeaderCellDef> Division </th>
            <td mat-cell *matCellDef="let element"> {{element.division_name}} </td>
          </ng-container>
          <ng-container matColumnDef="substation_name">
            <th mat-header-cell *matHeaderCellDef> Substation </th>
            <td mat-cell *matCellDef="let element"> {{element.site_name}} </td>
          </ng-container> -->
          <ng-container matColumnDef="feeder_name">
            <th mat-header-cell *matHeaderCellDef> Feeder </th>
            <td mat-cell *matCellDef="let element"> {{element.feeder_name}} </td>
          </ng-container>   <ng-container matColumnDef="project">
            <th mat-header-cell *matHeaderCellDef> Project </th>
            <td mat-cell *matCellDef="let element"> {{element.project_id}} </td>
          </ng-container>
          <ng-container matColumnDef="device_state">
            <th mat-header-cell *matHeaderCellDef> Device State </th>
            <td mat-cell *matCellDef="let element"> {{element.device_state}} </td>
          </ng-container>
          
          <tr mat-header-row *matHeaderRowDef="displayedColumns" ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancel</button>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->

  </mat-dialog-actions>
