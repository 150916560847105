import { Component, OnInit,OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CommonService } from '../common.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit,OnDestroy {
  navigation:any = [
        { 
          name:"Dashboard",
          state:"",
          menuTrigger:"dashmenu",
          child:[
            {
              name:"Main Dashboard",
              state:"/dashboard",
              title:"UPPCL Feeder Monitoring Dashboard"
            },
            {
              name:"Graphical Dashboard",
              state:"/graphical/dashboard",
              title:"UPPCL Feeder Monitoring Dashboard"
            }, {
              name:"No Modem",
              state:"/NoModem",
              title:"UPPCL Feeder Monitoring Dashboard"
            }
          ]
         },
        //  { 
        //   name:"Roaster Outage",
        //   state:"/outage",
        //   menuTrigger:"",
        //   title:"Roaster Outage",
        //   child:[]
        //  },
         {
          name:"Planned Outage Details",
          state:"/outageDetails",
          menuTrigger:"",
          title:"Roaster Outage",
          child:[]
         },
         { 
          name:"Report",
          state:"",          
          menuTrigger:"menu",
          child:[
            {
              name:"Supply Report",
              state:"/report/supply",
              title:"Supply Report"
            },
            {
              name:"Detailed Supply Report",
              state:"/reportmsi/supply/Status",
              title:"Detailed Supply Report"
            },
            {
              name:"Supply Summary Slab Wise",
              state:"/report/commuSupply",
              title:"Supply Report Slab Wise"
            },
            {
              name:"Communication Report",
              state:"/report/communication",
              title:"Communication Report"
            },
            {
              name:"Communication Status",
              state:"/report/communicationdiscom",
              title:"Day Wise Feeder"
            },
            {
              name:"Circle Wise Average Supply Duration Report",
              state:"/report/circlewiseDailyCommu",
              title:"Circle Wise Average Supply Duration Report"
            },
            {
              name:"Communication Vendor Wise",
              state:"/reportmsi/feeder/circlewise",
              title:"Day Wise Feeder"
            },
            {
              name:"Best And Worst Feeder",
              state:"/reportmsi/feeder/bestworst",
              title:"Best And Worst Feeder"
            },
            {
              name:"Master Report",
              state:"/report/master",
              title:"Master Report"
            },
            {
              name:"Feeder Data",
              state:"/report/current",
              title:"Feeder Data"
            },
            {
              name:"Outage Report Slabwise",
              state:"/reportmsi/feeder/Status",
              title:"Outage Report Slab Wise"
            },      
            {
              name:"SLA Report",
              state:"/report/slareport",
              title:"SLA Report"
            },  
            {
              name:"SLA Report(Summarized)",
              state:"/report/slareportsummerize",
              title:"SLA Report"
            },   
            {
              name:"Energy Supplied",
              state:"/report/consumption",
              title:"Consumption Report"
            }
          ]
         }, 
         { 
          name:"Report MIS",
          state:"",          
          menuTrigger:"menu",
          child:[
            {
              name:"Feeder Reliability Index Report",
              state:"/report/frir",
              title:"Feeder Reliability Index Report"
            },
            {
              name:"Meter Outage Report",
              state:"/report/mor",
              title:"Meter Outage Report"
            },
            // {
            //   name:"Modem Based Report",
            //   state:"/report/modemBased",
            //   title:"Modem Based Supply"
            // },
            {
              name:"Urban Report",
              state:"/reportmsi/feeder/urbanDetials",
              title:"Urban Details"
            },
            {
              name:"Vendor Log",
              state:"/reportmsi/feeder/vendorLog",
              title:"Vendor Logs"
            }
            // {
            //   name:"Power Outage Report",
            //   state:"/report/orsub",
            //   title:"Power Outage Report"
            // },
            // {
            //   name:"Power Outage Report(Substation Wise)",
            //   state:"/report/discomoutage",
            //   title:"Power Outage Report(Substation Wise)"
            // } 
          ]
         }
        //  { 
        //   name:"Report MIS",
        //   state:"",          
        //   menuTrigger:"matMenu",

        //   child:[
        //     {
        //       name:"Detailed Supply Report",
        //       state:"/reportmsi/supply/Status",
        //       title:"Detailed Supply Report"

        //     },
        //     {
        //       name:"feeder Status",
        //       state:"/reportmsi/feeder/Status",
        //       title:"feeder Status"

        //     },
        //     {
        //       name:"Best And Worst Feeder",
        //       state:"/reportmsi/feeder/bestworst",
        //       title:"Best And Worst Feeder"

        //     },
        //     // {
        //     //   name:"Day Wise Feeder",
        //     //   state:"/reportmsi/feeder/daywise",
        //     //   title:"Day Wise Feeder"
        //     // }  ,
        //     {
        //       name:"Communication Circle Wise",
        //       state:"/reportmsi/feeder/circlewise",
        //       title:"Day Wise Feeder"

        //     },
        //     {
        //       name:"Urban Report",
        //       state:"/reportmsi/feeder/urbanDetials",
        //       title:"Urban Details"

        //     }     
        //   ]
        //  } 

  ]
  returnStatus(data:any){
    let returnState = false;
    data.child.map((res:any)=>{
if(res.state == this.currentUrl){
  returnState = true
}
    })
    return returnState;
  }
  currentUrl: string="";
  subscription1$: Subscription
  constructor(private service: CommonService,private router: Router) { 
   this.subscription1$= this.router.events.subscribe((event: any) => {
    if (event instanceof NavigationEnd) {
      this.currentUrl = event.url
    }
  });
  

  }
  ngOnDestroy(): void {
  
    this.subscription1$.unsubscribe();
  }

  ngOnInit(): void {
  }
  _sendDataToParent(data: string) {
    this.service.changeData(data);  //invoke new Data

  }
  logout(){
    localStorage.clear();
    this.router.navigateByUrl('/')
  }
}
