import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../common.service';
import * as Highcharts from 'highcharts';
import { Chart } from 'angular-highcharts';
import { AuthService } from '../auth.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { FeederfilterllistComponent } from '../feederfilterllist/feederfilterllist.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServiceproviderChartComponent } from '../serviceprovider-chart/serviceprovider-chart.component';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  @ViewChild("drawer", { static: false }) side: any;
  showFiller = false;
  updateFlag: boolean = false;
  dataFromChild: any;
  /**
   *Declare discomlist
   */
  discomList = [
    { name: "ALL", value: "ALL" },
    { name: "PuVVNL", value: "5a71b954cf5e58.55295458" },
    { name: "MVVNL", value: "5a71b8e6167566.89648438" },
    { name: "DVVNL", value: "5a71b6d35156d7.70878980" },
    { name: "PVVNL", value: "5a71b98adb88d0.91464350" },
    { name: "KESCo", value: "5a71b8a355c2d4.24012768" }
  ]

  /**
   * declare project list
   */
  projectList = [
    { name: "ALL", value: "ALL", project: "ALL" },
    { name: "AG", value: "AG", project: "AG" },
    { name: "RURAL", value: "RURAL", project: "RURAL" },
    { name: "IPDS", value: "IPDS", project: "IPDS" },
    { name: "U-TEHSIL", value: "URBAN_TEHSIL", project: "URBAN_TEHSIL" },
    { name: "U-RAPDRP", value: "URBAN_RAPDRP", project: "URBAN_RAPDRP" },
    { name: "EODB", value: "EODB", project: "EoDB" },
   
    
  ]
  useDefault: boolean = false;
  /**
   * define chart 1
   */
  chart1 = new Chart({
    chart: {
      margin: 0,
      marginRight: 120,
      renderTo: 'chart',
      type: 'pie',
      height: 220,
      backgroundColor: 'transparent'
    },
    title: {
      useHTML: true,
      text: '800',
      verticalAlign: 'middle',
      margin: 0,
      style: {
        color: '#fff',
        font: 'bold 16px "Trebuchet MS", Verdana, sans-serif',
        paddingRight: '120px'
      },
    },
    xAxis: {
      minPadding: 0,
      maxPadding: 0
    },
    yAxis: {
      title: {
        text: '',
      }
    },
    legend: {
      align: 'right',
      verticalAlign: 'middle',
      layout: 'vertical',
      itemStyle: {
        color: '#fff',
        width: 100
      }, labelFormatter: function () {
        var p = 22 + '<br>' + this.name;
        return p;
      },
    },
    plotOptions: {
      pie: {
        shadow: false,
        colors: [
          '#6EBB49',
          '#ED2F0D',
          '#F4FA10',
          '#3258DD'
        ],
      }
    },
    tooltip: {
      formatter: function () {
        return '<b>' + this.point.name + '</b>: ' + this.y + ' %';
      }
    },
    series: [{
      type: 'pie',
      name: 'Browsers',
      data: [["UP Feeders", 6], ["Down Feeders", 7], ["Modem Not <br> Communicating ", 7]],
      size: '100%',
      innerSize: '50%',
      showInLegend: true,
      dataLabels: {
        enabled: false
      },
      point: {
        events: {
          click: function (event) {
            alert("dsad");
          }
        }
      }
    }]
  });
  /**
   * define chart 2
   */
  chart2 = new Chart({
    chart: {
      margin: 0,
      marginRight: 120,
      renderTo: 'chart',
      type: 'pie',
      height: 220,
      backgroundColor: 'transparent'
    },
    title: {
      useHTML: true,
      text: '800',
      verticalAlign: 'middle',
      margin: 0,
      style: {
        color: '#fff',
        font: 'bold 16px "Trebuchet MS", Verdana, sans-serif',
        paddingRight: '120px'
      },
    },
    xAxis: {
      minPadding: 0,
      maxPadding: 0
    },
    yAxis: {
      title: {
        text: '',
      }
    },
    legend: {
      align: 'right',
      verticalAlign: 'middle',
      layout: 'vertical',
      itemStyle: {
        color: '#fff',
        width: 100
      }, labelFormatter: function () {
        var p = 22 + '<br>' + this.name;
        return p;
      },
    },
    plotOptions: {
      pie: {
        shadow: false,
        colors: [
          '#6EBB49',
          '#ED2F0D',
          '#F4FA10',
          '#3258DD'
        ],
      }
    },
    tooltip: {
      formatter: function () {
        return '<b>' + this.point.name + '</b>: ' + this.y + ' %';
      }
    },
    series: [{
      type: 'pie',
      name: 'Browsers',
      data: [["UP Feeders", 6], ["Down Feeders", 7], ["Modem Not <br> Communicating ", 7]],
      size: '100%',
      innerSize: '50%',
      showInLegend: true,
      dataLabels: {
        enabled: false
      }
    }]
  });
  /**
   * chart 3
   */
   chart3 = new Chart({
    chart: {
      margin: 0,
      marginRight: 120,
      renderTo: 'chart',
      type: 'pie',
      height: 220,
      backgroundColor: 'transparent'
    },
    title: {
      useHTML: true,
      text: '800',
      verticalAlign: 'middle',
      margin: 0,
      style: {
        color: '#fff',
        font: 'bold 16px "Trebuchet MS", Verdana, sans-serif',
        paddingRight: '120px'
      },
    },
    xAxis: {
      minPadding: 0,
      maxPadding: 0
    },
    yAxis: {
      title: {
        text: '',
      }
    },
    legend: {
      align: 'right',
      verticalAlign: 'middle',
      layout: 'vertical',
      itemStyle: {
        color: '#fff',
        width: 100
      }, labelFormatter: function () {
        var p = 22 + '<br>' + this.name;
        return p;
      },
    },
    plotOptions: {
      pie: {
        shadow: false,
        colors: [
          '#6EBB49',
          '#ED2F0D',
          '#F4FA10',
          '#3258DD'
        ],
      }
    },
    tooltip: {
      formatter: function () {
        return '<b>' + this.point.name + '</b>: ' + this.y + ' %';
      }
    },
    series: [{
      type: 'pie',
      name: 'Browsers',
      data: [["UP Feeders", 6], ["Down Feeders", 7], ["Modem Not <br> Communicating ", 7]],
      size: '100%',
      innerSize: '50%',
      showInLegend: true,
      dataLabels: {
        enabled: false
      }
    }]
  });
  customSeries: any;
  chart = new Chart({
    chart: {
      margin: 0,
      marginRight: 120,
      renderTo: 'chart',
      type: 'pie',
      height: 220,
      backgroundColor: 'transparent'
    },
    title: {
      useHTML: true,
      text: '',
      verticalAlign: 'middle',
      margin: 0,
      style: {
        color: '#fff',
        font: 'bold 16px "Trebuchet MS", Verdana, sans-serif',
        paddingRight: '120px'
      },
    },
    xAxis: {
      minPadding: 0,
      maxPadding: 0
    },
    yAxis: {
      title: {
        text: '',
      }
    },
    legend: {
      align: 'right',
      verticalAlign: 'middle',
      layout: 'vertical',
      itemStyle: {
        color: '#fff',
        width: 100
      }, labelFormatter: function () {
        var p = 22 + '<br>' + this.name;
        return p;
      },
    },
    plotOptions: {
      pie: {
        shadow: false,
        colors: [
          '#6EBB49',
          '#ED2F0D',
          '#F4FA10',
          '#3258DD'
        ],
      }
    },
    tooltip: {
      formatter: function () {
        return '<b>' + this.point.name + '</b>: ' + this.y;
      }
    },
    series: []
  });
  type: any;
  filterType: any;
  discomName: string = '';
  /**
   * declare function chart1
   */
  chart1Function(data: any, count: any) {
    this.chart = new Chart({
      chart: {
        margin: 0,
        marginRight: 120,
        renderTo: 'chart',
        type: 'pie',
        height: 220,
        backgroundColor: 'transparent'
      },
      title: {
        useHTML: true,
        text: count,
        verticalAlign: 'middle',
        margin: 0,
        style: {
          color: '#fff',
          font: 'bold 16px "Trebuchet MS", Verdana, sans-serif',
          paddingRight: '120px'
        },
      },
      xAxis: {
        minPadding: 0,
        maxPadding: 0
      },
      yAxis: {
        title: {
          text: '',
        }
      },
      legend: {
        align: 'right',
        verticalAlign: 'middle',
        layout: 'vertical',
        itemStyle: {
          color: '#fff',
          width: 100
        }, labelFormatter: function () {
          var p = JSON.stringify(this.options).split(":")[2].split("}")[0] + '<br>' + this.name;
          return p;
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          colors: [
            '#6EBB49',
            '#ED2F0D',
            '#F4FA10',
            '#3258DD'
          ],
        }
      },
      tooltip: {
        formatter: function () {
          return '<b>' + this.point.name + '</b>: ' + this.y;
        }
      },
      series: data
    });
  }
  /**
   * declare function chart 2
   */
  chart2Function(data: any, count: any) {
    this.chart1 = new Chart({
      chart: {
        margin: 0,
        marginRight: 120,
        renderTo: 'chart',
        type: 'pie',
        height: 220,
        backgroundColor: 'transparent'
      },
      title: {
        useHTML: true,
        text: count,
        verticalAlign: 'middle',
        margin: 0,
        style: {
          color: '#fff',
          font: 'bold 16px "Trebuchet MS", Verdana, sans-serif',
          paddingRight: '120px'
        },
      },
      xAxis: {
        minPadding: 0,
        maxPadding: 0
      },
      yAxis: {
        title: {
          text: '',
        }
      },
      legend: {
        align: 'right',
        verticalAlign: 'middle',
        layout: 'vertical',
        itemStyle: {
          color: '#fff',
          width: 100
        }, labelFormatter: function () {
          var p = JSON.stringify(this.options).split(":")[2].split("}")[0] + '<br>' + this.name;
          return p;
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          colors: [
            '#6EBB49',
            '#ED2F0D',
            '#F4FA10',
            '#3258DD'
          ],
        }
      },
      tooltip: {
        formatter: function () {
          return '<b>' + this.point.name + '</b>: ' + this.y;
        }
      },
      series: data
    });
  }
  /**
   * chart3 function
   */
  chart3Function(data: any, count: any) {
    this.chart2 = new Chart({
      chart: {
        margin: 0,
        marginRight: 120,
        renderTo: 'chart',
        type: 'pie',
        height: 220,
        backgroundColor: 'transparent'
      },
      title: {
        useHTML: true,
        text: count,
        verticalAlign: 'middle',
        margin: 0,
        style: {
          color: '#fff',
          font: 'bold 16px "Trebuchet MS", Verdana, sans-serif',
          paddingRight: '120px'
        },
      },
      xAxis: {
        minPadding: 0,
        maxPadding: 0
      },
      yAxis: {
        title: {
          text: '',
        }
      },
      legend: {
        align: 'right',
        verticalAlign: 'middle',
        layout: 'vertical',
        itemStyle: {
          color: '#fff',
          width: 100
        }, labelFormatter: function () {
          var p = JSON.stringify(this.options).split(":")[2].split("}")[0] + '<br>' + this.name;
          return p;
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          colors: [
            '#6EBB49',
            '#ED2F0D',
            '#F4FA10',
            '#3258DD'
          ],
        }
      },
      tooltip: {
        formatter: function () {
          return '<b>' + this.point.name + '</b>: ' + this.y;
        }
      },
      series: data
    });
  }
  /**
   * chart 4 function
   */
   chart4Function(data: any, count: any) {
    this.chart3 = new Chart({
      chart: {
        margin: 0,
        marginRight: 120,
        renderTo: 'chart',
        type: 'pie',
        height: 220,
        backgroundColor: 'transparent'
      },
      title: {
        useHTML: true,
        text: count,
        verticalAlign: 'middle',
        margin: 0,
        style: {
          color: '#fff',
          font: 'bold 16px "Trebuchet MS", Verdana, sans-serif',
          paddingRight: '120px'
        },
      },
      xAxis: {
        minPadding: 0,
        maxPadding: 0
      },
      yAxis: {
        title: {
          text: '',
        }
      },
      legend: {
        align: 'right',
        verticalAlign: 'middle',
        layout: 'vertical',
        itemStyle: {
          color: '#fff',
          width: 100
        }, labelFormatter: function () {
          var p = JSON.stringify(this.options).split(":")[2].split("}")[0] + '<br>' + this.name;
          return p;
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          colors: [
            '#6EBB49',
            '#ED2F0D',
            '#F4FA10',
            '#3258DD'
          ],
        }
      },
      tooltip: {
        formatter: function () {
          return '<b>' + this.point.name + '</b>: ' + this.y;
        }
      },
      series: data
    });
  }
  /**
   * declare variable for substation count
   */
  substationCount: any;
  constructor(private service: CommonService, private AuthService: AuthService, public dialog: MatDialog) { }
  /**
   * set discomtype value from localstorage
   */
  discomType: any = localStorage.getItem("discom")
  projectType: any = 'ALL'
  selecttype: any = 'OUTGOING'
  searchCtrl: any = ''
  filterName:any
  /**
   * set zone from localstorage
   */
  zone:any = localStorage.getItem("zone")
  /**
   * set circle from localstorage
   */
  circle:any = localStorage.getItem("circle")
  /**
   * set division from localstorage
   */
  division:any = localStorage.getItem("division")
  /**
   * set subdivision from localstorage
   */
  subdivision:any = localStorage.getItem("subdivision")
  /**
   * set substation from localstorage
   */
  substation : any = localStorage.getItem("substation")
  ngOnInit(): void {
    /**
     * get discom from service
     */
    this.discomType = this.service.getdiscom
    if (this.service.getdiscom != 'ALL') {
      this.discomList = this.discomList.filter(discom => discom.value === this.service.getdiscom);
      this.discomSelect(this.discomType,this.discomList[0].name)
    }
    
    this.getdiscomFeederCount();
    // this.getSelectedFilterCount();
    /**
     * subscribe service for getting real time data
     */
    this.service.data$.subscribe(res => {
      if (res == "toggle") {
        this.side.toggle();
      } else if (res == "default data") { } else if (JSON.parse(res).type == "in") {
        this.selecttype = JSON.parse(res).selecttype;
        this.discomSelect(this.discomType,this.discomName);
      } else if (JSON.parse(res).type == "substation") {
        this.substationCount = JSON.parse(res).count;
      }
    })
    /**
     * subscribe updateData service
     */
    this.service.updatedata$.subscribe(res => {
      if(res == 'ALL'){
        /**
         * call selected filter donut chart api on page load
         */
        this.getSelectedFilterCount()
      }
      else if(JSON.parse(res).type == "filter" ){
        /**
         * call filter donut chart api when default data is set
         */
        this.filterType=JSON.parse(res).selectedFilter
        this.filterName=JSON.parse(res).selectedFiltername
        this.zone = JSON.parse(res).zone
        this.circle = JSON.parse(res).circle
        this.division = JSON.parse(res).division
        this.subdivision = JSON.parse(res).subdivision
        this.substation = JSON.parse(res).substation
        this.getSelectedFilterCount()
      }
    })
    /**
     * get district data donut chart on page referesh
     */
    this.getDistrictData();
  }
    
  eventFromChild(data1: any) {
  }
  /**
   * when particular discom is selected
   */
  discomSelect(data: string,name:string) {
    this.service.setdiscom(data);
    this.discomType = data;
    let data2 = {
      type: 'map',
      dicom: this.discomType,
      project: this.projectType,
      district: this.districtSelected
    }
    let data3={
      type:"filter",
      selectedFilter:'Discom',
      selectedFiltername:name,
      zone:'ALL',
      circle:"ALL",
      division:"ALL",
      subdivision:"ALL",
      substation:"ALL"
    }
    this.service.setzone('ALL')
    this.service.setcircle('ALL')
    this.service.setdivision('ALL')
    this.service.setsubdivision('ALL')
    this.service.setsubstation('ALL')
    this.service.updateData(JSON.stringify(data3))
    this.service.changeData(JSON.stringify(data2));
    this.getDistrictData()
    this.getSelectedFilterCount();
    this.getdiscomFeederCount()
  }
  /**
   * when particular scheme is selected
   */
  projectSelect(data: string) {
    this.service.setscheme(data);
    this.projectType = data;
    let data2 = {
      type: 'map',
      dicom: this.discomType,
      project: this.projectType,
      district: this.districtSelected,
      d:'ss'
    }
    // let data3 = {
    //   type: 'filter',
    //   zone: 'ALL',
    //   circle: 'ALL',
    //   division: 'ALL',
    //   subdivision:'ALL',
    //   substation:'ALL'
    // }
    // this.service.updateData(JSON.stringify(data3))
    this.service.changeData(JSON.stringify(data2));
    this.getDistrictData()
    this.getSelectedFilterCount();
    this.getdiscomSchemaFeederCount();
  }
  /**
   * get district list
   */
  districtList: any;
  districtSelected: any = 'ALL'
  getDistrictData() {
    this.AuthService.getDistrictList(this.discomType, this.projectType, this.selecttype).subscribe(res => {
      this.districtList = res.district;
    })
  }
  /**
   * get feeder count
   */
  getdiscomFeederCount() {
    this.AuthService.getFeederCount(this.discomType, 'ALL', 'ALL', this.selecttype).subscribe(res => {
      let data: any = []
      if (this.useDefault) {
        data = [["UP Feeders", res.count.UP], ["Down Feeders", res.count.DN], ["Modem Not <br> Communicating ", res.count.NC], ["Not In <br> Service ", res.count.NP]]
      } else {
        data = [["UP Feeders", res.count.UP], ["Down Feeders", res.count.DN], ["Modem Not <br> Communicating ", res.count.NC]]
      }
      this.customSeries = [{
        type: 'pie',
        name: 'Feeders',
        data: data,
        size: '100%',
        innerSize: '50%',
        showInLegend: true,
        dataLabels: {
          enabled: false
        }, events: {
          click: (event: any) => {
            let dataJson: any
            if (event.point.name == "UP Feeders") {
              dataJson = {
                state: 'UP',
                from: 'discom'
              }
            } else if (event.point.name == "Down Feeders") {
              dataJson = {
                state: 'DN',
                from: 'discom'
              }
            } else {
              dataJson = {
                state: 'NC',
                from: 'discom'
              }
            }
            this.openDialogfilter(dataJson);
          }
        }
      }]
      if (this.useDefault) {
        this.chart1Function(this.customSeries, res.count.UP + res.count.DN + res.count.NC + res.count.NP)

      } else {
        this.chart1Function(this.customSeries, res.count.UP + res.count.DN + res.count.NC)
      }
      /**
       * call scheme donut chart
       */
      this.getdiscomSchemaFeederCount();
    })
  }
  /**
   * get selected filter count
   */
   getSelectedFilterCount(){
     if(localStorage.getItem("zone")!='ALL'){
      this.zone=localStorage.getItem("zone")
     }
     if(localStorage.getItem("circle")!='ALL'){
      this.circle=localStorage.getItem("circle")
     }
     if(localStorage.getItem("division")!='ALL'){
      this.division=localStorage.getItem("division")
     }
     if(localStorage.getItem("subdivision")!='ALL'){
      this.subdivision=localStorage.getItem("subdivision")
     }
     if(localStorage.getItem("substation")!='ALL'){
      this.substation=localStorage.getItem("substation")
     }
    this.AuthService.getFeederCountV1(this.discomType,this.projectType,'ALL',this.selecttype,this.zone,this.circle,this.division,this.subdivision,this.substation).subscribe(res => {
      let data: any = []
      if (this.useDefault) {
        data = [["UP Feeders", res.count.UP], ["Down Feeders", res.count.DN], ["Modem Not <br> Communicating ", res.count.NC], ["Not In <br> Service ", res.count.NP]]
      } else {
        data = [["UP Feeders", res.count.UP], ["Down Feeders", res.count.DN], ["Modem Not <br> Communicating ", res.count.NC]]
      }
      this.customSeries = [{
        type: 'pie',
        name: 'Feeders',
        data: data,
        size: '100%',
        innerSize: '50%',
        showInLegend: true,
        dataLabels: {
          enabled: false
        }, events: {
          /**
           * high chart event
           */
          click: (event: any) => {
            let dataJson: any
            if (event.point.name == "UP Feeders") {
              dataJson = {
                state: 'UP',
                from: 'filter',
                zone:this.zone,
                circle:this.circle,
                division:this.division,
                subdivision:this.subdivision,
                substation:this.substation
              }
            } else if (event.point.name == "Down Feeders") {
              dataJson = {
                state: 'DN',
                from: 'filter',
                zone:this.zone,
                circle:this.circle,
                division:this.division,
                subdivision:this.subdivision,
                substation:this.substation
              }
            } else {
              dataJson = {
                state: 'NC',
                from: 'filter',
                zone:this.zone,
                circle:this.circle,
                division:this.division,
                subdivision:this.subdivision,
                substation:this.substation
              }
            }
            this.openDialogfilter(dataJson);
          }
        }
      }]
      if (this.useDefault) {
        this.chart4Function(this.customSeries, res.count.UP + res.count.DN + res.count.NC + res.count.NP)
      } else {
        this.chart4Function(this.customSeries, res.count.UP + res.count.DN + res.count.NC)
      }
      //this.getdiscomSchemaFeederCount();
    })
   }

  getdiscomSchemaFeederCount() {
    this.AuthService.getFeederCount(this.discomType, this.projectType, 'ALL', this.selecttype).subscribe(res => {
      console.log(typeof(res))
      let data: any = [];
      if (this.useDefault) {
        data = [["UP Feeders", res.count.UP], ["Down Feeders", res.count.DN], ["Modem Not <br> Communicating ", res.count.NC], ["Not In <br> Service ", res.count.NP]]

      } else {
        data = [["UP Feeders", res.count.UP], ["Down Feeders", res.count.DN], ["Modem Not <br> Communicating ", res.count.NC]]

      }
      this.customSeries = [{
        type: 'pie',
        name: 'Feeders',
        data: data,
        size: '100%',
        innerSize: '50%',
        showInLegend: true,
        dataLabels: {
          enabled: false
        }, events: {
          click: (event: any) => {
            //How do I Access the id??????? 

            let dataJson: any
            if (event.point.name == "UP Feeders") {
              dataJson = {
                state: 'UP',
                from: 'project'
              }
            } else if (event.point.name == "Down Feeders") {
              dataJson = {
                state: 'DN',
                from: 'project'
              }
            } else {
              dataJson = {
                state: 'NC',
                from: 'project'
              }
            }
            this.openDialogfilter(dataJson);
          }
        }
      }];
      if (this.useDefault) {
        this.chart2Function(this.customSeries, res.count.UP + res.count.DN + res.count.NC + res.count.NP)
      } else {
        this.chart2Function(this.customSeries, res.count.UP + res.count.DN + res.count.NC)
      }
      this.getdiscomSchemaDistrictFeederCount('indirect');
      this.getSelectedFilterCount()
    })
  }
  openDialogfilter(data: any) {
    const dialogRef = this.dialog.open(FeederfilterllistComponent, {
      width: '80%',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  getdiscomSchemaDistrictFeederCount(data: string) {
    if (data == 'direct') {
      this.service.setdistrict(this.districtSelected);
      let data2 = {
        type: 'map',
        dicom: this.discomType,
        project: this.projectType,
        district: this.districtSelected
      }
      this.service.changeData(JSON.stringify(data2));
    }
    this.AuthService.getFeederCount(this.discomType, this.projectType, this.districtSelected, this.selecttype).subscribe(res => {
      let data: any = [];
      if (this.useDefault) {
        data = [["UP Feeders", res.count.UP], ["Down Feeders", res.count.DN], ["Modem Not <br> Communicating ", res.count.NC], ["Not In <br> Service ", res.count.NP]]
      } else {
        data = [["UP Feeders", res.count.UP], ["Down Feeders", res.count.DN], ["Modem Not <br> Communicating ", res.count.NC]]
      }
      this.customSeries = [{
        type: 'pie',
        name: 'Feeders',
        data: data,
        size: '100%',
        innerSize: '50%',
        showInLegend: true,
        dataLabels: {
          enabled: false
        }, events: {
          click: (event: any) => {
            let dataJson: any
            if (event.point.name == "UP Feeders") {
              dataJson = {
                state: 'UP',
                from: 'district'
              }
            } else if (event.point.name == "Down Feeders") {
              dataJson = {
                state: 'DN',
                from: 'district'
              }
            } else {
              dataJson = {
                state: 'NC',
                from: 'district'
              }
            }
            this.openDialogfilter(dataJson);
          }
        }
      }]
      if (this.useDefault) {
        this.chart3Function(this.customSeries, res.count.UP + res.count.DN + res.count.NC + res.count.NP)
      } else {
        this.chart3Function(this.customSeries, res.count.UP + res.count.DN + res.count.NC)
      }
    })
  }
  toggle(event: MatSlideToggleChange) {
    this.useDefault = event.checked;
    this.AuthService.setnpBoolenValue(event.checked);
    this.getdiscomFeederCount();
    this.getDistrictData();
    let data2 = {
      type: 'map',
      dicom: this.discomType,
      project: this.projectType,
      district: this.districtSelected
    }
    this.service.changeData(JSON.stringify(data2));
  }
  /**
   * show service provider donut chart
   */
  show(key:any){
    const dialogRef = this.dialog.open(ServiceproviderChartComponent, {
      panelClass: 'dialog-container-custom' ,
      width: '40%',
      data: key,
      hasBackdrop: false
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
}
