import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService} from '../common.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  FormGroup: FormGroup;
  private userid:any='radius@feeder';
  private userpass:any='feeder@rahul';
  private encryptstring:any='cmFkaXVzQGZlZWRlcitmZWVkZXJAcmFodWw=';
  constructor(private fb: FormBuilder,private AuthService:AuthService,private Router:Router,private service:CommonService,private route: ActivatedRoute) { 
    this.FormGroup = fb.group({
      login_id:[''],
      password:['']
    })
  }
  public hasfirstError = (controlName: string, errorName: string) => {
    return this.FormGroup.controls[controlName].hasError(errorName);
  }
  signIn(){
    this.AuthService.login(this.FormGroup.value).subscribe(res=>{
      if(res.rc == -1){
        if(sessionStorage.getItem("attempt")){
          if(sessionStorage.getItem("attempt") == "3"){
            alert("Your account is locked due to multiple invalid attempt.Please try aftersometimes")
          }else{
            let i = Number(sessionStorage.getItem("attempt"))
            let a = (i+1)
            sessionStorage.setItem("attempt",a.toString())
            alert(res.message)
          }
        }else{
          sessionStorage.setItem("attempt","1")
          alert(res.message)
        }    
      }else if(res.rc == 0 && res.hasOwnProperty("login_id")){
        sessionStorage.clear()
        // let tmp = JSON.parse(res)
        localStorage.setItem("discom",res.discom_id)
        localStorage.setItem("zone",res.zone_id)
        localStorage.setItem("circle",res.circle_id)
        localStorage.setItem("division",res.division_id)
        localStorage.setItem("subdivision","ALL")
        localStorage.setItem("substation",res.site_id)
        localStorage.setItem("login_id",res.login_id)
        this.service.setdiscom(res.discom_id)
        this.service.setzone(res.zone_id)
        this.service.setcircle(res.circle_id)
        this.service.setdivision(res.division_id)
        this.service.setsubdivision('ALL')
        this.service.setsubstation(res.site_id)
        this.Router.navigateByUrl('dashboard')
      }else{
        alert("Something Went Wrong")
      }
     },error=>{
      alert("Invalid User name or Password")
    })
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      // let status=this.decrypttoken(params['token_id'])
    })
    if(localStorage.getItem("login_id")){
      localStorage.clear();
    }
  }
  // private generateroken(){
  //   let encryptedkey=btoa(this.userid.concat("+",this.userpass))
  //   console.log(this.userid,this.userpass)
  //   console.log(encryptedkey)
  // }
  // private decrypttoken(key:string){
  //   let decrypttoken=atob(key)
  //   if(decrypttoken === (this.userid.concat("+",this.userpass))){
  //     localStorage.setItem("discom",'ALL')
  //       localStorage.setItem("zone",'ALL')
  //       localStorage.setItem("circle",'ALL')
  //       localStorage.setItem("division",'ALL')
  //       localStorage.setItem("subdivision","ALL")
  //       localStorage.setItem("substation",'ALL')
  //       this.service.setdiscom('ALL')
  //       this.service.setzone('ALL')
  //       this.service.setcircle('ALL')
  //       this.service.setdivision('ALL')
  //       this.service.setsubdivision('ALL')
  //       this.service.setsubstation('ALL')
  //       this.Router.navigateByUrl('dashboard')
  //   }
  //   else{
  //     alert('invalid token')
  //   }
  // }
  /**
   * encryption part
   */
  
}
