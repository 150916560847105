<nav class="navbar navbar-default"style="margin-bottom:0px;background:black">
    <p style="text-align: center;color: white;padding-top:15px"><span style="font-size: 28px;font-weight: 500;">Feeder Load Curve Dashboard</span></p>
  
    </nav>
 <mat-drawer-container class="example-container backgorund" autosize>
   
      
    <mat-drawer #drawer class="example-sidenav" mode="side" opened="true">
        <div style="width: 100%;text-align: center">
            <img src="../../assets/elements/LOGO.png" style="width: 210px;" [routerLink]="['/dashboard']"
                style="cursor: pointer;">
        </div>
        <ul  style="color:white;">
            <li>
                <a>Load Curve</a>
            </li>
        </ul>
      </mat-drawer>
    <div class="example-sidenav-content backgorund">
        <div class="container2" style="margin: 40px 40px 0px 40px;">
            <div fxLayout="row wrap">
                <div fxFlex="100" style="height: 100%;">
                    <div fxLayout="row wrap">
                        <div fxFlex="100">
                            <div fxLayout="row wrap">
                                <div fxFlex="25">
                                    <mat-form-field appearance="outline" style="font-size: 13px;width:95%">
                                        <mat-label class="discomLabe" style="font-size: 14px; color:black">Select Discom
                                        </mat-label>
                                        <mat-select  name="discom"  (selectionChange)="selectedzone(discom.value)" #discom>
                                           
                                            <mat-option *ngFor="let list of discomList"
                                            [value]="list.value">
                                            {{list.name}}
                                            
                                        </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                               
                                <div fxFlex="25">
                                    <mat-form-field appearance="outline" style="font-size: 13px;width:95%">
                                        <mat-label class="zoneLabe" style="font-size: 14px; color:black">Select Zone
                                        </mat-label>
                                        <mat-select  name="zone"  (selectionChange)="selectedcircle(zone.value)" #zone>
                                            <mat-option [value]="'ALL'">
                                                ALL
                                            </mat-option>
                                            <mat-option *ngFor="let list of zoneData"
                                            [value]="list">
                                            {{list}}
                                        </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div fxFlex="25">
                                    <mat-form-field appearance="outline" style="font-size: 13px;width:95%">
                                        <mat-label class="circleLabe" style="font-size: 14px; color:black">Select Circle
                                        </mat-label>
                                        <mat-select  name="circle" (selectionChange)="selecteddivision(circle.value)" #circle>
                                            <mat-option [value]="'ALL'">
                                                ALL
                                            </mat-option>
                                            <mat-option *ngFor="let list of cirlcleListData"
                                            [value]="list">
                                            {{list}}
                                        </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
    
                                <div fxFlex="25">
                                    <mat-form-field appearance="outline" style="font-size: 13px;width:95%">
                                        <mat-label class="divisionLabe" style="font-size: 14px; color:black">Select Division
                                        </mat-label>
                                        <mat-select  name="division" (selectionChange)="selectedsubdivision(division.value)" #division>
                                            <mat-option [value]="'ALL'">
                                                ALL
                                            </mat-option>
                                            <mat-option *ngFor="let list of divisionListData"
                                            [value]="list">
                                            {{list}}
                                        </mat-option> 
                                              
                                        </mat-select>
                                    </mat-form-field> 
                                </div>
                                <div fxFlex="25">
                                    <mat-form-field appearance="outline" style="font-size: 13px;width:95%">
                                        <mat-label class="subdivisionLabe" style="font-size: 14px; color:black">Select Sub-Division
                                        </mat-label>
                                        <mat-select  name="subdivision"  (selectionChange)="selectedsubstation(subdivision.value)" #subdivision>
                                            <mat-option [value]="'ALL'">
                                                ALL
                                            </mat-option>
                                            <mat-option *ngFor="let list of subdivisionListData"
                                            [value]="list">
                                            {{list}}
                                        </mat-option> 
                                             
                                        </mat-select>
                                    </mat-form-field> 
                                
                                </div>
                                <div fxFlex="25">
                                    <mat-form-field appearance="outline" style="font-size: 13px;width:95%">
                                        <mat-label class="substationLabe" style="font-size: 14px; color:black">Select Substation
                                        </mat-label>
                                        <mat-select  name="substation" #substation>
                                            <mat-option [value]="'ALL'">
                                                ALL
                                            </mat-option>
                                            <mat-option *ngFor="let list of subStationListData"
                                            [value]="list">
                                            {{list}}
                                        </mat-option> 
                                           
                                        </mat-select>
                                    </mat-form-field> 
                               
                                </div>
                                <div fxFlex="20">
                                    <mat-form-field appearance="outline" style="margin-left: 12px;">
                                        <mat-label>From Date</mat-label>
                                        <input matInput [matDatepicker]="frompicker" [value]="date.value" #from_date>
                                        <mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
                                        <mat-datepicker #frompicker></mat-datepicker>
                                    </mat-form-field>
                               
                                </div>
                                <div fxFlex="20">
                                    <mat-form-field appearance="outline" style="margin-left: 12px;">
                                        <mat-label>To Date</mat-label>
                                        <input matInput [matDatepicker]="topicker" [value]="date.value" #to_date>
                                        <mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
                                        <mat-datepicker #topicker></mat-datepicker>
                                    </mat-form-field>
                               
                                </div>
                                
                                <div fxFlex="10" style="padding:10px;">
                                    <button mat-raised-button color="dark"  style="border:1px solid;"  (click)="graph_data(substation.value,from_date.value,to_date.value)" >Show</button>
                                </div>
                            </div>
    
                        </div>
                    </div>
                   
                   </div>

            </div>
            <div fxLayout="row">
                <mat-card style="border:1px solid;" fxFlex="100">
                  <mat-card-header>
                    <mat-card-title>
                        Average  Load Data
                    </mat-card-title>
                  </mat-card-header>
                <mat-card-content>  <div [chart]="chart2" style="width:100%"></div></mat-card-content>
                  </mat-card>
              
            </div>
        </div>
    </div>
</mat-drawer-container>