import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { CommonService } from '../common.service';
import { AuthService } from '../auth.service';

declare var google: any;
declare var L: any;
// these variables are not use
// declare var MarkerClusterer: any;
// let historicalOverlay;
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FeederSearchDialogComponent } from '../feeder-search-dialog/feeder-search-dialog.component';
// import Supercluster from 'supercluster';
// import { SuperClusterAdapterLoader } from 'supercluster-googlemaps-adapter';
import { MatTableDataSource } from '@angular/material/table';
import { FeederfilterllistComponent } from '../feederfilterllist/feederfilterllist.component';
import { Router } from '@angular/router';

declare var Supercluster: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  private setting = {
    element: {
      dynamicDownload: null as unknown as HTMLElement
    }
  }
  substation: any = localStorage.getItem("substation");
  /**
   * this variable is use for circle list
   */
  cirlcleListData: any
  /**
   * this circle variable is use for selected circle
   */
  circle: any = localStorage.getItem("circle");
  /**
   * this variable is use for zone search
   */
  zoneCtrl: any = "";
  /**
   * this variable is use for subdivision search
   */
  subdivisionCtrl: any = ""
  /**
   * this variable is use for substation search
   */
  substasionCtrl: any = ""
  /**
   * this variable is use for circle search
   */
  circleCtrl: any = "";
  /**
   * this variable is use for division search
   */
  divisionCtrl: any = ""
  /**
   * this variable is use for meter search
   */
  meterNo: any;
  @Output() sendDataToParent = new EventEmitter<string>();
  /**
   * this variable is use for listing of district
   */
  districtType: any = "ALL";
  gdata: any;
  substationCount: any;
  ready: boolean = false;
  mymap: any;
  index: any;
  sensorindex: any;
  getJsonData: any;
  /**
   * this variable is use for selected zone
   */
  zone: any = localStorage.getItem("zone")
  /**
   * this variable is use for select
   */
  division: any = localStorage.getItem("division");
  subdivision: any = localStorage.getItem("subdivision");

  subdivisionListData: any = [];
  subStationListData: any;
  divisionListData: any;

  meter_id: any;
  zonedisabled: boolean=false;
  circledisabled: boolean=false;


  /**
   * this function is used for open dialogue on search
   */
  openDialog(data: any) {
    const dialogRef = this.dialog.open(FeederSearchDialogComponent, {
      width: '80%',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  constructor(private service: CommonService, private auth: AuthService, public dialog: MatDialog) {
  }

  /**
   * function is for zone search
   */
  selectedzoneSearch(data: any) {
    this.auth.getCircleList(this.zone).subscribe(res => {
      this.cirlcleListData = res.Data.filter(this.onlyUnique);
      this.subdivisionListData = []
      this.circle = data.circle_name
      this.division = "ALL"
      this.subdivision = "ALL"
      this.substation = "ALL"
      this.selectedcircleSearch(data)
    })
  }
  /**
   *  function is use for circle search
   */
  selectedcircleSearch(data: any) {
    this.subdivisionListData = []
    this.auth.getdivision(this.circle).subscribe(res => {
      this.divisionListData = res.Data.filter(this.onlyUnique);
      this.division = data.division_name
      this.subdivision = "ALL"
      this.substation = "ALL"
      this.selecteddivisionSearch(data)
    })
  }
  /**
   * function is use for division search
   */
  selecteddivisionSearch(data: any) {
    this.subdivisionListData = []
    this.auth.getsubdivision(this.division).subscribe(res => {
      this.subdivision = "ALL"
      this.substation = "ALL"
      this.subdivisionListData = res.Data.filter(this.onlyUnique);
      if (this.subdivisionListData[0] == 'Radius') {
        this.selectedSubStationSearch(this.division, data);
      } else {
        this.subdivision = data.subdivision_name
        this.selectedSubStationSearch(this.subdivision, data)
      }
    })
  }
  /**
   * this function is use for substation search
   */
  selectedSubStationSearch(data: any, getdata: any) {
    this.auth.getsubstation(data, this.division, this.circle, this.zone).subscribe(res => {
      this.substation = "ALL"
      this.mymap.remove();
      this.subStationListData = res.Data.filter(this.onlyUnique);
      this.substation = getdata.substation_name
      this.drawMapSearch(this.projectType, this.discomType, this.districtType, getdata)
    })
  }
  /**
   * this function is use for plot location in map search
   */
  drawMapsubstationSearch(data: any) {
    this.mymap.remove();
    this.drawMap(this.projectType, this.discomType, this.districtType)
  }

  selectDataAll(data: any) {
    this.zone = data.zone_name;
    this.selectedzoneSearch(data);
  }
  /**
   * function for meter search
   */
  getMeterSearch() {
    if(this.checkSpecialCharacter(this.meterNo)){
      alert("Enter Valid Meter Serial No");
      return;
    }
    this.auth.getByMeterNo(this.meterNo).subscribe(res => {
      if(res.data == null || res.data == ''){
        alert("Meter Serial No. "+this.meterNo+" is not available")
      }
      if (res.data.length == 1) {
        this.selectDataAll(res.data[0]);
        if (res.data[0].latitude == "0" && res.data[0].longitude == "0") {
          this.auth.getMapDataDetail(res.data[0].id, "O").subscribe(res => {
            this.sensorTable(res.Data);
          })
        }
      } else {
        console.log("when meter is unavailable")
        this.openDialog(res.data)
      }
    })
  }
  /**
   * function for get substation count
   */
  getSubstationCount(ptype: any, dtype: any, district: any, type: any) {
    this.auth.getSubstationCount(ptype, dtype, district, type, this.zone, this.circle, this.division, this.subdivision).subscribe(res => {
      this.substationCount = res.Data;
      let dataJSON = {
        type: "substation"
        , count: res.Data
      }
      this.service.changeData(JSON.stringify(dataJSON));  //invoke new Data

    })
  }

  checkSpecialCharacter(string:any){
    var specialChars = "<>@!#$%^&*()+[]{}?:;|'\"\\,./~`=";
    for(let i = 0; i < specialChars.length;i++){
      if(string.indexOf(specialChars[i]) > -1){
          return true
       }
    }
    return false;
  }
  ngOnInit(): void {
    this.service.data$.subscribe(res => {
      if (res == "toggle") {
      } else if (res == "default data") { } else {
        if (JSON.parse(res).type == 'map') {
          if (this.gdata) {
            this.markcluster.clearMarkers();
          }
          this.discomType = JSON.parse(res).dicom;
          this.projectType = JSON.parse(res).project;
          this.districtType = JSON.parse(res).district
          this.mymap.remove();
          this.zone = this.service.getzone
          this.circle = this.service.getcircle
          this.division = this.service.getdivision
          this.subdivision = this.service.getsubdivision
          this.substation = this.service.getsubstation
          this.drawMap(this.projectType, this.discomType, this.districtType);
          console.log(JSON.parse(res))
          if(!JSON.parse(res).hasOwnProperty('d')){
            this.zoneList(this.projectType, this.discomType, this.districtType);
          }
          this.getSubstationCount(this.projectType, this.discomType, this.districtType, this.type)
        }
      }
    })

    google.load("visualization", "1", {
      packages: ["corechart"]
    });
    google.setOnLoadCallback(this.mapInitialize);
    setTimeout(() => {
      this.discomType = this.service.getdiscom
      this.drawMap(this.projectType, this.discomType, this.districtType);
      // this.getSubstationCount(this.projectType, this.discomType, this.districtType, this.type)
      this.zoneList(this.projectType, this.discomType, this.districtType);
    }, 1000)
  }
  _sendDataToParent(data: string) {
    this.service.changeData(data);  //invoke new Data

  }
  _sendselectType() {
    let dataJSON = {
      type: 'in',
      selecttype: this.type
    }
    this.service.changeData(JSON.stringify(dataJSON));  //invoke new Data
  }
  markers: any = [];
  projectType: any = 'ALL'
  discomType: any = this.service.getdiscom
  markcluster: any;
  src: any;
  type: any = 'OUTGOING';
  markersdata: any = [];
  infoWindowContent = [['<div style="color:blue;">Radius Synergies International Pvt Ltd, Noida</div>']];
  greenIcon = new L.Icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });
  redIcon = new L.Icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });
  yelloIcon = new L.Icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-yellow.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });
  blueIcon = new L.Icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });
  mapInitialize() {
  }
  setZoom(arg0: number) {
    throw new Error('Method not implemented.');
  }
  getPowerStatus(state: any) {
    if (state == "Y" || state == 1) {
      return '<img title="ON" src="http://feeder.myxenius.com/app_images/greendot.png" width="16" height="16"/>';
    } else if (state == "N" || state == 0)
      return '<img title="OFF" src="http://feeder.myxenius.com/app_images/reddot.png" width="16" height="16"/>';
    else
      return '<img title="NOT APPLICABLE" src="http://feeder.myxenius.com/app_images/bullet_grey.png" width="16" height="16"/>';
  }
  statusMeterWithTime(status: string | number, ts: any) {
    if (status == "Y" || status == 1) {
      return '<img title="ON' + ' Since (' + ts + ')" src="http://feeder.myxenius.com/app_images/greendot.png" width="16" height="16"/>';
    } else if (status == "N" || status == 0)
      return '<img title="OFF' + ' Since (' + ts + ')" src="http://feeder.myxenius.com/app_images/reddot.png" width="16" height="16"/>';
    else
      return '<img title="STATUS NOT AVAILABLE" src="http://feeder.myxenius.com/app_images/bullet_grey.png" width="16" height="16"/>';
  }
  DateFormat(time: string | null) {
    if ((time == null) || (time == '') || (time == 'NA')) {
      return "-";
    } else {
      let now = new Date(time.replace(/\-/g, '\/').replace(/[T|Z]/g, ' '));
      let year = "" + now.getFullYear();
      let month = "" + (now.getMonth() + 1); if (month.length == 1) { month = "0" + month; }
      let day = "" + now.getDate(); if (day.length == 1) { day = "0" + day; }
      let hour = "" + now.getHours(); if (hour.length == 1) { hour = "0" + hour; }
      let minute = "" + now.getMinutes(); if (minute.length == 1) { minute = "0" + minute; }
      let second = "" + now.getSeconds(); if (second.length == 1) { second = "0" + second; }
      return day + "-" + month + "-" + year + " " + hour + ":" + minute + ":" + second;

    }
  }
  /**
   * select project
   */
  selectProject(data: any) {
    this.projectType = data;
    this.markcluster.clearMarkers();
    this.drawMap(this.projectType, this.discomType, this.districtType)
  }
  /**
   * select discom
   */
  selectDiscom(data: any) {
    this.discomType = data;
    this.markcluster.clearMarkers();
    this.drawMap(this.projectType, this.discomType, this.districtType)
  }

  // processLargeArray(sensors: any) {
  //   // set this to whatever number of items you can process at once
  //   var chunk = 100;
  //   var index = 0;
  //   function doChunk() {
  //     var cnt = chunk;
  //     while (cnt-- && index < sensors.length) {
  //       // process array[index] here
  //       ++index;
  //     }
  //     if (index < sensors.length) {
  //       // set Timeout for async iteration
  //       setTimeout(doChunk, 1);
  //     }
  //   }
  //   doChunk();
  // }

  JsonData: any = {
    "type": "FeatureCollection",
    "features": []
  };
  featureStyle(feature: any) {
    var options = {
      fillColor: 'red',
      fillOpacity: 0.5,
      strokeColor: 'red',
      strokeOpacity: 1,
      strokeWeight: 2
    };
    return options;
  }
  /**
   * this function is use to open info window when we click on any marker
   */
  onClick = (e: any) => {
    this.auth.getMapDataDetail(e.sourceTarget.options.id, e.sourceTarget.options.vendor).subscribe(res => {
      this.sensorTable(res.Data);
    })
  }

  sensorTable(data: any) {
    const dialogRef = this.dialog.open(DialogContentExampleDialog, {
      width: '80%',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  zoneData: any = [];
  onlyUnique(value: any, index: any, self: any) {
    return self.indexOf(value) === index;
  }
  
  zoneList(projectType: any, discomType: any, districtType: any) {
    this.zoneData = [];
    this.subdivisionListData = []
    /**
     * is zone is already set
     */
    
    this.auth.getZoneList(projectType, discomType, districtType, this.type).subscribe(res => {
      this.zoneData = res.Data.filter(this.onlyUnique);
      /**
       * if zone is not ALL
       */
        if(localStorage.getItem("zone")!='ALL'){
          /**
           * if zone is set in login 
           * then set zoneData equal to zone value
           */
          this.zoneData = [localStorage.getItem("zone")]
          this.zonedisabled=true
          this.zone=localStorage.getItem("zone")
          if(localStorage.getItem("circle")=='ALL'){
            this.selectedzone()
          }
          else{
            this.circle=localStorage.getItem('circle')
            this.cirlcleListData=[localStorage.getItem('circle')]
            this.circledisabled=true
            this.selectedcircle()
          }
        }
      if(res.Data == ''){
        this.zone=''
        this.selectedzone()
      }
      else{
        this.zone=localStorage.getItem("zone")
        // this.circle="ALL"
        // this.division = "ALL"
        // this.subdivision = "ALL"
        // this.substation = "ALL"   
      }
    })
  }

  selectedzone() {
    this.auth.getCircleList(this.zone).subscribe(res => {
      console.log("circle",res)
      this.cirlcleListData = res.Data.filter(this.onlyUnique);
      this.mymap.remove();
      this.subdivisionListData = []      
      if(res.Data == ''){
        this.circle = "ALL"
        this.selectedcircle()
      }
      else{
        this.circle = "ALL"
      }
      let data2={
        type:"filter",
        selectedFilter:'Zone',
        selectedFiltername:this.zone,
        zone:this.zone,
        circle:"ALL",
        division:"ALL",
        subdivision:"ALL",
        substation:"ALL"
      }
      this.service.setzone(this.zone)
      this.service.updateData(JSON.stringify(data2))
      this.drawMap(this.projectType, this.discomType, this.districtType)
    })
  }
  selectedcircle() {
    this.subdivisionListData = []
    this.auth.getdivision(this.circle).subscribe(res => {
      this.divisionListData = res.Data.filter(this.onlyUnique);
      
      if(res.Data == ''){
        this.division = "ALL"
        this.selecteddivision()
      }else{
        this.division = "ALL"
        this.subdivision = "ALL"
        this.substation = "ALL"
      }
      let data2={
        type:"filter",
        selectedFilter:'Circle',
        selectedFiltername:this.circle,
        zone:this.zone,
        circle:this.circle,
        division:"ALL",
        subdivision:"ALL",
        substation:"ALL"
      }
      this.mymap.remove();
      this.service.setcircle(this.circle)
      this.service.updateData(JSON.stringify(data2))
      this.drawMap(this.projectType, this.discomType, this.districtType)
    })
  }
  selecteddivision() {
    this.subdivisionListData = []
    this.auth.getsubdivision(this.division).subscribe(res => {
      
      if(res.Data == ''){
        this.division="ALL"
        this.subdivision='ALL'
        this.selectedSubStation(this.division)
      }
      else{
        this.subdivision = "ALL"
        this.substation = "ALL"
      }
      let data2={
        type:"filter",
        selectedFilter:'Division',
        selectedFiltername:this.division,
        zone:this.zone,
        circle:this.circle,
        division:this.division,
        subdivision:"ALL",
        substation:"ALL"
      }
      this.service.setdivision(this.division)
      this.service.updateData(JSON.stringify(data2))
      this.subdivisionListData = res.Data.filter(this.onlyUnique);
      if (this.subdivisionListData[0] == 'Radius') {
        this.selectedSubStation(this.division);
      }
      this.mymap.remove();
      this.drawMap(this.projectType, this.discomType, this.districtType)
    })
  }
  selectedSubStation(data: any) {
    this.auth.getsubstation(data, this.division, this.circle, this.zone).subscribe(res => {      
      if(res.Data == ''){
        this.subdivision = 'ALL'
        this.drawMapsubstation(this.subdivision)
      }
      else{
        this.substation = "ALL"
      }
      let data2={
        type:"filter",
        selectedFilter:'Subdivision',
        selectedFiltername:data,
        zone:this.zone,
        circle:this.circle,
        division:this.division,
        subdivision:data,
        substation:"ALL"
      }
      this.service.setsubdivision(data)
      this.service.updateData(JSON.stringify(data2))
      this.subStationListData = res.Data.filter(this.onlyUnique);
      this.mymap.remove();
      this.drawMap(this.projectType, this.discomType, this.districtType)
      // alert(JSON.stringify(res))
    })

  }
  drawMapsubstation(data: any) {
    let data2={
      type:"filter",
      selectedFilter:'Substation',
      selectedFiltername:this.substation,
      zone:this.zone,
      circle:this.circle,
      division:this.division,
      subdivision:this.subdivision,
      substation:this.substation
    }
    this.service.setsubstation(this.substation)
    this.service.updateData(JSON.stringify(data2))
    this.mymap.remove();
    this.drawMap(this.projectType, this.discomType, this.districtType)
  }

  /**
   * draw map
   */
  drawMapSearch(projectType: any, discomType: any, districtType: any, dataget: any) {
    this.getSubstationCount(this.projectType, this.discomType, this.districtType, this.type)
    this.mymap = L.map('mapid').setView([26.82472700, 81.37655000], 6);
    L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
      maxZoom: 20,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
    }).addTo(this.mymap);
    fetch(`https://upgis.uppclonline.com/server/rest/services/AdminBoundary/MapServer/0`)
      .then(res => res)
      .then(res => {
        if (res) {
          const state = L.esri.featureLayer({
            url: 'https://upgis.uppclonline.com/server/rest/services/AdminBoundary/MapServer/0'
          }).addTo(this.mymap);
          const up = L.esri.featureLayer({
            url: 'https://upgis.uppclonline.com/server/rest/services/AdminBoundary/MapServer/1'
          }).addTo(this.mymap);
        }
      })
    this.auth.getMapData(projectType, discomType, districtType, this.type, this.zone, this.circle, this.division, this.subdivision, this.substation).subscribe(res => {
      this.getJsonData = res.Data
      var markers = L.DonutCluster({
        chunkedLoading: true
      }, {
        key: 'title',
        arcColorDict: {
          Up: 'green',
          Down: 'red',
          Nc: 'yellow',
          NP: 'blue'
        }
      })
      var k = 1;
      for (let i = 0; i < this.getJsonData.length; i++) {
        if (this.getJsonData[i].id == dataget.id) {

          if ((i > 0) && (this.getJsonData[i].longitude === this.getJsonData[i - 1].longitude)) {
            k = k + 5;
            this.getJsonData[i].longitude = this.getJsonData[i].longitude + 0.00001 * k
          } else {
            k = 1;
          }
          if (this.getJsonData[i].device_state == 'UP') {
            var marker = L.marker([this.getJsonData[i].latitude, this.getJsonData[i].longitude], { icon: this.greenIcon, title: 'Up', id: this.getJsonData[i].id, vendor: this.getJsonData[i].vendor }).on('click', this.onClick);
          } else if (this.getJsonData[i].device_state == 'DN') {
            var marker = L.marker([this.getJsonData[i].latitude, this.getJsonData[i].longitude], { icon: this.redIcon, title: 'Down', id: this.getJsonData[i].id, vendor: this.getJsonData[i].vendor }).on('click', this.onClick);
          } else if (this.getJsonData[i].device_state == 'NC') {
            var marker = L.marker([this.getJsonData[i].latitude, this.getJsonData[i].longitude], { icon: this.yelloIcon, title: 'Nc', id: this.getJsonData[i].id, vendor: this.getJsonData[i].vendor }).on('click', this.onClick);
          }
          else if (this.getJsonData[i].device_state == 'NP') {
            if (this.auth.npBoolenValue) {
              var marker = L.marker([this.getJsonData[i].latitude, this.getJsonData[i].longitude], { icon: this.blueIcon, title: 'NP', id: this.getJsonData[i].id, vendor: this.getJsonData[i].vendor }).on('click', this.onClick);

            }
          }
          markers.addLayer(marker);
        }
      }
      this.mymap.addLayer(markers);
    })


  }
  drawMap(projectType: any, discomType: any, districtType: any) {
    //alert(projectType)
    this.getSubstationCount(this.projectType, this.discomType, this.districtType, this.type)
    this.mymap = L.map('mapid').setView([26.82472700, 81.37655000], 6);

    L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
      maxZoom: 20,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
    }).addTo(this.mymap);


    fetch(`https://upgis.uppclonline.com/server/rest/services/AdminBoundary/MapServer/0`)
      .then(res => res)
      .then(res => {
        if (res) {
          const state = L.esri.featureLayer({
            url: 'https://upgis.uppclonline.com/server/rest/services/AdminBoundary/MapServer/0'
          }).addTo(this.mymap);
          const up = L.esri.featureLayer({
            url: 'https://upgis.uppclonline.com/server/rest/services/AdminBoundary/MapServer/1'
          }).addTo(this.mymap);
        }
      })
    this.auth.getMapData(projectType, discomType, districtType, this.type, this.zone, this.circle, this.division, this.subdivision, this.substation).subscribe(res => {
      this.getJsonData = res.Data
      var markers = L.DonutCluster({
        chunkedLoading: true
      }, {
        key: 'title',
        arcColorDict: {
          Up: 'green',
          Down: 'red',
          Nc: 'yellow',
          NP: 'blue'
        }
      })
      var k = 1;
      for (let i = 0; i < this.getJsonData.length; i++) {
        if ((i > 0) && (this.getJsonData[i].longitude === this.getJsonData[i - 1].longitude)) {
          k = k + 5;
          this.getJsonData[i].longitude = this.getJsonData[i].longitude + 0.00001 * k
        } else {
          k = 1;
        }
        if (this.getJsonData[i].device_state == 'UP') {
          var marker = L.marker([this.getJsonData[i].latitude, this.getJsonData[i].longitude], { icon: this.greenIcon, title: 'Up', id: this.getJsonData[i].id, vendor: this.getJsonData[i].vendor }).on('click', this.onClick);
        } else if (this.getJsonData[i].device_state == 'DN') {
          var marker = L.marker([this.getJsonData[i].latitude, this.getJsonData[i].longitude], { icon: this.redIcon, title: 'Down', id: this.getJsonData[i].id, vendor: this.getJsonData[i].vendor }).on('click', this.onClick);
        } else if (this.getJsonData[i].device_state == 'NC') {
          var marker = L.marker([this.getJsonData[i].latitude, this.getJsonData[i].longitude], { icon: this.yelloIcon, title: 'Nc', id: this.getJsonData[i].id, vendor: this.getJsonData[i].vendor }).on('click', this.onClick);
        }
        else if (this.getJsonData[i].device_state == 'NP') {
          if (this.auth.npBoolenValue) {
            var marker = L.marker([this.getJsonData[i].latitude, this.getJsonData[i].longitude], { icon: this.blueIcon, title: 'NP', id: this.getJsonData[i].id, vendor: this.getJsonData[i].vendor }).on('click', this.onClick);
          }
        }
        markers.addLayer(marker);
      }
      this.mymap.addLayer(markers);
    })
  }

  getRandomLatLng(map: any) {
    var bounds = map.getBounds(),
      southWest = bounds.getSouthWest(),
      northEast = bounds.getNorthEast(),
      lngSpan = northEast.lng - southWest.lng,
      latSpan = northEast.lat - southWest.lat;

    return new L.LatLng(
      southWest.lat + latSpan * Math.random(),
      southWest.lng + lngSpan * Math.random());
  }
  // dyanmicDownloadByHtmlTag(data: any) {
  //   const blob = new Blob([data], { type: 'text/csv' });
  //   const url = window.URL.createObjectURL(blob);
  //   window.open(url);
  // }
}
@Component({
  selector: 'table-dialog',
  templateUrl: 'table-dialog.html',
  styleUrls: ['./home.component.scss']
})
export class DialogContentExampleDialog implements OnInit {
  dataSource: any;
  link2: any;
  fid: any;
  constructor(private router: Router,
    public dialogRef: MatDialogRef<FeederSearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }
  ELEMENT_DATA = []

  displayedColumns: string[] = ['meter_sr_no', 'KVAh', 'KWh', 'discom_name', 'zone_name', 'circle_name', 'division_name', 'substation_name', 'last_event_time', 'feeder_name', 'project'];

  /**
   * absolute value 
   */
  absValue(value:any){
    return Math.abs(value)
  }
  getPowerStatus(state: any) {

    if (state == "Y" || state == 1) {
      return '<img title="ON" src="http://feeder.myxenius.com/app_images/greendot.png" width="16" height="16"/>';
    } else if (state == "N" || state == 0)
      return '<img title="OFF" src="http://feeder.myxenius.com/app_images/reddot.png" width="16" height="16"/>';
    else
      return '<img title="NOT APPLICABLE" src="http://feeder.myxenius.com/app_images/bullet_grey.png" width="16" height="16"/>';
  }
  statusMeterWithTime(status: string | number, ts: any) {
    if (status == "Y" || status == 1) {
      return '<img title="ON' + ' Since (' + ts + ')" src="http://feeder.myxenius.com/app_images/greendot.png" width="16" height="16"/>';
    } else if (status == "N" || status == 0)
      return '<img title="OFF' + ' Since (' + ts + ')" src="http://feeder.myxenius.com/app_images/reddot.png" width="16" height="16"/>';
    else
      return '<img title="STATUS NOT AVAILABLE" src="http://feeder.myxenius.com/app_images/bullet_grey.png" width="16" height="16"/>';
  }
  DateFormat(time: string | null) {
    if ((time == null) || (time == '') || (time == 'NA')) {
      return "-";
    } else {
      let now = new Date(time.replace(/\-/g, '\/').replace(/[T|Z]/g, ' '));
      let year = "" + now.getFullYear();
      let month = "" + (now.getMonth() + 1); if (month.length == 1) { month = "0" + month; }
      let day = "" + now.getDate(); if (day.length == 1) { day = "0" + day; }
      let hour = "" + now.getHours(); if (hour.length == 1) { hour = "0" + hour; }
      let minute = "" + now.getMinutes(); if (minute.length == 1) { minute = "0" + minute; }
      let second = "" + now.getSeconds(); if (second.length == 1) { second = "0" + second; }
      return day + "-" + month + "-" + year + " " + hour + ":" + minute + ":" + second;

    }
  }

  ngOnInit(): void {
    // alert(JSON.stringify(this.data))
    // this.route.navigate
    this.fid = this.data.id
    let currentURL = window.location.href;
    let tmp = currentURL.split("/")
    if (this.data.vendor == 'O') {
      this.link2 = tmp[0] + "//" + tmp[2] + "/vendorDetails/" + this.data.id
    } else {
      this.link2 = tmp[0] + "//" + tmp[2] + "/vendorDetails/" + this.data.id
    }
    this.dataSource = new MatTableDataSource<any>([this.data]);

  }
  openurl() {
    window.open(this.link2, "_blank")
  }
  getSplitname(data: string, number: number) {
    return data.split(":")[number]

  }
 
}


