import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-supply-dialog',
  templateUrl: './supply-dialog.component.html',
  styleUrls: ['./supply-dialog.component.scss']
})
export class SupplyDialogComponent implements OnInit {
  dataSource: any;
  
  fileName = 'supplyreport.xlsx';
  downloadexce(): void {
     this.exportAsExcelFile(this.reportJSON)

  }
  public exportAsExcelFile(json: any[]): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    XLSX.writeFile(workbook, this.fileName);
  }
  reportJSON:any =[]
  constructor(
    public dialogRef: MatDialogRef<SupplyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log(JSON.stringify(data))
  }
  displayedColumns: string[] = ['FEEDER_NAME','METER_NUMBER','DISCOM', 'NO_OF_DAYS', 'OUTAGE_COUNT', 'OUTAGE_DURATION', 'Current','Voltage'];

  ngOnInit(): void {
    this.reportJSON =[];
    for(var i=0 ;i<this.data.length;i++){
      this.reportJSON.push({
        FEEDER_NAME:this.data[i].FEEDER_NAME,
        METER_NUMBER:this.data[i].METER_NUMBER,
        DISCOM:this.data[i].DISCOM,
        NO_OF_DAYS:this.data[i].NO_OF_DAYS,
        OUTAGE_COUNT:this.data[i].OUTAGE_COUNT,
        OUTAGE_DURATION:this.data[i].OUTAGE_DURATION,
        Current:this.data[i].R_CURRENT + '|' + this.data[i].Y_CURRENT + '|' +     this.data[i].B_CURRENT,
        Voltage:this.data[i].R_VOLTAGE  + '|' + this.data[i].Y_VOLTAGE + '|' +     this.data[i].B_VOLTAGE
      })
    }
    this.dataSource = new MatTableDataSource<any>(this.data);

  }

}
