<button class="close" mat-button (click)="onCancel()" style="margin-bottom:8px;">X</button>

<div class="roaster-main">
    <div class="example-container">
        <form [formGroup]="range">
            <!-- ============ discom ============== -->
            <mat-form-field appearance="fill">
                <mat-label>DISCOM</mat-label>
                <mat-select formControlName="discom_name" (selectionChange)="getZone($event)">
                  <mat-option *ngFor="let item of discomList" value="{{item.value}}">{{item.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <!-- ============ ZONE ================ -->
            <mat-form-field appearance="fill">
                <mat-label>ZONE</mat-label>
                <mat-select formControlName="zone_name" (selectionChange)="selectedzone($event)">
                  <mat-option value="">-- SELECT --</mat-option>
                  <mat-option *ngFor="let item of zoneData" value="{{item}}">{{item}}</mat-option>
                </mat-select>
            </mat-form-field>
            <!-- ============ CIRCLE ================ -->
            <mat-form-field appearance="fill">
                <mat-label>CIRCLE</mat-label>
                <mat-select formControlName="circle_name" (selectionChange)="selectedcircle($event)">
                  <mat-option value="">-- SELECT --</mat-option>
                  <mat-option *ngFor="let item of cirlcleListData" value="{{item}}">{{item}}</mat-option>
                </mat-select>
            </mat-form-field>
            <!-- ============ DIVISION =============== -->
            <mat-form-field appearance="fill">
                <mat-label>DIVISION</mat-label>
                <mat-select formControlName="division_name" (selectionChange)="selecteddivision($event)">
                  <mat-option value="">-- SELECT --</mat-option>
                  <mat-option *ngFor="let item of divisionListData" value="{{item}}">{{item}}</mat-option>
                </mat-select>
            </mat-form-field>
            <!-- ================= SUB DIVISION =================== -->
            <mat-form-field appearance="fill">
                <mat-label>SUB DIVISION</mat-label>
                <mat-select formControlName="sub_division_name" (selectionChange)="selectedsubdivision($event)">
                  <mat-option value="">-- SELECT --</mat-option>
                  <mat-option *ngFor="let item of subdivisionListData" value="{{item}}">{{item}}</mat-option>
                </mat-select>
            </mat-form-field>
            <!-- ============ SUBSTATION ============ -->
            <mat-form-field appearance="fill">
                <mat-label>SUBSTATION</mat-label>
                <mat-select formControlName="substation_name" (selectionChange)="selectedstation($event)">
                  <mat-option value="">-- SELECT --</mat-option>
                  <mat-option *ngFor="let item of substationListData" value="{{item}}">{{item}}</mat-option>
                </mat-select>
            </mat-form-field>
            <!-- =========== FEEDER =========== -->
            <mat-form-field appearance="fill">
                <mat-label>FEEDER</mat-label>
                <mat-select formControlName="feeder_name">
                  <mat-option value="">-- SELECT --</mat-option>
                  <mat-option *ngFor="let item of feederData" value="{{item}}">{{item}}</mat-option>

                </mat-select>
            </mat-form-field>
            <!-- =========== OUTAGE TYPE ========== -->
            <mat-form-field appearance="fill">
                <mat-label>OUTAGE TYPE</mat-label>
                <mat-select formControlName="outage_type">
                  <mat-option value="">-- SELECT --</mat-option>
                  <mat-option value="MAINTAINENCE">MAINTAINENCE</mat-option>
                  <mat-option value="OTHERS">OTHERS</mat-option>
                </mat-select>
            </mat-form-field>
            <!-- ==================== reason ==================== -->
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>REASON</mat-label>
                <textarea matInput placeholder="ENTER REASON" formControlName="reason"></textarea>
            </mat-form-field>
            <!-- =================== remark ===================== -->
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>REMARK</mat-label>
                <textarea matInput placeholder="ENTER REMARK" formControlName="remark"></textarea>
            </mat-form-field>
            <!-- ========== PROJECT FILTER =========== -->
            <!-- ======================= start ================== -->
            <mat-form-field appearance="fill">
                <input matInput [ngxMatDatetimePicker]="picker3" placeholder="FROM DATE END TIME" 
                   [min]="minDate" [max]="maxDate" [disabled]="disabled" formControlName="start">
                <mat-datepicker-toggle matSuffix [for]="$any(picker3)"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker3 [showSpinners]="showSpinners" [showSeconds]="showSeconds"
                   [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond"
                   [touchUi]="touchUi"  [enableMeridian]="enableMeridian">
                </ngx-mat-datetime-picker>
            </mat-form-field>
            <!-- ====================== end ======================== -->
            <mat-form-field appearance="fill">
                <input matInput [ngxMatDatetimePicker]="picker4" placeholder="TO DATE END TIME" 
                   [min]="minDate" [max]="maxDate" [disabled]="disabled" formControlName="end">
                <mat-datepicker-toggle matSuffix [for]="$any(picker4)"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker4 [showSpinners]="showSpinners" [showSeconds]="showSeconds"
                   [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond"
                   [touchUi]="touchUi"  [enableMeridian]="enableMeridian"  >
                </ngx-mat-datetime-picker>
             </mat-form-field>
              <button mat-stroked-button color="primary" style="margin-left:10px" (click)="addRoasterDetails()">SUBMIT</button>
        </form>
      </div>
</div>