import { Component, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-no-modem-dash',
  templateUrl: './no-modem-dash.component.html',
  styleUrls: ['./no-modem-dash.component.scss']
})
export class NoModemDashComponent implements OnInit {
  chart2: any;

  constructor() { }

  ngOnInit(): void {
    setTimeout(()=>{
      this.chartFunction();

    },400)
  }
  chartFunction(){
    this.chart2 = new Chart({chart: {
      type: 'column'
  },
  title: {
    text: '  No Modem Feeder 6563'
  },
  xAxis: {
      categories: ['PUVVNL', 'MVVNL', 'DVVNL', 'PVVNL', 'UPPCL']
  },
  yAxis: {
      min: 0,
      title: {
          text: 'Total No Modem Feeder '
      },
      stackLabels: {
          enabled: true,
          style: {
              fontWeight: 'bold',
              
          }
      }
  },
  legend: {
      align: 'right',
      x: -30,
      verticalAlign: 'top',
      y: 25,
      floating: true,
       borderColor: '#CCC',
      borderWidth: 1,
      shadow: false
  },
  tooltip: {
      headerFormat: '<b>{point.x}</b><br/>',
      pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
  },
  plotOptions: {
      column: {
          stacking: 'normal',
          dataLabels: {
              enabled: true
          }
      }
  },
  series: [{
    type: 'column',
  
      name: 'URBAN',
       data: [271, 788, 236, 226, 1521]
  }, {
    type: 'column',
  
      name: 'RURAL',
      data: [817, 913, 1765, 1197, 4692]
  }, {
    type: 'column',
  
      name: 'AGRICULTURE',
      data: [4, 15, 101, 230, 350]
  }] });
  }

}
