<h2 mat-dialog-title>Supply Feeder Detail </h2>
<mat-dialog-content >

    <button mat-raised-button color="primary" (click)="downloadexce()">download</button>
<div class ="overflow" style="overflow: auto;height: 400px;">
    <table mat-table [dataSource]="dataSource" style="width:100%;">

        <!-- Position Column -->


        <!-- Name Column -->
        <ng-container matColumnDef="FEEDER_NAME">
            <th mat-header-cell *matHeaderCellDef> Feeder Name </th>
            <td mat-cell *matCellDef="let element" style="color: #293368;cursor: pointer;"  > <span
                    *ngIf="element.FEEDER_NAME"> {{element.FEEDER_NAME}}</span>  </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="METER_NUMBER">
            <th mat-header-cell *matHeaderCellDef> Meter Sr.No. </th>
            <td mat-cell *matCellDef="let element"> {{element.METER_NUMBER}} </td>
        </ng-container>
        <ng-container matColumnDef="DISCOM">
            <th mat-header-cell *matHeaderCellDef> Discom </th>
            <td mat-cell *matCellDef="let element"> {{element.DISCOM}} </td>
        </ng-container>
         
        <ng-container matColumnDef="NO_OF_DAYS">
            <th mat-header-cell *matHeaderCellDef> No. Of Days </th>
            <td mat-cell *matCellDef="let element"> {{element.NO_OF_DAYS}} </td>
        </ng-container>
        <ng-container matColumnDef="OUTAGE_COUNT">
            <th mat-header-cell *matHeaderCellDef> Outage Count </th>
            <td mat-cell *matCellDef="let element"> {{element.OUTAGE_COUNT}} </td>
        </ng-container>
        <ng-container matColumnDef="OUTAGE_DURATION">
            <th mat-header-cell *matHeaderCellDef>   OUTAGE_DURATION </th>
            <td mat-cell *matCellDef="let element"> {{element.OUTAGE_DURATION}} </td>
        </ng-container>
        <!-- Symbol Column -->
        <ng-container matColumnDef="Current">
            <th mat-header-cell *matHeaderCellDef> Current (R|Y|B) </th>
            <td mat-cell *matCellDef="let element"> {{element.R_CURRENT + '|' + element.Y_CURRENT + '|' +
                element.B_CURRENT}} </td>
        </ng-container>
        <ng-container matColumnDef="Voltage">
            <th mat-header-cell *matHeaderCellDef> Voltage (R|Y|B) </th>
            <td mat-cell *matCellDef="let element"> {{element.R_VOLTAGE + '|' + element.Y_VOLTAGE + '|' +
                element.B_VOLTAGE}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close style="background-color:red !important;color:white">Cancel</button>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->

</mat-dialog-actions>