import { Component, OnInit,AfterViewInit,Inject } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { CommonService } from 'src/app/common.service';
import * as XLSX from 'xlsx';
import { FormGroup, FormControl } from '@angular/forms';

import {
  ElementRef, ViewChild 
} from '@angular/core';
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DatePipe } from '@angular/common';

import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';



import {MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AddRoasterComponent } from '../add-roaster/add-roaster.component';


@Component({
  selector: 'app-roaster-details',
  templateUrl: './roaster-details.component.html',
  styleUrls: ['./roaster-details.component.scss']
})
export class RoasterDetailsComponent implements OnInit {
  discom_id: any;
  zoneData: any;
  zone_id: any;
  cirlcleListData: any;
  circle_id: any;
  divisionListData: any;
  division_id: any;
  subdivisionListData: any;
  subdivision_id: any;
  substationListData: any;

  dataSource : any = [];
  dataShow : boolean = false;
  columnsToDisplay = ['DISCOM','ZONE','CIRCLE','DIVISION', 'SUB_DIVISION','SUB_STATION','METER_NUMBER','TYPE','FROM_DATE','TO_DATE','REMARK','PROFILE_ID','REASON'];

  /**
   *Declare discomlist
   */
   discomList = [
    { name: "ALL", value: "ALL" },
    { name: "PuVVNL", value: "5a71b954cf5e58.55295458" },
    { name: "MVVNL", value: "5a71b8e6167566.89648438" },
    { name: "DVVNL", value: "5a71b6d35156d7.70878980" },
    { name: "PVVNL", value: "5a71b98adb88d0.91464350" },
    { name: "KESCo", value: "5a71b8a355c2d4.24012768" }
  ]
  feeder_id: any;
  feederData: any = [];
  length: any;
  dataSourceWithPageSize: any;
  
  constructor(private commonservice: CommonService,private datepipe: DatePipe, private AuthService: AuthService, private service: CommonService, private NgxUiLoaderService: NgxUiLoaderService,public dialog: MatDialog ) { }

  @ViewChild('paginator') paginator !: MatPaginator;
  @ViewChild('paginatorPageSize')
  paginatorPageSize!: MatPaginator;

  pageSizes = [3, 5, 7];


  ngOnInit(): void {
    this.range.value.start = new Date()
    this.range.value.end = new Date()
    this.getRoasterDetails()

  }

  range = new FormGroup({
    start: new FormControl(this.datepipe.transform(new Date(),'yyyy-MM-dd')),
    end: new FormControl(this.datepipe.transform(new Date(),'yyyy-MM-dd')),
    discom_name : new FormControl('ALL'),
    zone_name : new FormControl('ALL'),
    circle_name : new FormControl('ALL'),
    division_name : new FormControl('ALL'),
    sub_division_name : new FormControl('ALL'),
    substation_name : new FormControl('ALL'),
    feeder_name : new FormControl('ALL'),
    outage_type : new FormControl('ALL'),
  });

  /************** DISCOM LIST *************/
  getZone(e:any){
    this.discom_id = e.value
    this.AuthService.getZoneList('ALL',e.value,'ALL','OUTGOING').subscribe(res=>{
      this.zoneData = res.Data
    })
   }
   selectedzone(e:any){
    this.zone_id = e.value
    this.AuthService.getCircleList(e.value).subscribe(res=>{
      this.cirlcleListData = res.Data
    })
   }
   selectedcircle(e:any){
    this.circle_id = e.value
    this.AuthService.getdivision(e.value).subscribe(res=>{
      this.divisionListData = res.Data
    })
    
   }
   selecteddivision(e:any){
    this.division_id = e.value
    this.AuthService.getsubdivision(e.value).subscribe(res=>{
      this.subdivisionListData = res.Data
    })
   }
   selectedsubdivision(e:any){
    this.subdivision_id = e.value
    this.AuthService.getsubstation(e.value,this.division_id,this.circle_id,this.zone_id).subscribe(res=>{
      this.substationListData = res.Data
    })
   }

   selectedstation(e:any){
    this.feeder_id = e.value
    this.AuthService.getFeederList(this.feeder_id).subscribe(res=>{
      this.feederData = res.Data
    })
   }

  /** ***************** get roaster details list ***************** */
  getRoasterDetails(){
    let res = this.discomList.filter((item) => {
      if(item.value == this.range.value.discom_name){ this.range.value.discom_name = item.name } 
  });
    this.NgxUiLoaderService.start();
    this.AuthService.getRoasterDetailList(this.range.value.discom_name,this.range.value.zone_name,this.range.value.circle_name,this.range.value.division_name,this.range.value.sub_division_name,this.range.value.substation_name,this.range.value.feeder_name,this.datepipe.transform(this.range.value.start,'yyyy-MM-dd'),this.datepipe.transform(this.range.value.end,'yyyy-MM-dd')).subscribe(res => {
      this.dataSource = res.Data
      this.dataSourceWithPageSize = res.Data
      this.length = res.Data.length
      this.NgxUiLoaderService.stop()
      if(this.dataSource != ''){
        this.dataShow = true;
      }
      this.dataSource.paginator = this.paginator;
    this.dataSourceWithPageSize.paginator = this.paginatorPageSize;
    })
  }

  /** ********************* add roaster popup ******************* */
  addRoaster(){
    const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        this.dialog.open(AddRoasterComponent, dialogConfig);
  }
/** */
/** ********************** Download EXCEL ********************** */
public exportAsExcelFile(json: any[]): void {
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
  const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
  XLSX.writeFile(workbook,"PLANNED_OUTAGE_REPORT.xlsx");
}
downloadexce(): void {
  this.exportAsExcelFile(this.dataSource)
}
ngAfterViewInit() {
  this.getRoasterDetails()
}


}
