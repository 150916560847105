import { Component ,HostListener } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'UPPCL';
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(e: KeyboardEvent) {
    if (e.key === 'F12') {
        return false;
    }
    if (e.ctrlKey && e.shiftKey && e.key === "I") {
        return false;
    }
    if (e.ctrlKey && e.shiftKey && e.key === "C") {
        return false;
    }
    if (e.ctrlKey && e.shiftKey && e.key === "J") {
        return false;
    }
    if (e.ctrlKey && e.key == "U") {
        return false;
    }
    return true;
}
}


if (typeof Worker !== 'undefined') {
  // Create a new
  const worker = new Worker('./app.worker', { type: 'module' });
  worker.onmessage = ({ data }) => {
    console.log(`page got message: ${data}`);
  };
  worker.postMessage('hello');
} else {
  // Web Workers are not supported in this environment.
  // You should add a fallback so that your program still executes correctly.
}