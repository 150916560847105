<h2 mat-dialog-title>Feeder List</h2>
<mat-dialog-content>
      <mat-card  >
          <div style="overflow-x:auto;" class="container scroll">
              <!-- <cdk-virtual-scroll-viewport itemSize="50"  #scrollViewport >        -->
              <table width="2000px" id ="excel-table">
                <tr style="background-color: white;" *ngIf="checkJSON =='DOWN'">
                  <th width="10px" [style.top]="inverseOfTranslation">SR. NO.</th>
                  <th [style.top]="inverseOfTranslation">PROJECT</th>
                  <th [style.top]="inverseOfTranslation">DISCOM</th>
                  <th [style.top]="inverseOfTranslation">ZONE</th>
                  <th [style.top]="inverseOfTranslation">DISTRICT</th>
                  <th [style.top]="inverseOfTranslation">CIRCLE</th>
                  <th [style.top]="inverseOfTranslation">TOWN</th>
                  <th [style.top]="inverseOfTranslation">DIVISION</th>
                  <th [style.top]="inverseOfTranslation">SUB DIVISION</th>
                  <th [style.top]="inverseOfTranslation">SUB STATION</th>
                  <th [style.top]="inverseOfTranslation">FEEDER NAME</th>
                  <th [style.top]="inverseOfTranslation">METER NUMBER</th>
                  <th [style.top]="inverseOfTranslation">CURRENT(R|Y|B)</th>
                  <th [style.top]="inverseOfTranslation">VOLTAGE(R|Y|B)</th>
                  <th [style.top]="inverseOfTranslation">PF(R|Y|B)</th>
                  <th [style.top]="inverseOfTranslation">FEEDER CODE</th>
                  <th [style.top]="inverseOfTranslation">SERVICE PROVIDER</th>
                  <th [style.top]="inverseOfTranslation" style="padding-left: 75px;padding-right: 75px;">MODEM NO</th>
                  <th [style.top]="inverseOfTranslation">FEEDER TYPE</th>
                  <th [style.top]="inverseOfTranslation">DEVICE STATE</th>
                  <th [style.top]="inverseOfTranslation" >S.S.O NO</th>
                  <th [style.top]="inverseOfTranslation" >JE NO</th>
                  <th [style.top]="inverseOfTranslation" >S.D.O NO</th>
                  <th [style.top]="inverseOfTranslation" >EE NO</th>
                  <th [style.top]="inverseOfTranslation" >EVENT TIME</th>
                  <th [style.top]="inverseOfTranslation" >OUTAGE DURATION</th>
                  <th [style.top]="inverseOfTranslation" style="padding-left: 54px;padding-right: 54px;">LAST PACKET TIME</th>
                </tr>
                <tr style="background-color: white;" *ngIf="checkJSON !='DOWN'">
                  <th width="10px" [style.top]="inverseOfTranslation">SR. NO.</th>
                  <th [style.top]="inverseOfTranslation">PROJECT</th>
                  <th [style.top]="inverseOfTranslation">DISCOM</th>
                  <th [style.top]="inverseOfTranslation">ZONE</th>
                  <th [style.top]="inverseOfTranslation">DISTRICT</th>
                  <th [style.top]="inverseOfTranslation">CIRCLE</th>
                  <th [style.top]="inverseOfTranslation">TOWN</th>
                  <th [style.top]="inverseOfTranslation">DIVISION</th>
                  <th [style.top]="inverseOfTranslation">SUB DIVISION</th>
                  <th [style.top]="inverseOfTranslation">SUB STATION</th>
                  <th [style.top]="inverseOfTranslation">FEEDER NAME</th>
                  <th [style.top]="inverseOfTranslation">METER NUMBER</th>
                  <th [style.top]="inverseOfTranslation">CURRENT(R|Y|B)</th>
                  <th [style.top]="inverseOfTranslation">VOLTAGE(R|Y|B)</th>
                  <th [style.top]="inverseOfTranslation">PF(R|Y|B)</th>
                  <th [style.top]="inverseOfTranslation">FEEDER CODE</th>
                  <th [style.top]="inverseOfTranslation">SERVICE PROVIDER</th>
                  <th [style.top]="inverseOfTranslation" style="padding-left: 75px;padding-right: 75px;">MODEM NO</th>
                  <th [style.top]="inverseOfTranslation">FEEDER TYPE</th>
                  <th [style.top]="inverseOfTranslation">DEVICE STATE</th>
                  <th [style.top]="inverseOfTranslation" >S.S.O NO</th>
                  <th [style.top]="inverseOfTranslation" >JE NO</th>
                  <th [style.top]="inverseOfTranslation" >S.D.O NO</th>
                  <th [style.top]="inverseOfTranslation" >EE NO</th>
                  <th [style.top]="inverseOfTranslation" >EVENT TIME</th>
                  <th [style.top]="inverseOfTranslation" style="padding-left: 54px;padding-right: 54px;">LAST PACKET TIME</th>
              </tr>
              <tbody *ngIf="checkJSON == 'SERVICE'">
                <tr *ngFor="let list of serviceJSON;let i =index">
                    <td>{{i+1}} </td>
                    <td>{{list.PROJECT}}</td>
                    <td>{{list.DISCOM}}</td>
                    <td>{{list.ZONE}}</td>
                    <td>{{list.DISTRICT}}</td>
                    <td>{{list.CIRCLE}}</td>
                    <td>{{list.TOWN}}</td>
                    <td>{{list.DIVISION}}</td>
                    <td>{{list.SUB_DIVISION}}</td>
                    <td>{{list.SUB_STATION}}</td>
                    <td>{{list.FEEDER_NAME}}</td>
                    <td>{{list.METER_NUMBER}}</td>
                    <td>{{absValue(list?.R_CURRENT)+'|'+absValue(list?.Y_CURRENT)+'|'+absValue(list?.B_CURRENT)}}</td>
                    <td>{{absValue(list?.R_VOLTAGE)+'|'+absValue(list?.Y_VOLTAGE)+'|'+absValue(list?.B_VOLTAGE)}}</td>
                    <td>{{absValue(list?.R_PF)+'|'+absValue(list?.Y_PF)+'|'+absValue(list?.B_PF)}}</td>
                    <td>{{list.FEEDER_CODE}}</td>
                    <td>{{list.SERVICE_PROVIDER}}</td>
                    <td>{{list.MODEM_NO}}</td>
                    <td>{{list.FEEDER_TYPE}}</td>
                    <td>{{list.DEVICE_STATE}}   </td>
                    <td>{{list.MOBILE_NO}}</td>
                    <td>{{list.JE_NO}}</td>
                    <td>{{list.SDO_NO}}</td>
                    <td>{{list.EE_NO}}</td>
                    <td>{{list.EVENT_TIME}}</td> 
                    <td>{{list.LAST_PACKET_TIME}}</td> 
                    <!-- <td>{{list.LAST_INCREMENTAL}}</td> 
                    <td>{{list.READING_TIME}}</td>  -->
                </tr>
              </tbody>
              <tbody *ngIf="checkJSON == 'DEFAULT'">
                <tr *ngFor="let list of masterData;let i =index">
                    <td>{{i+1}} </td>
                    <td>{{list.PROJECT}}</td>
                    <td>{{list.DISCOM}}</td>
                    <td>{{list.ZONE}}</td>
                    <td>{{list.DISTRICT}}</td>
                    <td>{{list.CIRCLE}}</td>
                    <td>{{list.TOWN}}</td>
                    <td>{{list.DIVISION}}</td>
                    <td>{{list.SUB_DIVISION}}</td>
                    <td>{{list.SUB_STATION}}</td>
                    <td>{{list.FEEDER_NAME}}</td>
                    <td>{{list.METER_NUMBER}}</td>
                    <td>{{absValue(list?.R_CURRENT)+'|'+absValue(list?.Y_CURRENT)+'|'+absValue(list?.B_CURRENT)}}</td>
                    <td>{{absValue(list?.R_VOLTAGE)+'|'+absValue(list?.Y_VOLTAGE)+'|'+absValue(list?.B_VOLTAGE)}}</td>
                    <td>{{absValue(list?.R_PF)+'|'+absValue(list?.Y_PF)+'|'+absValue(list?.B_PF)}}</td>
                    <td>{{list.FEEDER_CODE}}</td>
                    <td>{{list.SERVICE_PROVIDER}}</td>
                    <td>{{list.MODEM_NO}}</td>
                    <td>{{list.FEEDER_TYPE}}</td>
                    <td>{{list.DEVICE_STATE}}   </td>
                    <td>{{list.MOBILE_NO}}</td>
                    <td>{{list.MOBILE_NO_1}}</td>
                    <td>{{list.MOBILE_NO_2}}</td>
                    <td>{{list.MOBILE_NO_3}}</td>
                    <td>{{list.LAST_EVENT_TIME}}</td> 
                    <td>{{list.LAST_PACKET_TIME}}</td> 
                    <!-- <td>{{list.LAST_INCREMENTAL}}</td> 
                    <td>{{list.READING_TIME}}</td>  -->
                </tr>
              </tbody>
              <tbody *ngIf="checkJSON == 'DOWN'">
                <tr *ngFor="let list of masterData;let i =index">
                    <td>{{i+1}} </td>
                    <td>{{list.PROJECT}}</td>
                    <td>{{list.DISCOM}}</td>
                    <td>{{list.ZONE}}</td>
                    <td>{{list.DISTRICT}}</td>
                    <td>{{list.CIRCLE}}</td>
                    <td>{{list.TOWN}}</td>
                    <td>{{list.DIVISION}}</td>
                    <td>{{list.SUB_DIVISION}}</td>
                    <td>{{list.SUB_STATION}}</td>
                    <td>{{list.FEEDER_NAME}}</td>
                    <td>{{list.METER_NUMBER}}</td>
                    <td>{{absValue(list?.R_CURRENT)+'|'+absValue(list?.Y_CURRENT)+'|'+absValue(list?.B_CURRENT)}}</td>
                    <td>{{absValue(list?.R_VOLTAGE)+'|'+absValue(list?.Y_VOLTAGE)+'|'+absValue(list?.B_VOLTAGE)}}</td>
                    <td>{{absValue(list?.R_PF)+'|'+absValue(list?.Y_PF)+'|'+absValue(list?.B_PF)}}</td>
                    <td>{{list.FEEDER_CODE}}</td>
                    <td>{{list.SERVICE_PROVIDER}}</td>
                    <td>{{list.MODEM_NO}}</td>
                    <td>{{list.FEEDER_TYPE}}</td>
                    <td>{{list.DEVICE_STATE}}   </td>
                    <td>{{list.MOBILE_NO}}</td>
                    <td>{{list.MOBILE_NO_1}}</td>
                    <td>{{list.MOBILE_NO_2}}</td>
                    <td>{{list.MOBILE_NO_3}}</td>
                    <td>{{list.LAST_EVENT_TIME}}</td> 
                    <td>{{list.OUTAGE_DURATION}}</td> 
                    <td>{{list.LAST_PACKET_TIME}}</td> 
                    <!-- <td>{{list.LAST_INCREMENTAL}}</td> 
                    <td>{{list.READING_TIME}}</td>  -->
                </tr>
              </tbody>
           </table>
      <!-- </cdk-virtual-scroll-viewport> -->
          </div>
      </mat-card>
      </mat-dialog-content>
      <mat-dialog-actions>
        <button mat-button mat-dialog-close color="warn">Close</button>
        <button mat-raised-button color="primary"   (click)="downloadexce()">Download</button>
        <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
      </mat-dialog-actions>
    