import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { CommonService } from 'src/app/common.service';
import * as XLSX from 'xlsx';

import {
  ElementRef, ViewChild
} from '@angular/core';
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-feederfilterllist',
  templateUrl: './feederfilterllist.component.html',
  styleUrls: ['./feederfilterllist.component.scss']
})
export class FeederfilterllistComponent implements OnInit {
  @ViewChild(CdkVirtualScrollViewport, { static: false })
  public viewPort: CdkVirtualScrollViewport | undefined;
  fileName = 'MasterData.xlsx';
  show: any = 'ALL';
  downloadexce(): void {
    if(this.checkJSON == 'DEFAULT'){
      this.exportAsExcelFile(this.reportJSON)
    }
    if(this.checkJSON == 'SERVICE'){
      this.exportAsExcelFile(this.serviceJSON)
    }
    if(this.checkJSON == 'DOWN'){
      this.exportAsExcelFile(this.reportJSON)
    }
  }
  absValue(value:any){
    return Math.abs(value)
  }
  public exportAsExcelFile(json: any[]): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    XLSX.writeFile(workbook, this.fileName);
  }
  constructor(private AuthService: AuthService, private service: CommonService, private NgxUiLoaderService: NgxUiLoaderService, public dialogRef: MatDialogRef<FeederfilterllistComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.getMasterData();
  }
  _sendDataToParent(data: string) {
    this.service.changeData(data);  //invoke new Data

  }
  reportJSON: any = []
  serviceJSON:any = []
  checkJSON:any = 'DEFAULT'
  masterData: any;
  getMasterData() {
    if(this.data.from == "DOWN"){
      this.checkJSON = 'DOWN'
    }
    this.NgxUiLoaderService.start();
    var discom: any = "ALL";
    var scheme: any = "ALL";
    var district: any = "ALL";
    var circle: any = "ALL";
    var zone: any = "ALL";
    var division: any = "ALL";
    var subdivision: any = "ALL";
    var substation: any = "ALL";
    if (this.data.from == 'discom') {
      discom = this.service.getdiscom
    }
    if (this.data.from == 'district') {
      discom = this.service.getdiscom
      discom = this.service.getdiscom
      scheme = this.service.getscheme
      district = this.service.getdistrict
    }
    if (this.data.from == 'project') {
      discom = this.service.getdiscom
      scheme = this.service.getscheme
    }
    /**
     * when we click on donut chart in service provider
     */
    if (this.data.from == 'service') {
      if(this.data.serviceprovider == 'ALL'){
        this.checkJSON = 'DEFAULT'
      }
      else{
        this.checkJSON = 'SERVICE'
      }
      discom = this.service.getdiscom
      scheme = this.service.getscheme
      district = this.service.getdistrict
      zone = this.service.getzone
      circle = this.service.getcircle
      division = this.service.getdivision
      subdivision = this.service.getsubdivision
      substation = this.service.getsubstation
      this.AuthService.getMasterDatafilterV1(this.data.state, discom, scheme, district, zone, circle, division, subdivision, substation).subscribe(res => {
        this.masterData = res.Data
        for (var i = 0; i < this.masterData.length; i++) {
          /**
           * check service provider 
           */
          if( this.masterData[i].SERVICE_PROVIDER == this.data.serviceprovider){
            this.serviceJSON.push({
              "SR. NO.": i + 1,
              "PROJECT": this.masterData[i].PROJECT,
              "DISCOM": this.masterData[i].DISCOM,
              "ZONE": this.masterData[i].ZONE,
              "DISTRICT": this.masterData[i].DISTRICT,
              "CIRCLE": this.masterData[i].CIRCLE,
              "TOWN": this.masterData[i].TOWN,
              "DIVISION": this.masterData[i].DIVISION,
              "SUB_DIVISION": this.masterData[i].SUB_DIVISION,
              "SUB_STATION": this.masterData[i].SUB_STATION,
              "FEEDER_NAME": this.masterData[i].FEEDER_NAME,
              "METER_NUMBER": this.masterData[i].METER_NUMBER,
              "R_CURRENT": this.absValue(this.masterData[i].R_CURRENT),
              "B_CURRENT": this.absValue(this.masterData[i].B_CURRENT),
              "Y_CURRENT": this.absValue(this.masterData[i].Y_CURRENT),
              "R_VOLTAGE": this.absValue(this.masterData[i].R_VOLTAGE),
              "B_VOLTAGE": this.absValue(this.masterData[i].B_VOLTAGE),
              "Y_VOLTAGE": this.absValue(this.masterData[i].Y_VOLTAGE),
              "R_PF": this.absValue(this.masterData[i].R_PF),
              "B_PF": this.absValue(this.masterData[i].B_PF),
              "Y_PF": this.absValue(this.masterData[i].Y_PF),
              "FEEDER_CODE": this.masterData[i].FEEDER_CODE,
              "SERVICE_PROVIDER": this.masterData[i].SERVICE_PROVIDER,
              "MODEM_NO": this.masterData[i].MODEM_NO,
              "FEEDER_TYPE": this.masterData[i].FEEDER_TYPE,
              "LATITUDE": this.masterData[i].LATITUDE,
              "LONGITUDE": this.masterData[i].LONGITUDE,
              "DEVICE_STATE": this.masterData[i].DEVICE_STATE,
              "SSO_NO": this.masterData[i].MOBILE_NO,
              "JE_NO": this.masterData[i].MOBILE_NO_1,
              "SDO_NO": this.masterData[i].MOBILE_NO_2,
              "EE_NO": this.masterData[i].MOBILE_NO_3,
              "EVENT_TIME": this.masterData[i].LAST_EVENT_TIME,
              "LAST_PACKET_TIME": this.masterData[i].LAST_PACKET_TIME,
            })
          }
          else{
            console.log(this.masterData[i].SERVICE_PROVIDER)
          }
        }
        console.log(this.serviceJSON[0].PROJECT)
        this.NgxUiLoaderService.stop();
      })
    }
    else if (this.data.from == 'filter') {
      discom = this.service.getdiscom
      scheme = this.service.getscheme
      district = this.service.getdistrict
      zone = this.service.getzone
      circle = this.service.getcircle
      division = this.service.getdivision
      subdivision = this.service.getsubdivision
      substation = this.service.getsubstation
      this.AuthService.getMasterDatafilterV1(this.data.state, discom, scheme, district, zone, circle, division, subdivision, substation).subscribe(res => {
        this.masterData = res.Data
        for (var i = 0; i < this.masterData.length; i++) {
          this.reportJSON.push({
            "SR. NO.": i + 1,
            "PROJECT": this.masterData[i].PROJECT,
            "DISCOM": this.masterData[i].DISCOM,
            "ZONE": this.masterData[i].ZONE,
            "DISTRICT": this.masterData[i].DISTRICT,
            "CIRCLE": this.masterData[i].CIRCLE,
            "TOWN": this.masterData[i].TOWN,
            "DIVISION": this.masterData[i].DIVISION,
            "SUB DIVISION": this.masterData[i].SUB_DIVISION,
            "SUB STATION": this.masterData[i].SUB_STATION,
            "FEEDER NAME": this.masterData[i].FEEDER_NAME,
            "METER NUMBER": this.masterData[i].METER_NUMBER,
            "R_CURRENT": this.absValue(this.masterData[i].R_CURRENT),
            "B_CURRENT": this.absValue(this.masterData[i].B_CURRENT),
            "Y_CURRENT": this.absValue(this.masterData[i].Y_CURRENT),
            "R_VOLTAGE": this.absValue(this.masterData[i].R_VOLTAGE),
            "B_VOLTAGE": this.absValue(this.masterData[i].B_VOLTAGE),
            "Y_VOLTAGE": this.absValue(this.masterData[i].Y_VOLTAGE),
            "R_PF": this.absValue(this.masterData[i].R_PF),
            "B_PF": this.absValue(this.masterData[i].B_PF),
            "Y_PF": this.absValue(this.masterData[i].Y_PF),
            "FEEDER CODE": this.masterData[i].FEEDER_CODE,
            "SERVICE PROVIDER": this.masterData[i].SERVICE_PROVIDER,
            "MODEM NO": this.masterData[i].MODEM_NO,
            "FEEDER TYPE": this.masterData[i].FEEDER_TYPE,
            "LATITUDE": this.masterData[i].LATITUDE,
            "LONGITUDE": this.masterData[i].LONGITUDE,
            "DEVICE STATE": this.masterData[i].DEVICE_STATE,
            "SSO NO": this.masterData[i].MOBILE_NO,
            "JE NO": this.masterData[i].MOBILE_NO_1,
            "S.D.O NO": this.masterData[i].MOBILE_NO_2,
            "EE NO": this.masterData[i].MOBILE_NO_3,
            "EVENT TIME": this.masterData[i].LAST_EVENT_TIME,
            "LAST PACKET TIME": this.masterData[i].LAST_PACKET_TIME,
          })
        }
        this.NgxUiLoaderService.stop();
      })
    }
    else if(this.data.from == 'DOWN'){
      /**
       * master list for down feeders > 1 hr
       */
      this.masterData = this.data.resultData
      for (var i = 0; i < this.masterData.length; i++) {
        this.reportJSON.push({
          "SR. NO.": i + 1,
          "PROJECT": this.masterData[i].PROJECT,
          "DISCOM": this.masterData[i].DISCOM,
          "ZONE": this.masterData[i].ZONE,
          "DISTRICT": this.masterData[i].DISTRICT,
          "CIRCLE": this.masterData[i].CIRCLE,
          "TOWN": this.masterData[i].TOWN,
          "DIVISION": this.masterData[i].DIVISION,
          "SUB DIVISION": this.masterData[i].SUB_DIVISION,
          "SUB STATION": this.masterData[i].SUB_STATION,
          "FEEDER NAME": this.masterData[i].FEEDER_NAME,
          "METER NUMBER": this.masterData[i].METER_NUMBER,
          "R_CURRENT": this.absValue(this.masterData[i].R_CURRENT),
          "B_CURRENT": this.absValue(this.masterData[i].B_CURRENT),
          "Y_CURRENT": this.absValue(this.masterData[i].Y_CURRENT),
          "R_VOLTAGE": this.absValue(this.masterData[i].R_VOLTAGE),
          "B_VOLTAGE": this.absValue(this.masterData[i].B_VOLTAGE),
          "Y_VOLTAGE": this.absValue(this.masterData[i].Y_VOLTAGE),
          "R_PF": this.absValue(this.masterData[i].R_PF),
          "B_PF": this.absValue(this.masterData[i].B_PF),
          "Y_PF": this.absValue(this.masterData[i].Y_PF),
          "FEEDER CODE": this.masterData[i].FEEDER_CODE,
          "SERVICE PROVIDER": this.masterData[i].SERVICE_PROVIDER,
          "MODEM NO": this.masterData[i].MODEM_NO,
          "FEEDER TYPE": this.masterData[i].FEEDER_TYPE,
          "LATITUDE": this.masterData[i].LATITUDE,
          "LONGITUDE": this.masterData[i].LONGITUDE,
          "DEVICE STATE": this.masterData[i].DEVICE_STATE,
          "SSO NO": this.masterData[i].MOBILE_NO,
          "JE NO": this.masterData[i].MOBILE_NO_1,
          "S.D.O NO": this.masterData[i].MOBILE_NO_2,
          "EE NO": this.masterData[i].MOBILE_NO_3,
          "EVENT TIME": this.masterData[i].LAST_EVENT_TIME,
          "LAST PACKET TIME": this.masterData[i].LAST_PACKET_TIME,
        })
      }
      this.NgxUiLoaderService.stop();
    }
    else {
      this.AuthService.getMasterDatafilter(this.data.state, discom, scheme, district).subscribe(res => {
        this.masterData = res.Data
        for (var i = 0; i < this.masterData.length; i++) {
          this.reportJSON.push({
            "SR. NO.": i + 1,
            "PROJECT": this.masterData[i].PROJECT,
            "DISCOM": this.masterData[i].DISCOM,
            "ZONE": this.masterData[i].ZONE,
            "DISTRICT": this.masterData[i].DISTRICT,
            "CIRCLE": this.masterData[i].CIRCLE,
            "TOWN": this.masterData[i].TOWN,
            "DIVISION": this.masterData[i].DIVISION,
            "SUB DIVISION": this.masterData[i].SUB_DIVISION,
            "SUB STATION": this.masterData[i].SUB_STATION,
            "FEEDER NAME": this.masterData[i].FEEDER_NAME,
            "METER NUMBER": this.masterData[i].METER_NUMBER,
            "R_CURRENT": this.absValue(this.masterData[i].R_CURRENT),
            "B_CURRENT": this.absValue(this.masterData[i].B_CURRENT),
            "Y_CURRENT": this.absValue(this.masterData[i].Y_CURRENT),
            "R_VOLTAGE": this.absValue(this.masterData[i].R_VOLTAGE),
            "B_VOLTAGE": this.absValue(this.masterData[i].B_VOLTAGE),
            "Y_VOLTAGE": this.absValue(this.masterData[i].Y_VOLTAGE),
            "R_PF": this.absValue(this.masterData[i].R_PF),
            "B_PF": this.absValue(this.masterData[i].B_PF),
            "Y_PF": this.absValue(this.masterData[i].Y_PF),
            "FEEDER CODE": this.masterData[i].FEEDER_CODE,
            "SERVICE PROVIDER": this.masterData[i].SERVICE_PROVIDER,
            "MODEM NO": this.masterData[i].MODEM_NO,
            "FEEDER TYPE": this.masterData[i].FEEDER_TYPE,
            "LATITUDE": this.masterData[i].LATITUDE,
            "LONGITUDE": this.masterData[i].LONGITUDE,
            "DEVICE STATE": this.masterData[i].DEVICE_STATE,
            "SSO NO": this.masterData[i].MOBILE_NO,
            "JE NO": this.masterData[i].MOBILE_NO_1,
            "S.D.O NO": this.masterData[i].MOBILE_NO_2,
            "EE NO": this.masterData[i].MOBILE_NO_3,
            "EVENT TIME": this.masterData[i].LAST_EVENT_TIME,
            "LAST PACKET TIME": this.masterData[i].LAST_PACKET_TIME,
          })
        }
        this.NgxUiLoaderService.stop();
      })
    }
  }
  public get inverseOfTranslation(): string {
    if (!this.viewPort || !this.viewPort["_renderedContentOffset"]) {
      return "-0px";
    }
    let offset = this.viewPort["_renderedContentOffset"];
    return `-${offset}px`;
  }
}
