<div class=" " style="width: 100%;">
    <app-header></app-header>  
    <p style=" margin-bottom: 13px;
      text-align: center;
      color: black;
      background: black;
      padding: 10px;
      color: white;"><span style="font-size: 28px;
          margin-bottom: 5px;
          font-weight: 500;;
          border-radius: 10px;;">Feeder Details</span>
    </p>
    <hr>
    <!-- ====================== back button ===================== -->
   
    <!-- ======================== feeder vendor details ================= -->
    <div class="wrapper">
        <!-- header block -->
                <button mat-mini-fab color="primary" aria-label="Example icon button with a menu icon" style="margin-left: 10px; margin-bottom: -50px!important;" [routerLink]="['/dashboard']">
                  <mat-icon>arrow_back</mat-icon>
                </button>
        <!-- header block end here -->
        <!-- vendor detail section -->
        <ngx-ui-loader></ngx-ui-loader>
        <div class="vendor_detail">
            <div class="left-part">
                <!-- set discom Name -->
              <p class="span-text" *ngIf="discomName"><span>Discom :: </span>{{discomName}}</p>
              <p class="span-text" *ngIf="!discomName"><span>Discom :: </span> - </p>
              <!-- set zone name -->
              <p class="span-text" *ngIf="zoneName"><span>Zone :: </span>{{zoneName}}</p>
              <p class="span-text" *ngIf="!zoneName"><span>Zone :: </span> - </p>
              <!-- set district name -->
              <p class="span-text" *ngIf="districtName"><span>District :: </span>{{districtName}}</p>
              <p class="span-text" *ngIf="!districtName"><span>District :: </span> - </p>
              
            </div>
            <div class="center-part">
                 <!-- set circle name -->
                 <p class="span-text" *ngIf="circleName"><span>Circle :: </span>{{circleName}}</p>
                 <p class="span-text" *ngIf="!circleName"><span>Circle :: </span> - </p>
                 <!-- set town name -->
                 <p class="span-text" *ngIf="townName"><span>Town :: </span>{{townName}}</p>
                 <p class="span-text" *ngIf="!townName"><span>Town :: </span>-</p>
                 <!-- set division name -->
                 <p class="span-text" *ngIf="divisionName"><span>Division :: </span>{{divisionName}}</p>
                 <p class="span-text" *ngIf="!divisionName"><span>Division :: </span>-</p>
            </div>
            
            <div class="right-part">
                <!-- set sub division name -->
                <p class="span-text" *ngIf="subdivisionName"><span>SubDivision  :: </span> {{subdivisionName}}</p>
                <p class="span-text" *ngIf="!subdivisionName"><span>SubDivision  :: </span> -</p>
                <!-- sub station name -->
                <p class="span-text" *ngIf="substationName"><span>SubStation  :: </span> {{substationName}}</p>
                <p class="span-text" *ngIf="!substationName"><span>SubStation  :: </span> -</p>
                <!-- feeder name -->
                <p class="span-text" *ngIf="feederName"><span>Feeder  :: </span> {{feederName}}</p>
                <p class="span-text" *ngIf="!feederName"><span>Feeder  :: </span> -</p>
                <!-- service provider -->
                <!-- <p class="span-text" *ngIf="serviceProvider"><span>Service Provider  :: </span> {{serviceProvider}}</p>
                <p class="span-text" *ngIf="!serviceProvider"><span>Service Provider  :: </span> -</p> -->
            </div>
        </div>
        <div class="vendor-detail1">
                <span style=" font-weight: 400;" class="live-feeder-status">LIVE FEEDER</span>
                <span style="margin-left: 50px; font-weight: 800;">PROJECT :: {{projectId}}</span>
                <span style="margin-left: 50px; font-weight: 800;">SERVICE PROVIDER :: {{serviceProvider}}</span>
            <!-- <div class="projectData">
                <p style="font-weight: 400;">{{projectId}}</p>
            </div>  -->
        </div>
        <!-- vendor detail section end here -->
        <!-- feeder status block -->
        <div class="feeder-block">
            <div class="feeder-block-inner-content">
                <div class="header">FEEDER STATUS</div>
                <div class="content">
                    <img src="../../assets/elements/green_dot.png" class="img-5" *ngIf="deviceState=='ON'">
                    <img src="../../assets/elements/red_dot.png" class="img-5" *ngIf="deviceState=='OFF'">
                    <p *ngIf="!deviceState">-</p>
                </div>
            </div>
            <!-- <div class="feeder-block-inner-content">
                <div class="header">CB</div>
                <div class="content"><img src="/assets/images/green_dot.png" class="img-5"></div>
            </div> -->
            <div class="feeder-block-inner-content">
                <div class="header">METER NUMBER</div>
                <div class="content" *ngIf="serialNumber">{{serialNumber}}</div>
                <div class="content" *ngIf="!serialNumber">-</div>
            </div>
            <div class="feeder-block-inner-content">
                <div class="header">MODEM NUMBER</div>
                <div class="content" *ngIf="modemNo">{{modemNo}}</div>
                <div class="content" *ngIf="!modemNo">-</div>
            </div>
            <div class="feeder-block-inner-content">
                <div class="header">READING(KVAH)</div>
                <div class="content" *ngIf="kvah">{{kvah}}</div>
                <div class="content" *ngIf="!kvah">-</div>
            </div>
            <div class="feeder-block-inner-content">
                <div class="header">READING(KWH)</div>
                <div class="content" *ngIf="kwh">{{kwh}}</div>
                <div class="content" *ngIf="!kwh">-</div>
            </div>
        </div>
        <div class="feeder-block">
            <div class="feeder-block-inner-content">
                <div class="header">Load(KVA)</div>
                <div class="content">1590</div>
            </div>
            <div class="feeder-block-inner-content">
                <div class="header">Voltage(R|Y|B)</div>
                <div class="content">
                  <span *ngIf="rvoltage">{{rvoltage}}</span>
                        <span *ngIf="!rvoltage">-</span>|
                        <span *ngIf="yvoltage">{{yvoltage}}</span>
                        <span *ngIf="!yvoltage">-</span>|
                        <span *ngIf="bvoltage">{{bvoltage}}</span>
                        <span *ngIf="!bvoltage">-</span>
                </div>
            </div>
            <div class="feeder-block-inner-content">
                <div class="header">Current(R|Y|B)</div>
                <div class="content">
                    <span *ngIf="rcurrent">{{rcurrent}}</span>
                        <span *ngIf="!rcurrent">-</span>|
                        <span *ngIf="ycurrent">{{ycurrent}}</span>
                        <span *ngIf="!ycurrent">-</span>|
                        <span *ngIf="bcurrent">{{bcurrent}}</span>
                        <span *ngIf="!bcurrent">-</span>
                </div>
            </div>
            <div class="feeder-block-inner-content">
                <div class="header">LAST PACKET</div>
                <div class="content" *ngIf="lastpacketTime">{{lastpacketTime | date: 'dd/MM/yyyy HH:mm:ss'}}</div>
                <div class="content" *ngIf="!lastpacketTime">-</div>
            </div>
            <div class="feeder-block-inner-content">
                <div class="header">LAST EVENT</div>
                <div class="content" *ngIf="lasteventTime">{{lasteventTime | date: 'dd/MM/yyyy HH:mm:ss'}}</div>
                <div class="content" *ngIf="!lasteventTime">-</div>
            </div>
        </div>
        <!-- feeder status block end here -->
        <!-- feeder status block 2 -->
        <div class="feeder-block">
            <div class="feeder-block-inner-content1">
                <div class="header left">CURRENT MONTH ENERGY SUPPLIED {{date | date:'MM-YYYY'}} [ UOM : {{uom}}]
                    <!-- =============== single date filter =============== -->
                    <!-- <mat-form-field appearance="fill">
                        <mat-label>Choose a date</mat-label>
                        <input matInput [matDatepicker]="picker" (dateInput)="OnDateChange($event.value)">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                      </mat-form-field> -->
                </div>
                <div class="content" >
                    <!-- <ngx-ui-loader></ngx-ui-loader> -->
                    <div [chart]="chart1" style="width:100%;height: 300px;"></div>
                </div>
            </div>
            <div class="feeder-block-inner-content1">
                <div class="header center">TODAY'S VITAL STATICS</div>
                <div class="content">
                   <table>
                       <tr>
                           <td><p>Interruption Count</p></td>
                           <td *ngIf="outageCount"><p>{{outageCount}}</p></td>
                           <td *ngIf="!outageCount"><p>-</p></td> 
                       </tr>
                       <tr>
                            <td><p>Interruption Duration</p></td>
                            <td *ngIf="outageDetail"><p>{{outageDetail}}</p></td>
                            <td *ngIf="!outageDetail"><p>-</p></td>
                        </tr>
                        <tr>
                            <td><p>Estimated EUL(+15%)MVAh</p></td>
                            <td><p>0</p></td>
                        </tr>
                        <tr>
                            <td><p>Estimated EUL(0%)MVAh</p></td>
                            <td><p>0</p></td>
                        </tr>
                        <tr>
                            <td><p>Estimated EUL(-15%)MVAh</p></td>
                            <td><p>0</p></td>
                        </tr>
                   </table>
                </div>
            </div>
        </div>
        <!-- feeder block 2 end here -->
        <!-- feeder block 3 start here -->
        <div class="feeder-block">
            <div class="feeder-block-inner-content2">
                <div class="header center">MONTH {{date | date:'MM-YYYY'}}</div>
                <div class="content">
                    <table>
                        <tr>
                            <td><p>Outage Count & Duration</p></td>
                            <td *ngIf="outageData"><p>{{outageData.Data.TOTAL_COUNT}}</p></td>
                            <td *ngIf="!outageData"><p>-</p></td>
                            <td *ngIf="outageData"><p>{{outageData.Data.TOTAL_OUTAGE}}</p></td>
                            <td *ngIf="!outageData"><p>-</p></td>
                        </tr>
                        <tr>
                            <td><p>Less Than 5 Minutes</p></td>
                            <td *ngIf="outageData"><p>{{outageData.Data.LESS_THAN_5_COUNT}}</p></td>
                            <td *ngIf="!outageData"><p>-</p></td>
                            <td *ngIf="outageData"><p>{{outageData.Data.LESS_THAN_5_OUTAGE}}</p></td>
                            <td *ngIf="!outageData"><p>-</p></td>
                        </tr>
                        <tr>
                            <td><p>Between 5 to 30 Minutes</p></td>
                            <td *ngIf="outageData"><p>{{outageData.Data.LESS_THAN_30_COUNT}}</p></td>
                            <td *ngIf="!outageData"><p>-</p></td>
                            <td *ngIf="outageData"><p>{{outageData.Data.LESS_THAN_30_OUTAGE}}</p></td>
                            <td *ngIf="!outageData"><p>-</p></td>
                        </tr>
                        <tr>
                            <td><p>Greater Than 30 Minutes</p></td>
                            <td *ngIf="outageData"><p>{{outageData.Data.GREATER_THAN_30_COUNT}}</p></td>
                            <td *ngIf="!outageData"><p>-</p></td>
                            <td *ngIf="outageData"><p>{{outageData.Data.GREATER_THAN_30_OUTAGE}}</p></td>
                            <td *ngIf="!outageData"><p>-</p></td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="feeder-block-inner-content2">
                <div class="header center">FEEDER CONTACT DETAILS</div>
                <div class="content">
                    <table>
                        <tr>
                            <td class="header-topic"><p class="text-uppercase">DESIGNATION</p></td>
                            <!-- <td class="header-topic"><p>Name</p></td> -->
                            <td class="header-topic"><p class="text-uppercase">CONTACT NO.</p></td>
                            <!-- <td class="header-topic"><p>E-Mail</p></td> -->
                        </tr>
                        <tr>
                            <td *ngIf="designation"><p>{{designation}}</p></td>
                            <td *ngIf="!designation"><p>-</p></td>
                            <!-- <td *ngIf="name"><p>{{name}}</p></td>
                            <td *ngIf="!name"><p>-</p></td> -->
                            <td *ngIf="phoneno"><p>{{phoneno}}</p></td>
                            <td *ngIf="!phoneno"><p>-</p></td>
                            <!-- <td *ngIf="emailid"><p>{{emailid}}</p></td>
                            <td *ngIf="!emailid"><p>-</p></td> -->
                        </tr>
                        <tr>
                            <td *ngIf="designation1"><p>{{designation1}}</p></td>
                            <td *ngIf="!designation1"><p>-</p></td>
                            <!-- <td *ngIf="name1"><p>{{name1}}</p></td>
                            <td *ngIf="!name1"><p>-</p></td> -->
                            <td *ngIf="phoneno1"><p>{{phoneno1}}</p></td>
                            <td *ngIf="!phoneno1"><p>-</p></td>
                            <!-- <td *ngIf="emailid1"><p>{{emailid1}}</p></td>
                            <td *ngIf="!emailid1"><p>-</p></td> -->
                        </tr>
                        <tr>
                            <td *ngIf="designation2"><p>{{designation2}}</p></td>
                            <td *ngIf="!designation2"><p>-</p></td>
                            <!-- <td *ngIf="name2"><p>{{name2}}</p></td>
                            <td *ngIf="!name2"><p>-</p></td> -->
                            <td *ngIf="phoneno2"><p>{{phoneno2}}</p></td>
                            <td *ngIf="!phoneno2"><p>-</p></td>
                            <!-- <td *ngIf="emailid2"><p>{{emailid2}}</p></td>
                            <td *ngIf="!emailid2"><p>-</p></td> -->
                        </tr>
                        <tr>
                            <td *ngIf="designation3"><p>{{designation3}}</p></td>
                            <td *ngIf="!designation3"><p>-</p></td>
                            <!-- <td *ngIf="name3"><p>{{name3}}</p></td>
                            <td *ngIf="!name3"><p>-</p></td> -->
                            <td *ngIf="phoneno3"><p>{{phoneno3}}</p></td>
                            <td *ngIf="!phoneno3"><p>-</p></td>
                            <!-- <td *ngIf="emailid3"><p>{{emailid3}}</p></td>
                            <td *ngIf="!emailid3"><p>-</p></td> -->
                        </tr>
                        <tr *ngIf="designation4">
                            <td *ngIf="designation4"><p>{{designation4}}</p></td>
                            <td *ngIf="!designation4"><p>-</p></td>
                            <!-- <td *ngIf="name4"><p>{{name4}}</p></td>
                            <td *ngIf="!name4"><p>-</p></td> -->
                            <td *ngIf="phoneno4"><p>{{phoneno4}}</p></td>
                            <td *ngIf="!phoneno4"><p>-</p></td>
                            <!-- <td *ngIf="emailid4"><p>{{emailid4}}</p></td>
                            <td *ngIf="!emailid4"><p>-</p></td> -->
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <!-- feeder block 3 end here -->
    </div>  