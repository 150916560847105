import { Injectable, EventEmitter } from '@angular/core';

import { HttpClient, HttpInterceptor, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import {
  Router
} from '@angular/router';
import {
  Observable
} from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { CommonService } from './common.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  setdefault: boolean = false;
  setnpBoolenValue(data: boolean) {
    this.setdefault = data;
  }
  get npBoolenValue() {
    return this.setdefault
  }
  getByMeterNo(data: string): Observable<any> {

    return this._http.post(`${environment.LOCALBASE}/search?data=${data}`, data).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert('Something went wrong ;)');
        return Observable.throw('Something went wrong ;)');
      })
    );
  }
  supplySummarypop(s_type: any, key: any): Observable<any> {
    let date1 = new Date();
    // now increment it by 1 day
    date1.setDate(date1.getDate() - 1);
    https://feeder.myxenius.com/feederui//getReportsCountData?date1=2021-06-11&date2=2021-06-11&s_type=S1&key=RURAL::PVVNL
    return this._http.get(`${environment.LOCALBASE2}/getReportsCountData?date1=${this.datepipe.transform(date1, 'yyyy-MM-dd')}&date2=${this.datepipe.transform(date1, 'yyyy-MM-dd')}&s_type=${s_type}&key=${key}`).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert('Something went wrong ;)');
        return Observable.throw('Something went wrong ;)');
      })
    );
  }
  login(data: any): Observable<any> {
    const headers = { 'content-type': 'application/json'}  
    const body=JSON.stringify(data);
   // https://feeder.myxenius.com/thirdparty/feeder_login?login_id=rahul.singh&password=1234
    // return this._http.get(`${environment.LOCALBASE}/feeder_login?login_id=${data.login_id}&password=${data.password}`).pipe(
    return this._http.post(`${environment.LOCALBASE}/feeder_login`,body,{'headers':headers}).pipe(

     // return this._http.get(`${environment.LOCALBASE3}/feeder_login?login_id=${data.login_id}&password=${data.password}`).pipe(  
    // eg. "map" without a dot before
      map(data => {


        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        return Observable.throw('Something went wrong ;)');
      })
    );
  }
  getCommunicationReport(): Observable<any> {

    return this._http.get(`${environment.LOCALBASE2}/communicatingreport`).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert('Something went wrong ;)');
        return Observable.throw('Something went wrong ;)');
      })
    );
  }
  getCommunicationReportCircleWise(data: any): Observable<any> {
    return this._http.get(`${environment.LOCALBASE2}/communicatingreportCircle?discom_name=${data}`).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert('Something went wrong ;)');
        return Observable.throw('Something went wrong ;)');
      })
    );
  }
  getCommunicationReportZoneWise(data: any): Observable<any> {
    return this._http.get(`${environment.LOCALBASE2}/communicatingreportZone?discom_name=${data}`).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert('Something went wrong ;)');
        return Observable.throw('Something went wrong ;)');
      })
    );
  }
  getSupplyCommunicationReport(): Observable<any> {
    let date1 = new Date();
    // now increment it by 1 day
    date1.setDate(date1.getDate() - 1);

    return this._http.get(`${environment.LOCALBASE2}/getReportGraphCount?date1=${this.datepipe.transform(date1, 'yyyy-MM-dd')}&date2=${this.datepipe.transform(date1, 'yyyy-MM-dd')}`).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert('Something went wrong ;)');
        return Observable.throw('Something went wrong ;)');
      })
    );
  }
  // getSubstationCount(projectType:any,discomType:any,districtType:any ,type:any): Observable<any> {

  //   let data= { 

  //   }



  //     return this._http.get( `${environment.LOCALBASE}/substation_count?project_id=${projectType}&discom_id=${discomType}&district_id=${districtType}&type=${type}`,data).pipe(
  //       // eg. "map" without a dot before
  //       map(data => {
  //         return data;
  //       }),
  //       // "catchError" instead "catch"
  //       catchError(error => {
  //         alert("Something went wrong ;)");

  //          return Observable.throw(error);
  //       })
  //     );
  //   }
  getMapData(projectType: any, discomType: any, districtType: any, type: any, zone: any, circle: any, division: any, Subdivision: any, Station: any): Observable<any> {

    let data = {

    }
    //    return this._http.get( `${environment.LOCALBASE2}//getAllLatLong?discom_id=${discomType}&project_type=${projectType}&district_id=${districtType}&type=${type}`,data).pipe(


    //    return this._http.get( `${environment.LOCALBASE}/get_MAP_data_cd?project_id=${projectType}&discom_id=${discomType}&district_id=${districtType}&type=${type}`,data).pipe(
    //,this.zone,this.circle,this.division,this.subdivision,this.substation

    return this._http.get(`${environment.LOCALBASE2}//getAllLatLong?discom_id=${discomType}&project_type=${projectType}&district_id=${districtType}&type=${type}&zone=${zone}&circle=${circle}&division=${division}&Subdivision=${Subdivision}&Station=${Station}`, data).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");

        return Observable.throw(error);
      })
    );
  }
  getMapDataDetail(sensorId: any, vendor: any): Observable<any> {

    let data = {

    }
    //    return this._http.get( `${environment.LOCALBASE2}//getAllLatLong?discom_id=${discomType}&project_type=${projectType}&district_id=${districtType}&type=${type}`,data).pipe(


    //    return this._http.get( `${environment.LOCALBASE}/get_MAP_data_cd?project_id=${projectType}&discom_id=${discomType}&district_id=${districtType}&type=${type}`,data).pipe(

    return this._http.get(`${environment.LOCALBASE2}//getSensorDetail?sensorId=${sensorId}&vendor=${vendor}`).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");

        return Observable.throw(error);
      })
    );
  }
  getSubstationCount(projectType: any, discomType: any, districtType: any, type: any, zone: any, circle: any, division: any, Subdivision: any): Observable<any> {

    let data = {

    }
    //    return this._http.get( `${environment.LOCALBASE2}//getAllLatLong?discom_id=${discomType}&project_type=${projectType}&district_id=${districtType}&type=${type}`,data).pipe(


    //    return this._http.get( `${environment.LOCALBASE}/get_MAP_data_cd?project_id=${projectType}&discom_id=${discomType}&district_id=${districtType}&type=${type}`,data).pipe(
    //,this.zone,this.circle,this.division,this.subdivision,this.substation

    return this._http.get(`${environment.LOCALBASE2}/getSubstationCount?discom_id=${discomType}&project_type=${projectType}&district_id=${districtType}&type=${type}&zone=${zone}&circle=${circle}&division=${division}&Subdivision=${Subdivision}`, data).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");

        return Observable.throw(error);
      })
    );
  }

  getZoneList(projectType: any, discomType: any, districtType: any, type: any): Observable<any> {

    let data = {

    }

    return this._http.get(`${environment.LOCALBASE2}//getZoneList?discom_id=${discomType}&project_type=${projectType}&district_id=${districtType}&type=${type}`, data).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");

        return Observable.throw(error);
      })
    );
  }
  getCircleList(zone_name: any): Observable<any> {

    let data = {

    }

    return this._http.get(`${environment.LOCALBASE2}//getCircle?zone_name=${zone_name}`, data).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");

        return Observable.throw(error);
      })
    );
  }
  getdivision(zone_name: any): Observable<any> {

    let data = {

    }

    return this._http.get(`${environment.LOCALBASE2}//getdivision?circle_name=${zone_name}`, data).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");

        return Observable.throw(error);
      })
    );
  }
  getsubdivision(zone_name: any): Observable<any> {

    let data = {

    }

    return this._http.get(`${environment.LOCALBASE2}//getsubdivision?division_name=${zone_name}`).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");

        return Observable.throw(error);
      })
    );
  }
  getsubstation(subdivision_name:any,division_name: any,circle_name: any,zone_name: any): Observable<any> {

    let data = {

    }

    return this._http.get(`${environment.LOCALBASE2}//getsubstationV1?subdivision_name=${subdivision_name}&division_name=${division_name}&circle_name=${circle_name}&zone_name=${zone_name}`).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");

        return Observable.throw(error);
      })
    );
  }

  getDistrictList(discomType: any, projectType: any, type: any): Observable<any> {


    return this._http.get(`${environment.LOCALBASE}/get_District?project_id=${projectType}&discom_id=${discomType}&type=${type}`).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");

        return Observable.throw(error);
      })
    );
  }
  getFeederCount(discomType: any, projectType: any, districtType: any, type: any): Observable<any> {

    return this._http.get(`${environment.LOCALBASE}/feeder_count?discom_id=${discomType}&project_id=${projectType}&district_id=${districtType}&type=${type}`).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");

        return Observable.throw(error);
      })
    );

  }
  /**
   * get feeder count
   */
   getFeederCountV1(discomType: any, projectType: any, districtType: any, type: any,zone:any,circle:any,division:any,subdivision:any,substation:any): Observable<any> {

    return this._http.get(`${environment.LOCALBASE}/feederCountHeirarchywise?discom_id=${discomType}&project_id=${projectType}&district_id=${districtType}&type=${type}&zone_name=${zone}&circle_name=${circle}&division_name=${division}&subdivision_name=${subdivision}&site_name=${substation}`).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");

        return Observable.throw(error);
      })
    );

  }
  saveOutageRoaster(data: any): Observable<any> {


    return this._http.get(`${environment.LOCALBASE}/add_Roaster_cd?project_id=${data.project_id}&forMonth=${data.forMonth}&supply_hour=${data.supply_hour}`, data).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");

        return Observable.throw(error);
      })
    );
  }
  getMasterData(): Observable<any> {


    return this._http.get(`${environment.LOCALBASE2}/getMasterDataS`).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");

        return Observable.throw(error);
      })
    );
  }
  getSupplyGraph(discom_id: any, project_type: any, supplyhr: any, date1: any, date2: any): Observable<any> {

    //@RequestParam String date1,@RequestParam String date2,@RequestParam String discom_id,@RequestParam String supply_hour
    return this._http.get(`${environment.LOCALBASE2}/getSupplyReportGraph?date1=${date1}&date2=${date2}&supply_hour=${supplyhr}&project_type=${project_type}&discom_id=${discom_id}`).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");

        return Observable.throw(error);
      })
    );
  }
  getMasterDatafilter(state: any, discom_id: any, project_type: any, district: any): Observable<any> {
    return this._http.get(`${environment.LOCALBASE2}/getmasterByfilter?state=${state}&discom_id=${discom_id}&project_type=${project_type}&district_id=${district}`).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");

        return Observable.throw(error);
      })
    );
  }
  /**
   * get master by filter for hierarchcy wise
   */
   getMasterDatafilterV1(state: any, discom_id: any, project_type: any, district: any,zone:any,circle:any,division:any,subdivision:any,substation:any): Observable<any> {
    return this._http.get(`${environment.LOCALBASE2}/getmasterByfilterV1?state=${state}&discom_id=${discom_id}&project_type=${project_type}&district=${district}&zone=${zone}&circle=${circle}&division=${division}&subdivision=${subdivision}&substation=${substation}`).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");

        return Observable.throw(error);
      })
    );
  }
  getMasterSupplyData(start: any, end: any): Observable<any> {
    return this._http.get(`${environment.LOCALBASE2}/getSupplyReport?date1=${start}&date2=${end}`).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");

        return Observable.throw(error);
      })
    );
  }
  getMasterSupplyDataV1(start: any, end: any,discom_id:any,zone_id:any,circle_id:any,division_id:any,subdivision_id:any,station_id:any): Observable<any> {
    return this._http.get(`${environment.LOCALBASE2}/getSupplyReportH?date1=${start}&date2=${end}&discom_id=${discom_id}&project_type=ALL&district_id=ALL&type=OUTGOING&zone=${zone_id}&circle=${circle_id}&division=${division_id}&Subdivision=${subdivision_id}&Station=${station_id}`).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");

        return Observable.throw(error);
      })
    );
  }
  getBestWorstFeeder(start: any, end: any, project: any, discom: any, type: any, count: any): Observable<any> {


    return this._http.get(`${environment.LOCALBASE2}/getBestWorstFeeder?date1=${start}&date2=${end}&project=${project}&discom_name=${discom}&type=ALL&count=${count}`).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");

        return Observable.throw(error);
      })
    );
  }
  getOutageRoaster(data: any): Observable<any> {


    return this._http.get(`${environment.LOCALBASE}/get_Roaster_list_cd?project_id=${data}`, data).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");

        return Observable.throw(error);
      })
    );
  }
  getUserActivityLog(discom_id: any, date1: any, date2: any): Observable<any> {


    return this._http.get(`${environment.LOCALBASE2}/getDeviceHistory?discom_id=${discom_id}&date1=${date1}&date2=${date2}`).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");

        return Observable.throw(error);
      })
    );
  }
  // Report MSI
  getSupplyReportMsi(data1: any, data2: any): Observable<any> {

    return this._http.get(`${environment.LOCALBASE2}/getFeederSupplyStatus?date1=${data1}&date2=${data2}`).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert('Something went wrong ;)');
        return Observable.throw('Something went wrong ;)');
      })
    );
  }
  getdayWiseFeederTriping(data1: any): Observable<any> {

    return this._http.get(`${environment.LOCALBASE2}/getDailyAnalysisOfSupplyOutageData?date=${data1}`).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert('Something went wrong ;)');
        return Observable.throw('Something went wrong ;)');
      })
    );
  }
  getIndustrialFeederStatusProject(data1: any, data2: any, project: any): Observable<any> {

    return this._http.get(`${environment.LOCALBASE2}/getIndustrialFeederStatusProject?date1=${data1}&date2=${data2}&project=${project}`).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert('Something went wrong ;)');
        return Observable.throw('Something went wrong ;)');
      })
    );
  }
  getIndustrialFeederStatusList(data1: any, data2: any, project: any, discom: any, key: any): Observable<any> {
    return this._http.get(`${environment.LOCALBASE2}/getIndustrialFeederStatusProjectClick?date1=${data1}&date2=${data2}&project=${project}&discom_name=${discom}&click_param=${key}`).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert('Something went wrong ;)');
        return Observable.throw('Something went wrong ;)');
      })
    );
  }
  /**
   * api for urban report
   */
  getUrbanReportData(discom_name: any, start: any, end: any): Observable<any> {
    return this._http.get(`${environment.LOCALBASE2}/urbanReport?discom_name=${discom_name}&date1=${start}&date2=${end}`).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        alert("Something went wrong ;)");
        return Observable.throw(error);
      })
    );
  }
  /**
   * single feeder details
   */
  getSingleFeederDetails(fid: any): Observable<any> {
    return this._http.get(`${environment.LOCALBASE2}/getFeederDetails?fid=${fid}`).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        alert("Something went wrong ;)");
        return Observable.throw(error);
      })
    );
  }
  /**
   * get outage duration
   */
  getOutageDuration(fid: any, date1: any): Observable<any> {
    return this._http.get(`${environment.LOCALBASE2}/getFeederCountByDate?fid=${fid}&date=${date1}&vendor=o`).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        alert("Something went wrong ;)");
        return Observable.throw(error);
      })
    );
  }
  /**
   * get current month data on high chart
   */
  getSingleFeederGraphData(fid: any, date1: any, date2: any): Observable<any> {
    return this._http.get(`${environment.LOCALBASE2}/getFeederGraph?fid=${fid}&date1=${date1}&date2=${date2}&vendor=o`).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        alert("Something went wrong ;)");
        return Observable.throw(error);
      })
    )
  }
  /**
   * get interruption count
   */
  getInterruptionCount(fid: any, date1: any, date2: any) {
    return this._http.get(`${environment.LOCALBASE2}/getFeederMonthDetails?fid=${fid}&date1=${date1}&date2=${date2}&vendor=o`).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        alert("Something went wrong ;)");
        return Observable.throw(error);
      })
    )
  }
  /**
   * Modem Based Supply Report
   */
  //https://feeder.myxenius.com/feederui//getSupplyReportModem?date1=2021-10-28&date2=2021-10-28
  getModemBasedSupplyReport(date1:any,date2:any): Observable<any>{
    return this._http.get(`${environment.LOCALBASE2}/getSupplyReportModem?date1=${date1}&date2=${date2}`).pipe(map(data=>{
      return data;
    }),
    catchError(error => {
      alert("Something went wrong ;)");
      return Observable.throw(error);
    })
    )
  }
  /**
   * Api for geeting service provider count
   */
  getServiceProviderCount(discom_id:any,zone_name:any,circle_name:any,division_name:any,subdivision_name:any,site_name:any,project_id:any,service_provider:any):Observable<any>{
    return this._http.get(`${environment.LOCALBASE}/feeder_count_serviceProvider?discom_id=${discom_id}&zone_name=${zone_name}&circle_name=${circle_name}&division_name=${division_name}&subdivision_name=${subdivision_name}&site_name=${site_name}&project_id=${project_id}&service_provider=${service_provider}`).pipe(map(data=>{
      return data;
    }),
    catchError(error => {
      alert("Something went wrong ;)");
      return Observable.throw(error);
    })
    )
  }
  /**
   * api for feeder realibility report
   */
  getFeederRealibiltyReport(discom_id:any,project_id:any,date1:any,date2:any): Observable<any>{
    return this._http.get(`${environment.LOCALBASE2}/getReliabilityIndex?date1=${date1}&date2=${date2}&discom_id=${discom_id}&project_type=${project_id}`).pipe(map(data=>{
      return data;
    }),
    catchError(error => {
      alert("Something went wrong ;)");
      return Observable.throw(error);
    })
    )
  }
  /**
   * api for feeder realibilty v1 hiereachy wise
   */
   getFeederRealibiltyReportV1(zone:any,discom_id:any,project_id:any,date1:any,date2:any,key_value:any): Observable<any>{
    return this._http.get(`${environment.LOCALBASE2}/getReliabilityIndexH?zone_id=${zone}&date1=${date1}&date2=${date2}&discom_id=${discom_id}&project_type=${project_id}&key=${key_value}`).pipe(map(data=>{
      return data;
    }),
    catchError(error => {
      alert("Something went wrong ;)");
      return Observable.throw(error);
    })
    )
  }
  /**
   * meter report
   */
   getMeterOutageReport(serial_no:any,date1:any,date2:any): Observable<any>{
    return this._http.get(`${environment.LOCALBASE2}getMeterOutageSerial?date1=${date1}&date2=${date2}&serial_no=${serial_no}`).pipe(map(data=>{
      return data;
    }),
    catchError(error => {
      alert("Something went wrong ;)");
      return Observable.throw(error);
    })
    )
  }
  /**
   * outage report substation wise
   */
  getOutageReportSubstationwise(substation_id:any,date1:any,date2:any):Observable<any>{
    return this._http.get(environment.LOCALBASE2+`/getSubstationOutageReportFeederWise?date1=${date1}&date2=${date2}&sub_station=${substation_id}`).pipe(map(res=>{
      return res
    }),
    catchError(error => {
      alert("Something went wrong ;)");
      return Observable.throw(error);
    })
    )
  }
  /**
   * outage report discom wise
   */
  getOutageReportDiscomWise(discom_id:any,date1:any,date2:any):Observable<any>{
    //https://feeder.myxenius.com/feederui//getSubstationOutageReport?date1=2021-01-04&date2=2022-01-04&discom_id=5a71b954cf5e58.55295458
    return this._http.get(environment.LOCALBASE2+`/getSubstationOutageReport?date1=${date1}&date2=${date2}&discom_id=${discom_id}`).pipe(map(res=>{
      return res
    }),
    catchError(error => {
      alert("Something went wrong ;)");
      return Observable.throw(error);
    })
    )
  }
  /**
   * vendor log
   */
   getVendorLog(fromdate:any,serviceprovider:any,meterserialno:any):Observable<any>{
    //https://feeder.myxenius.com/feederui//getSubstationOutageReport?date1=2021-01-04&date2=2022-01-04&discom_id=5a71b954cf5e58.55295458
    return this._http.get(environment.LOCALBASE+`/vendor_logs?FromDate=${fromdate}&meter_no=${meterserialno}&service_provider=${serviceprovider}`).pipe(map(res=>{
      return res
    }),
    catchError(error => {
      alert("Something went wrong ;)");
      return Observable.throw(error);
    })
    )
  }
  /**
   * communication status discom wise report
   */
   getCommunicationdiscomwise():Observable<any>{
    //https://feeder.myxenius.com/feederui//getSubstationOutageReport?date1=2021-01-04&date2=2022-01-04&discom_id=5a71b954cf5e58.55295458
    return this._http.get(environment.LOCALBASE+`/communication_count`).pipe(map(res=>{
      return res
    }),
    catchError(error => {
      alert("Something went wrong ;)");
      return Observable.throw(error);
    })
    )
  }
  /**
   * get down feeder details
   */
  getDownFeederList(discom_id:any,zone_name:any,circle_name:any,division_name:any,subdivision_name:any,site_name:any,project_id:any,service_provider:any):Observable<any>{
    return this._http.get(`${environment.LOCALBASE2}/downfeederreport?discom_id=${discom_id}&zone_name=${zone_name}&circle_name=${circle_name}&division_name=${division_name}&subdivision_name=${subdivision_name}&site_name=${site_name}&project_id=${project_id}&service_provider=${service_provider}`).pipe(map(data=>{
      return data;
    }),
    catchError(error => {
      alert("Something went wrong ;)");
      return Observable.throw(error);
    })
    )
  }
  /**
   * daily consumption report
   */
   getDailyConsumptionReport(start: any,discom_id:any,zone_id:any,circle_id:any,division_id:any,subdivision_id:any,station_id:any): Observable<any> {
    return this._http.get(`${environment.LOCALBASE2}/getDailyConsumptionReportH?date=${start}&discom_id=${discom_id}&project_type=ALL&district_id=ALL&type=OUTGOING&zone=${zone_id}&circle=${circle_id}&division=${division_id}&Subdivision=${subdivision_id}&Station=${station_id}`).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");
        return Observable.throw(error);
      })
    );
  }
  /**
   * monthly consumption report
   */
   getMonthlyConsumptionReport(start: any,discom_id:any,zone_id:any,circle_id:any,division_id:any,subdivision_id:any,station_id:any): Observable<any> {
    return this._http.get(`${environment.LOCALBASE2}/getMontlyConsumptionReportH?date=${start}&discom_id=${discom_id}&project_type=ALL&district_id=ALL&type=OUTGOING&zone=${zone_id}&circle=${circle_id}&division=${division_id}&Subdivision=${subdivision_id}&Station=${station_id}`).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");
        return Observable.throw(error);
      })
    );
  }
  /**
   * sla report
   */
  //https://feeder.myxenius.com/feederui/getfeederSLA?date=2022-09-15&key=MONTHLY
   getSlaReport(start:any,key:any): Observable<any> {
    return this._http.get(`${environment.LOCALBASE2}/getfeederSLA?date=${start}&key=${key}`).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");
        return Observable.throw(error);
      })
    );
  }

  getSlaReportV1(start:any,key:any): Observable<any> {
    return this._http.get(`${environment.LOCALBASE2}/getfeederSLAV1?date=${start}&key=${key}`).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");
        return Observable.throw(error);
      })
    );
  }

  /** ******************************* get roaster details ************************ */
  getRoasterDetailList(discom_name:any,zone_name:any,circle_name:any,division_name:any,sub_division_name:any,substation_name:any,feeder_name:any,start:any,end:any):Observable<any>{
    return this._http.get(`${environment.LOCALBASE2}/getOutageRoasterV1?discomName=${discom_name}&zone=${zone_name}&district=ALL&circle=${circle_name}&division=${division_name}&subdivision=${sub_division_name}&Station=${substation_name}&feeder=ALL&project=ALL&fromDate=${start}&toDate=${end}`).pipe(map(data=>{
      return data;
    }),
    catchError(error => {
      alert("Something went wrong ;)");
      return Observable.throw(error);
    })
    );
  }
  /***************************** get feeder list *********************** */
  getFeederList(site_name:any):Observable<any>{
    return this._http.get(`${environment.LOCALBASE2}/getFeederList?site_name=${site_name}`).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");
        return Observable.throw(error);
      })
    );
  }
  addRoasterDetails(discom_name:any,zone_name:any,circle_name:any,division_name:any,sub_division_name:any,substation_name:any,feeder_name:any,start:any,end:any,outage_type:any,reason:any,remark:any,project:any,login_id:any):Observable<any>{
   let data = {"discomName":discom_name,"zone":zone_name,"district":"ALL","circle":circle_name,"division":division_name,"subDivision":sub_division_name,"station":substation_name,"feeder":feeder_name,"fromDate":start,"toDate":end,"reason":reason,"remark":remark,"outageType":outage_type,"profile_id":login_id,"project_id":project}
    console.log(data)
   return this._http.post(`${environment.LOCALBASE}/outageRoster`,data).pipe(map(data=>{
      return data;
    }),
    catchError(error => {
      alert("Something went wrong ;)");
      return Observable.throw(error);
    })
    );
  }


  getloadcurvedata(fromdate:any,todate:any,discom_id:any,zone:any,circle:any,division:any,Subdivision:any,Stations:any): Observable<any> {

    //@RequestParam String date1,@RequestParam String date2,@RequestParam String discom_id,@RequestParam String supply_hour
    return this._http.get(`${environment.LOCALBASE2}/getLdCrvAggrgtDt?date1=${fromdate}&date2=${todate}&discom_id=${discom_id}&zone=${zone}&circle=${circle}&division=${division}&Subdivision=${Subdivision}&Stations=${Stations}&graph_type=LOAD`).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");
        return Observable.throw(error);
      })
    );
  }
  getCircleWiseDailyCommunicationReport(fromdate:any,todate:any,project_id:any): Observable<any> {

    //@RequestParam String date1,@RequestParam String date2,@RequestParam String discom_id,@RequestParam String supply_hour
    return this._http.get(`${environment.LOCALBASE2}/getCrclWsFeederSupplyStatus?date1=${fromdate}&date2=${todate}&project_id=${project_id}`).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");
        return Observable.throw(error);
      })
    );
  }
  
  constructor(private _http: HttpClient, private datepipe: DatePipe,private d:CommonService) { }
}
