<button mat-icon-button color="accent" aria-label="Example icon button with a menu icon" style="    padding-left: 0px;
position: absolute;
top: 58%;
left: -1%;;" (click)="_sendDataToParent('toggle')">
  <mat-icon style="color: #293368;
font-size: 33px;color: #293368;
font-size: 37px;
margin-top: 13px;">code</mat-icon>
</button>

<nav class="navbar navbar-default" style="margin: 0;
float: right;
width: 100%;
background: black;
border: 0px;">
  <div class="container-fluid">
    <ul class="nav navbar-nav" style="float: right;">
      <ng-container *ngFor="let list of navigation">
        <ng-container *ngIf="list.menuTrigger == 'dashmenu'">
          <li [ngClass]="{'active2': returnStatus(list) }" [matMenuTriggerFor]="dashmenu">


            <a> {{list.name}} </a>

          </li>

          <mat-menu #dashmenu="matMenu">
            <button mat-menu-item [routerLink]="[list2.state]" [ngClass]="{'active2': list2.state === currentUrl}" *ngFor="let list2 of list.child">{{list2.name}}</button>
          </mat-menu>
        </ng-container>
        <ng-container *ngIf="list.menuTrigger == ''">
          <li [ngClass]="{'active2': list.state === currentUrl}"  [routerLink]="[list.state]">


            <a> {{list.name}} </a>

          </li>

        </ng-container>
        <ng-container *ngIf="list.menuTrigger == 'menu'">
          <li   [matMenuTriggerFor]="menu" [ngClass]="{'active2': returnStatus(list) }">


            <a> {{list.name}} </a>

          </li>

          <mat-menu #menu="matMenu">
            <button mat-menu-item [routerLink]="[list2.state]" [ngClass]="{'active2': list2.state === currentUrl}" *ngFor="let list2 of list.child">{{list2.name}}</button>
          </mat-menu>
        </ng-container>

        <ng-container *ngIf="list.menuTrigger == 'matMenu'">
          <li   [matMenuTriggerFor]="matMenu" [ngClass]="{'active2': returnStatus(list) }">


            <a> {{list.name}} </a>

          </li>

          <mat-menu #matMenu="matMenu">
            <button mat-menu-item [routerLink]="[list2.state]" [ngClass]="{'active2': list2.state === currentUrl}" *ngFor="let list2 of list.child">{{list2.name}}</button>
          </mat-menu>

        </ng-container>

      </ng-container>
      <li (click)="logout()">
        <a > <mat-icon>exit_to_app</mat-icon></a>      
   </li>
    </ul>
    <!-- <ul class="nav navbar-nav" style="float: right;">
        <li class="active2"  [matMenuTriggerFor]="dashmenu"><a  >Dashboard</a></li>
        
        <li [routerLink]="['/outage']" ><a  >Roaster Outage</a></li>
        
        <li   [matMenuTriggerFor]="menu"><a  > Report</a></li>
        <li [matMenuTriggerFor]="report"  ><a> MIS Report </a></li>
        <mat-menu #report="matMenu">
            <button mat-menu-item [routerLink]="['/reportmsi/supply/Status']" >Supply Report</button>
            <button mat-menu-item [routerLink]="['/reportmsi/feeder/Status']"   >feeder Status</button>



        </mat-menu>
        <li   [routerLink]="['/']">
              <a > <mat-icon>exit_to_app</mat-icon></a>      
         </li>
       </ul> -->
    <!-- <mat-menu #menu="matMenu">
        <button mat-menu-item [routerLink]="['/report/master']">Master Report</button>
        <button mat-menu-item [routerLink]="['/report/communication']">Communication Report</button>
        <button mat-menu-item [routerLink]="['/report/supply']">Supply Report</button>
        <button mat-menu-item [routerLink]="['/report/current']">Feeder Data</button>
        <button mat-menu-item [routerLink]="['/report/Activity']">User Activity</button>
        <button mat-menu-item [routerLink]="['/report/commuSupply']">Supply Summary</button>

        
      </mat-menu> -->
    <!-- <mat-menu #dashmenu="matMenu">
        <button mat-menu-item [routerLink]="['/dashboard']" class="active2" >Main Dashboard</button>
        <button mat-menu-item [routerLink]="['/graphical/dashboard']">Graphical Dashboard</button>

        
      </mat-menu> -->

  </div>
</nav>
