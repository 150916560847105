
<h2 mat-dialog-title>Feeder Detail <span *ngIf="data.site_name">(Substation Name:-{{data.site_name}})</span> <span  *ngIf="!data.site_name" > (Substation Name:-{{getSplitname(data.name,1)}} )</span></h2>
<mat-dialog-content>


        <table mat-table [dataSource]="dataSource" style ="width:100%">
      
          <!-- Position Column -->
       
      
          <!-- Name Column -->
          <ng-container matColumnDef="meter_sr_no">
            <th mat-header-cell *matHeaderCellDef> Feeder Name </th>
            <td mat-cell *matCellDef="let element"   style="color: #293368;cursor: pointer;" (click) ="openurl()"> <span *ngIf ="element.feeder_name"> {{element.feeder_name}}</span>  <span *ngIf ="!element.feeder_name"> {{getSplitname(data.name,0)}}</span></td>
          </ng-container>
      
          <!-- Weight Column -->
          <ng-container matColumnDef="discom_name">
            <th mat-header-cell *matHeaderCellDef> Meter Sr.No. </th>
            <td mat-cell *matCellDef="let element" > {{element.serial_no}} </td>
          </ng-container>
          <ng-container matColumnDef="KVAh">
            <th mat-header-cell *matHeaderCellDef> KVAh </th>
            <td mat-cell *matCellDef="let element" > {{element.KVAh}} </td>
          </ng-container>
          <ng-container matColumnDef="KWh">
            <th mat-header-cell *matHeaderCellDef> KWh </th>
            <td mat-cell *matCellDef="let element" > {{element.KWh}} </td>
          </ng-container>
          <!-- Symbol Column -->
          <ng-container matColumnDef="zone_name">
            <th mat-header-cell *matHeaderCellDef> Current (R|Y|B) </th>
            <td mat-cell *matCellDef="let element" > {{absValue(element.R_Current) + '|' + absValue(element.Y_Current) + '|' + absValue(element.B_Current)}} </td>
          </ng-container>
          <ng-container matColumnDef="circle_name">
            <th mat-header-cell *matHeaderCellDef> Instant Load (VA) </th>
            <td mat-cell *matCellDef="let element" > {{element.instant_cum_KVA}} </td>
          </ng-container>
          <ng-container matColumnDef="division_name">
            <th mat-header-cell *matHeaderCellDef> MD (VA) </th>
            <td mat-cell *matCellDef="let element" > {{element.max_demand_KVA}} </td>
          </ng-container>
          
          <ng-container matColumnDef="substation_name">
            <th mat-header-cell *matHeaderCellDef> Last Communication Time </th>
            <td mat-cell *matCellDef="let element" > <span *ngIf ="element.last_packet_time">{{DateFormat(element.last_packet_time)}}</span> <span *ngIf ="!element.last_packet_time">{{DateFormat(element.last_reading_updated)}}</span>  </td>
          </ng-container>
          <ng-container matColumnDef="last_event_time">
            <th mat-header-cell *matHeaderCellDef> Last Event Time </th>
            <td mat-cell *matCellDef="let element" ><span *ngIf ="element.last_event_time">{{DateFormat(element.last_event_time)}}</span> <span *ngIf ="!element.last_packet_time">{{DateFormat(element.last_reading_updated)}}</span>  </td>
          </ng-container>
          <ng-container matColumnDef="feeder_name">
            <th mat-header-cell *matHeaderCellDef> INCOMER </th>
            <td mat-cell *matCellDef="let element" [innerHtml]="getPowerStatus(element.digital_input2)">   </td>
          </ng-container>   <ng-container matColumnDef="project">
            <th mat-header-cell *matHeaderCellDef> CB </th>
            <td mat-cell *matCellDef="let element" [innerHtml]="getPowerStatus(element.digital_input3)">   </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns" ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      
 </mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close style="background-color:red !important;color:white">Cancel</button>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->

  </mat-dialog-actions>
