import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedMaterialModule} from '../shared.material'
import { HeaderComponent } from '../header/header.component';
 

@NgModule({
  declarations: [HeaderComponent],
  imports: [SharedMaterialModule,
    CommonModule 
  ],exports:[HeaderComponent]
})
export class SharedComponentModule { }
