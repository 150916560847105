<mat-drawer-container class="example-container backgorund" autosize>
    <mat-drawer #drawer class="example-sidenav" mode="side" opened="true">
        <div style="width: 100%;text-align: center">
            <img src="../../assets/elements/LOGO.png" style="width: 210px;" [routerLink]="['/dashboard']"
                style="cursor: pointer;">
        </div>
        <div fxLayout="row wrap">
            <div fxFlex="50" style="    font-size: 18px;
                margin-left: 14px;
            color: white;">
                Sub Station Count : <span style=" font-size: 18px;
                font-weight: 600;
                color: white ;">
                    {{substationCount}}
                </span>
                
            </div>
        </div>
        <a style="color: white; position: absolute; z-index:1000; left: 380px; top:190px; font-size: 16px;cursor: pointer;" (click)="show('DOWN')" title="Down Feeder Chart">&#8224;</a>
        <a style="color: white; position: absolute; z-index:1000; left: 410px; top:190px; font-size: 16px;cursor: pointer;" (click)="show('SERVICE')" title="Show Service Provider Chart">&#9734;</a>
        <!-- ============== discom wise donut chart =============== -->
        <div fxLayout="row wrap" style="width: 450px;">
            <!-- ============= heirarchcy wise dashboard =========== -->
            <div fxFlex="100">
                <mat-card class='sidecard'>
                    <div class="wrapper">
                        <h4 style="color: white;" *ngIf="!filterType">HIERARCHY : UPPCL</h4>
                        <h4 style="color: white;" *ngIf="filterType">HIERARCHY : {{filterType}}&nbsp;->&nbsp;{{filterName}}</h4>
                        <br>
                        <h3>HIERARCHY</h3>
                        <div class="content">
                            <div [chart]="chart3"></div>
                        </div>
                    </div>
                </mat-card>
            </div>
            <div fxFlex="100">
                <mat-card class='sidecard'>
                    <div style="width: 100%;">
                        <ul class="tab1">
                            <ng-container *ngFor="let list of discomList">
                                <li (click)="discomSelect(list.value,list.name)" [ngClass]="{
                                    'tab1active' : discomType == list.value,
                                '': discomType != list.value
                                }"><a>{{list.name}}</a></li>
                            </ng-container>
                        </ul>
                        <div class="wrapper">
                            <h3>DISCOMs</h3>
                            <div class="content">
                                <div [chart]="chart"></div>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </div>
            <!-- ==================== scheme wise selection ================= -->
            <div fxFlex="100">
                <mat-card class='sidecard2'>
                    <div style="width: 100%; font-size: 12px;">
                        <ul class="tab2">
                            <ng-container *ngFor="let list of projectList">
                                <li (click)="projectSelect(list.value)" [ngClass]="{
                                    'tab1active' : projectType == list.value,
                                   '': discomType != list.value
                                 }"><a style="font-size:12px;">{{list.name}}</a></li>
                            </ng-container>
                        </ul>
                        <div class="wrapper">
                            <h3>SCHEME</h3>
                            <div class="content">
                                <div [chart]="chart1"></div>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </div>
            <!-- =================== District Wise Selection ================ -->
            <div fxFlex="100">
                <mat-card class='sidecard'>
                    <div class="wrapper">
                        <mat-form-field class="my-form-field" style="width: 100%;" [color]="'white'">
                            <mat-label style="color: white;font-size: 16px;">Select District</mat-label>
                            <mat-select class="customSelect" [(ngModel)]="districtSelected" name="select"
                                (selectionChange)="getdiscomSchemaDistrictFeederCount('direct');"
                                [ngModelOptions]="{standalone: true}">
                                <mat-option>
                                    <ngx-mat-select-search [(ngModel)]="searchCtrl" [placeholderLabel]="'Search...'"
                                        [noEntriesFoundLabel]="'Not found'" name="search"
                                        [ngModelOptions]="{standalone: true}"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option [value]="'ALL'">
                                    All
                                </mat-option>
                                <mat-option *ngFor="let list of districtList  |stringFilterBy:searchCtrl"
                                    [value]="list">
                                    {{list}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <br>
                        <h3>DISTRICT</h3>
                        <div class="content">
                            <div [chart]="chart2"></div>
                        </div>
                    </div>
                </mat-card>
            </div>
            <!-- ==================== selection wise chart ================== -->
            
            <!-- <mat-slide-toggle style="float: right;" [checked]="useDefault" (change)="toggle($event)">Not In Service</mat-slide-toggle> -->
        </div>
    </mat-drawer>
    <div class="example-sidenav-content backgorund">
        <router-outlet></router-outlet>
    </div>
</mat-drawer-container>