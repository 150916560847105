

<div class=" ">
    
    <app-header></app-header>
    <p style="       margin-bottom: 13px;
    text-align: center;
    color: black;
    background: black;
    padding: 10px;
    color: white;"><span style="        font-size: 28px;
        
        margin-bottom: 5px;
        font-weight: 500;;
        border-radius: 10px;;">No Modem Feeder</span>
    
    </p>
    <hr>
    <div class="content"  >

    <div [chart]="chart2"></div>
</div>
    </div>