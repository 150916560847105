
   
    <div fxFlex="100">
        <mat-slide-toggle style="color:whitesmoke ;">Slide me!</mat-slide-toggle>
        <span (click)='onNoClick()' style="color:white">&times;</span>
        <mat-card class='sidecard'>
            <ul class="tab1">
                <ng-container *ngFor="let list of serviceProvider">
                    <li (click)="serviceProviderSelect(list.value)" [ngClass]="{
                        'tab1active' : serviceproviderType == list.value,
                    '': serviceproviderType != list.value
                    }"><a>{{list.name}}</a></li>
                </ng-container>
            </ul>
            <div class="wrapper">
                <br>
                <h3 style="margin-right: -30px!important;">{{chart_name}}</h3>
                <div class="content">
                    <div [chart]="chart1"></div>
                </div>
            </div>
        </mat-card>
    </div>
