import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AuthService } from 'src/app/auth.service';
import { CommonService } from 'src/app/common.service';
import * as XLSX from 'xlsx';
import { FormGroup, FormControl } from '@angular/forms';

import {
  ElementRef, 
} from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DatePipe } from '@angular/common';

import * as moment from 'moment';

@Component({
  selector: 'app-add-roaster',
  templateUrl: './add-roaster.component.html',
  styleUrls: ['./add-roaster.component.scss']
})
export class AddRoasterComponent implements OnInit {
  @ViewChild('picker3') picker1: any;
  @ViewChild('picker4') picker2: any;
  public date!: moment.Moment;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate!: moment.Moment;
  public maxDate!: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;

  discom_id: any;
  zoneData: any;
  zone_id: any;
  cirlcleListData: any;
  circle_id: any;
  divisionListData: any;
  division_id: any;
  subdivisionListData: any;
  subdivision_id: any;
  substationListData: any;

  /**
   *Declare discomlist
   */
   discomList = [
    { name: "-- SELECT --", value: "" },
    { name: "PuVVNL", value: "5a71b954cf5e58.55295458" },
    { name: "MVVNL", value: "5a71b8e6167566.89648438" },
    { name: "DVVNL", value: "5a71b6d35156d7.70878980" },
    { name: "PVVNL", value: "5a71b98adb88d0.91464350" },
    { name: "KESCo", value: "5a71b8a355c2d4.24012768" }
  ]
  feeder_id: any;
  feederData: any=[];
  dataSource: any;

  constructor( private commonservice: CommonService,private datepipe: DatePipe, private AuthService: AuthService, private service: CommonService, private NgxUiLoaderService: NgxUiLoaderService,public dialog: MatDialog,public dialogRef: MatDialogRef<AddRoasterComponent>,@Inject(MAT_DIALOG_DATA) public data: any) {
    console.log(JSON.stringify(data))
  }
  ngOnInit(): void {
  }
range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
    discom_name : new FormControl(''),
    zone_name : new FormControl(''),
    circle_name : new FormControl(''),
    division_name : new FormControl(''),
    sub_division_name : new FormControl(''),
    substation_name : new FormControl(''),
    feeder_name : new FormControl(''),
    outage_type : new FormControl(),
    reason : new FormControl(),
    remark : new FormControl(),
    project: new FormControl('')
  });

  public stepHours = [1, 2, 3, 4, 5];
  public stepMinutes = [1, 5, 10, 15, 20, 25];
  public stepSeconds = [1, 5, 10, 15, 20, 25];

  /************** DISCOM LIST *************/
  getZone(e:any){
    this.discom_id = e.value
    this.AuthService.getZoneList('ALL',e.value,'ALL','OUTGOING').subscribe(res=>{
      this.zoneData = res.Data
      console.log(this.zoneData)
    })
   }
   selectedzone(e:any){
    this.zone_id = e.value
    this.AuthService.getCircleList(e.value).subscribe(res=>{
      this.cirlcleListData = res.Data
      console.log(this.cirlcleListData)
    })
   }
   selectedcircle(e:any){
    this.circle_id = e.value
    this.AuthService.getdivision(e.value).subscribe(res=>{
      this.divisionListData = res.Data
      console.log(this.divisionListData)
    })
    
   }
   selecteddivision(e:any){
    this.division_id = e.value
    this.AuthService.getsubdivision(e.value).subscribe(res=>{
      this.subdivisionListData = res.Data
      console.log(this.subdivisionListData)
    })
   }
   selectedsubdivision(e:any){
    this.subdivision_id = e.value
    this.AuthService.getsubstation(e.value,this.division_id,this.circle_id,this.zone_id).subscribe(res=>{
      this.substationListData = res.Data
      console.log(this.substationListData)
    })
   }

   selectedstation(e:any){
    this.feeder_id = e.value
    this.AuthService.getFeederList(this.feeder_id).subscribe(res=>{
      this.feederData = res.Data
      console.log(this.feederData)
    })
   }

  
  /** ***************** get roaster details list ***************** */
  addRoasterDetails(){
    if(this.checkSpecialCharacter(this.range.value.remark)){
      alert("Please Enter Valid Remark");
      return;
    }
    if(this.checkSpecialCharacter(this.range.value.reason)){
      alert("Please Enter Valid Remark");
      return;
    }
    /**
     * check if value is ALL send NULL
     */
    if(this.range.value.discom_name == 'ALL'){
      this.range.value.discom_name = ''
    }
    else if(this.range.value.zone_name == 'ALL'){
      this.range.value.zone_name = ''
    }
    else if(this.range.value.circle_name == 'ALL'){
      this.range.value.circle_name = ''
    }
    else if(this.range.value.division_name == 'ALL'){
      this.range.value.division_name = ''
    }
    else if(this.range.value.sub_division_name == 'ALL'){
      this.range.value.sub_division_name = ''
    }
    else if(this.range.value.substation_name == 'ALL'){
      this.range.value.substation_name = ''
    }
    let res = this.discomList.filter((item) => {
      if(item.value == this.range.value.discom_name){ this.range.value.discom_name = item.name } 
    });
    this.AuthService.addRoasterDetails(this.range.value.discom_name,this.range.value.zone_name,this.range.value.circle_name,this.range.value.division_name,this.range.value.sub_division_name,this.range.value.substation_name,this.range.value.feeder_name,this.datepipe.transform(this.range.value.start,'yyyy-MM-dd HH:mm:ss'),this.datepipe.transform(this.range.value.end,'yyyy-MM-dd HH:mm:ss'),this.range.value.outage_type,this.range.value.reason,this.range.value.remark,'ALL',localStorage.getItem("login_id")).subscribe(res => {
      this.dialogRef.close();
      window.location.reload()
    })
  }

  onCancel(): void {
    this.dialogRef.close();
  }
  
  checkSpecialCharacter(string:any){
    var specialChars = "<>@!#$%^&*()+[]{}?:;|'\"\\,./~`=";
    for(let i = 0; i < specialChars.length;i++){
      if(string.indexOf(specialChars[i]) > -1){
          return true
       }
    }
    return false;
  }
}
