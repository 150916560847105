import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from '../auth.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-outagedetailpopup',
  templateUrl: './outagedetailpopup.component.html',
  styleUrls: ['./outagedetailpopup.component.scss']
})
export class OutagedetailpopupComponent implements OnInit {
  outagefeederdetail: any;
  tableheader: string[];
  fileName='FRI EFFECTED FEEDERS DETAIL.xlsx';
  constructor(public dialogRef: MatDialogRef<OutagedetailpopupComponent>,private service:AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any,private loader:NgxUiLoaderService) { 
      this.outagefeederdetail = data.data
      this.tableheader = ['SrNo.','PROJECT','DISCOM','ZONE','DISTRICT','CIRCLE','TOWN','DIVISION','SUB DIVISION','SUB STATION','FEEDER NAME','METER NUMBER','FEEDER CODE','SERVICE PROVIDER','MODEM NO','FEEDER TYPE','FROM DATE','TO DATE','NO OF DAYS','OUTGAE COUNT','OUTAGE DURATION','SUPPLY DURATION','AVG OUTAGE DURATION PER DAY','AVG SUPPLY DURATION PER DAY']
    }

  ngOnInit(): void {
  }
  openurl(data:any){
    let id = data
    let currentURL = window.location.href;
    let tmp = currentURL.split("/")
    let link2 = tmp[0] + "//" + tmp[2] + "/vendorDetails/" + id
    window.open(link2, "_blank")
  }
  downloadexce(): void 
  {
    this.loader.start();
     let element = document.getElementById('excel-table1'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
     XLSX.writeFile(wb, this.fileName);
     this.loader.stop();
  }
  toHHMMSS(sec: any) {
    console.log(sec)
    let sec_num = parseInt(sec, 10); // don't forget the second param
    let hours :any;
    hours =  Math.floor(sec_num / 3600);
    let minutes:any;
      minutes = Math.floor((sec_num - (hours * 3600)) / 60);
      let seconds :any
      seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours < 10) {
       hours = "0" + hours;
       }
    if (minutes < 10) { minutes = "0" + minutes; }
    if (seconds < 10) { seconds = "0" + seconds; }
    return hours + ':' + minutes + ':' + seconds;
  }
}
