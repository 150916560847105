<ng-container>
<mat-card style="margin-left: 31px; margin-top: 10px;">
    <button mat-raised-button color="primary" (click)="downloadexce()">DownloadExcel</button>&nbsp;&nbsp;&nbsp;
    <mat-card-content>
        <table  id="excel-table1">
            <thead>
                <tr>
                    <th *ngFor="let item of tableheader;let i=index">{{item}}</th>
                </tr>
            </thead>
            <tbody *ngFor="let item of outagefeederdetail;let i=index">
                <tr>
                    <td>{{i+1}}</td>
                    <td>{{item.PROJECT}}</td>
                    <td>{{item.DISCOM}}</td>
                    <td>{{item.ZONE}}</td>
                    <td>{{item.DISTRICT}}</td>
                    <td>{{item.CIRCLE}}</td>
                    <td>{{item.TOWN}}</td>
                    <td>{{item.DIVISION}}</td>
                    <td>{{item.SUB_DIVISION}}</td>
                    <td>{{item.SUB_STATION}}</td>
                    <td>{{item.FEEDER_NAME}}</td>
                    <td>{{item.METER_NUMBER}}</td>
                    <td>{{item.FEEDER_CODE}}</td>
                    <td>{{item.SERVICE_PROVIDER}}</td>
                    <td>{{item.MODEM_NO}}</td>
                    <td>{{item.FEEDER_TYPE}}</td>
                    <td>{{item.FROM_DATE}}</td>
                    <td>{{item.TO_DATE}}</td>
                    <td>{{item.NO_OF_DAYS}}</td>
                    <td>{{item.OUTAGE_COUNT}}</td>
                    <td>{{toHHMMSS(item.OUTAGE_DURATION)}}</td>
                    <td>{{toHHMMSS(item.SUPPLY_DURATION)}}</td>
                    <td>{{toHHMMSS(item.AVG_OUTAGE_DURATION_PER_DAY)}}</td>
                    <td>{{toHHMMSS(item.AVG_SUPPLY_DURATION_PER_DAY)}}</td>
                </tr>

            </tbody>
        </table>
        
    </mat-card-content>
</mat-card>
</ng-container>