import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HttpClient } from '@angular/common/http';
import { formatDate } from '@angular/common';
/**
 * import activated route dor getting parameter from route
 */
import { ActivatedRoute } from '@angular/router';
import { Chart } from 'angular-highcharts';

@Component({
  selector: 'app-vendor-details',
  templateUrl: './vendor-details.component.html',
  styleUrls: ['./vendor-details.component.scss']
})
export class VendorDetailsComponent implements OnInit {

  data1: any = [{
    name: 'Installation',
    data: [43934]
  }]
  chart1 = new Chart({
    chart: {
      marginLeft: 10,
      marginRight: 20,
      renderTo: 'chart',
      type: 'line',
      width: 530,
      height: 280,
      backgroundColor: 'transparent'
    },

    title: {
      text: ''
    },

    // subtitle: {
    //   text: 'Source: thesolarfoundation.com'
    // },

    yAxis: {
      title: {
        text: 'KWh'
      }
    },

    xAxis: {
      title: {
        text: 'Date'
      },
      type: 'datetime',
      dateTimeLabelFormats: {
        day: {
          main: '%e. %b'
        }
      },
    },

    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle'
    },

    plotOptions: {
      series: {
        label: {
          connectorAllowed: false
        },
        pointStart: 1
      }
    },

    series: this.data1,
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    }
  });

  maxDate: any = new Date();
  zoneName: any;
  circleName: any;
  divisionName: any;
  subdivisionName: any;
  districtName: any;
  townName: any;
  substationName: any;
  feederName: any;
  projectId: any;
  deviceState: any;
  serialNumber: any;
  modemNo: any;
  frequency: any;
  lastpacketTime: any;
  lasteventTime: any;
  serviceProvider: any;
  kvah: any;
  kwh: any;
  rcurrent: any;
  bcurrent: any;
  ycurrent: any;
  rvoltage: any;
  bvoltage: any;
  yvoltage: any;
  uom:any;
  rpf: any;
  bpf: any;
  ypf: any;
  supportName: any = {};
  temp: any = []
  designation: any;
  designation1: any;
  designation2: any;
  designation3: any;
  designation4: any;
  emailid: any;
  emailid1: any;
  emailid2: any;
  emailid3: any;
  emailid4: any;
  name: any;
  name1: any;
  name2: any;
  name3: any;
  name4: any;
  phoneno: any;
  phoneno1: any;
  phoneno2: any;
  phoneno3: any;
  phoneno4: any;
  date1: any;
  date2: any;
  outageCount: any;
  outageDetail: any;
  getCurrentDate = formatDate(new Date(), 'yyyy/MM/dd', 'en').split('/').join('-'); //formatDate(new Date(), 'yyyy/MM/dd', 'en');
  date=new Date();
  firstDay = formatDate(new Date(this.date.getFullYear(),this.date.getMonth(), 1),'yyyy/MM/dd', 'en').split('/').join('-');
  outageData: any;
  selectedDate:any;
  constructor(private _http: HttpClient, private AuthService: AuthService, private ActivateRoute: ActivatedRoute,private NgxUi:NgxUiLoaderService) { }
  feederId: any;
  discomName: any;
  ngOnInit(): void {
    /**
     * single page feeder api
     */
    console.log(this.ActivateRoute.snapshot.params.fid);
    this.feederId = this.ActivateRoute.snapshot.params.fid;
    this.getStaticData()   
  }
  /**
   * get static data
   */
  getStaticData() {
    
    this.AuthService.getSingleFeederDetails(this.feederId).subscribe(res => {
      this.NgxUi.start()
      /**
       * set discom name
       */
      this.discomName = res.Data.discom_name;
      /**
       * set zone name
       */
      this.zoneName = res.Data.zone_name
      /**
       * set circle name
       */
      this.circleName = res.Data.circle_name
      /**
       * set division name
       */
      this.divisionName = res.Data.division_name
      /**
       * set subdivision name
       */
      this.subdivisionName = res.Data.subdivision_name
      /**
       * set district name
       */
      this.districtName = res.Data.district_name
      /**
       * set town name
       */
      this.townName = res.Data.town_name
      /**
       * set substation name
       */
      this.substationName = res.Data.site_name
      /**
       * set feeder name
       */
      this.feederName = res.Data.feeder_name
      /**
       * set project id
       */
      this.projectId = res.Data.project_id
      /**
       * set device state
       */
      this.deviceState = res.Data.device_state
      /**
       * set serial number
       */
      this.serialNumber = res.Data.serial_no
      /**
       * set modem no
       */
      this.modemNo = res.Data.modem_no
      /**
       * set frequency
       */
      this.frequency = res.Data.frequency
      /**
       * set last packet time
       */
      this.lastpacketTime = res.Data.last_packet_time
      /**
       * set last event time
       */
      this.lasteventTime = res.Data.last_event_time
      /**
       * set service provider
       */
      this.serviceProvider = res.Data.service_provider
      /**
       * set reading kvah
       */
      this.kvah = res.Data.KVAh;
      /**
       * set reading kwh
       */
      this.kwh = res.Data.KWh
      /**
       * set rcurrent
       */
      this.rcurrent = res.Data.R_Current
      /**
       * set bcurrent
       */
      this.bcurrent = res.Data.B_Current
      /**
       * set Ccurrent
       */
      this.ycurrent = res.Data.Y_Current
      /**
     * set rcurrrvoltageent
     */
      this.rvoltage = res.Data.R_Voltage
      /**
       * set bvoltage
       */
      this.bvoltage = res.Data.B_Voltage
      /**
       * set yvoltage
       */
      this.yvoltage = res.Data.Y_Voltage
      /**
      * set rcurrrvoltageent
      */
      this.rpf = res.Data.R_PF
      /**
       * set bBF
       */
      this.bpf = res.Data.B_PF
      /**
       * set yBF
       */
      this.ypf = res.Data.Y_PF
      this.designation = res.Data.designation
      this.designation1 = res.Data.designation1
      this.designation2 = res.Data.designation2
      this.designation3 = res.Data.designation3
      this.designation4 = res.Data.designation4
      this.emailid = res.Data.email_id
      this.emailid1 = res.Data.emailid1
      this.emailid2 = res.Data.email_d2
      this.emailid3 = res.Data.email_d3
      this.emailid4 = res.Data.email_id4
      this.name = res.Data.name
      this.name1 = res.Data.name1
      this.name2 = res.Data.name2
      this.name3 = res.Data.name3
      this.name4 = res.Data.name4
      this.phoneno = res.Data.phone_no
      this.phoneno1 = res.Data.phone_no1
      this.phoneno2 = res.Data.phone_no2
      this.phoneno3 = res.Data.phone_no3
      this.phoneno4 = res.Data.phone_no4
      this.NgxUi.stop()
    })
    
    this.getOutageCount()
   
  }
  /**
   * get outage count
   */
  getOutageCount() {
    this.NgxUi.start()
    this.AuthService.getOutageDuration(this.feederId, this.getCurrentDate).subscribe(res => {
      this.outageCount = res.Data.OUTAGE_COUNT
      this.outageDetail = res.Data.OUTAGE_DURATION
      this.NgxUi.stop()
      //this.getGraphData(this.feederId,this.firstDay, this.getCurrentDate)

      this.getInterruptionDetail()
    })
  }
  /**
   * get graph data
   */
  getGraphData(meterId:any,fromDate:any,toDate:any) {
    let DateCount = toDate.split("-")
    let labelarray:any = []
    for(let i=0;i<DateCount[2];i++){
      let d = DateCount[0]+"-"+DateCount[1]+"-"+(i+1) ;
      labelarray.push(d)
    }
    console.log(labelarray)
    this.AuthService.getSingleFeederGraphData(meterId,fromDate,toDate).subscribe(res => {
      this.NgxUi.start()
      var out: any = [];
      var meterDate: any = [];
      for (var i = 0; i < res.Data.length; i++) {
        this.uom = res.Data[i]['uom'];
        var tmp = [];
        tmp.push(res.Data[i]['consumed_KWh']);
        out.push(tmp);
      }
      let data1: any = [{ name:'Units',
        data: out }]
        console.log(data1)
      this.chart1 = new Chart({
        chart: {
          marginLeft: 10,
          marginRight: 20,
          renderTo: 'chart',
          type: 'line',
          width: 550,
          height: 300,
          backgroundColor: 'transparent'
        },

        title: {
          text: ''
        },

        yAxis: {
          title: {
            text: 'KWh'
          },
        },

        xAxis: {
          categories:labelarray,
          labels: {
            rotation: 90
        }
        },

        legend: {
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'middle'
        },

        plotOptions: {
          series: {
            label: {
              connectorAllowed: false
            },
          }
        },

        series: data1,
        responsive: {
          rules: [{
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom'
              }
            }
          }]
        }
      });
      this.NgxUi.stop()
     // this.getInterruptionDetail()
    })
  }
  /**
   * get interruption detail
   */
  getInterruptionDetail() {
    this.AuthService.getInterruptionCount(this.feederId,this.firstDay,this.getCurrentDate).subscribe(res => {
      this.outageData=res;
      this.getGraphData(this.feederId,this.firstDay, this.getCurrentDate)
    })
 
  }
  /**
   * get date picker date
   */
   OnDateChange(value:any){
     var d;
    this.selectedDate=formatDate(new Date(value), 'yyyy/MM/dd', 'en').split('/').join('-'); 
    if(this.selectedDate<this.firstDay){
      d=new Date(value);
      this.firstDay= formatDate(new Date(d.getFullYear(),d.getMonth(), 1),'yyyy/MM/dd', 'en').split('/').join('-');
      this.selectedDate= formatDate(new Date(d.getFullYear(),d.getMonth()+1, 1),'yyyy/MM/dd', 'en').split('/').join('-');
      this.getGraphData(this.feederId,this.firstDay,this.selectedDate)
    }
    else{
      this.getGraphData(this.feederId,this.firstDay,this.getCurrentDate)
    }
    // this.getGraphData(this.feederId,)
   }

}
