import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GraphicalDashboardComponent } from './graphical-dashboard/graphical-dashboard.component';
import { HomeComponent } from './home/home.component';
import { MainComponent } from './main/main.component';
import { NoModemDashComponent } from './no-modem-dash/no-modem-dash.component';
import { OutageRoasterComponent } from './outage/outage-roaster.component';
import { RedirectComponent } from './redirect/redirect.component';
import { RoasterDetailsComponent } from './roaster-details/roaster-details.component';
import { SigninComponent } from './signin/signin.component';
import { VendorDetailsComponent } from './vendor-details/vendor-details.component';
import { LoadComponent } from './load/load.component';
import { AuthguardGuard } from './authguard.guard';

const routes: Routes = [  {
  path: '',
  component:MainComponent,
   children: [
    { path: 'dashboard', redirectTo: 'dashboard', pathMatch: 'full',canActivate:[AuthguardGuard] },
    { path: 'dashboard', component: HomeComponent,canActivate:[AuthguardGuard]  },
    { path: 'graphical/dashboard', component: GraphicalDashboardComponent,canActivate:[AuthguardGuard] },
    { path: 'NoModem', component: NoModemDashComponent,canActivate:[AuthguardGuard] },
    //vendor detail routes
    { path: 'vendorDetails/:fid', component:VendorDetailsComponent,canActivate:[AuthguardGuard]},
    {path:'outage',component:OutageRoasterComponent,canActivate:[AuthguardGuard]},
    {path:'outageDetails',component:RoasterDetailsComponent,canActivate:[AuthguardGuard]},
    {path:'report',loadChildren: () => import('./report/report.module')
    .then(m => m.ReportModule),canActivate:[AuthguardGuard]
 
},{path:'reportmsi',
  
loadChildren: () => import('./report-msi/report-msi.module')
.then(m => m.ReportMsiModule),canActivate:[AuthguardGuard]

},

    { path: '',   redirectTo: '/signin', pathMatch: 'full' }, // redirect to `first-component`

  ]
},
 { path: 'signin', component: SigninComponent },
 { path: 'signin/:token_id', component: SigninComponent },
 { path: 'LoadCurve', component:LoadComponent,canActivate:[AuthguardGuard]}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
