import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { CommonService } from '../common.service';
import { DatePipe } from '@angular/common';
import { Chart } from 'angular-highcharts';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SupplyDialogComponent } from '../supply-dialog/supply-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-graphical-dashboard',
  templateUrl: './graphical-dashboard.component.html',
  styleUrls: ['./graphical-dashboard.component.scss'],
  providers:[DatePipe]
})

export class GraphicalDashboardComponent implements OnInit {
  graphData: any;

  constructor( public dialog: MatDialog,private service: CommonService,private AuthService:AuthService,private datepipe:DatePipe ,private NgxUiLoaderService:NgxUiLoaderService) {
    this.service.data$.subscribe(res => {
      if (res == "toggle") {

      } else if (res == "default data") { } else {

        if (JSON.parse(res).type == 'map') {
            this.discom =  JSON.parse(res).dicom;
            this.project = JSON.parse(res).project;
            this.getGraphData()
        }
      }
    })
   }
  supplyhr:any = 24;
  discom:any = this.service.getdiscom;
  project:any = this.service.getscheme;
  date1:any;
  date2:any;
  ngOnInit(): void {
    this.getGraphData();
  }
  
  chart1 = new Chart({
    chart: {
        type: 'column'
    },
    title: {
        text: ''
    },
    subtitle: {
        text: ''
    },
    xAxis: {
        categories: [
        
        ],
        crosshair: true
    },
    yAxis: {
        min: 0,
        title: {
            text: 'Supply in Hr'
        }
    },
    tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">Feeder Count: </td>' +
            '<td style="padding:0"><b>{point.y} mm</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
    },
    plotOptions: {
      series: {
        cursor: 'pointer',

          point: {
              events: {
                  click: function () {
                          
                  }
              }
          }
      
  },
        column: {
            pointPadding: 0.2,
            borderWidth: 0
        } 
    },
    series: [{
        name: ' ',
        type: 'column',

        data: []

    }]
});
chart2 = new Chart({
  chart: {
      type: 'column'
  },
  title: {
      text: ''
  },
  subtitle: {
      text: ''
  },
  xAxis: {
      categories: [
      
      ],
      crosshair: true
  },
  yAxis: {
      min: 0,
      title: {
          text: 'Supply in Hr'
      }
  },
  tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat: '<tr><td style="color:{series.color};padding:0">Feeder Count: </td>' +
          '<td style="padding:0"><b>{point.y} mm</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true
  },
  plotOptions: {
    series: {
      cursor: 'pointer',

        point: {
            events: {
                click: function () {
                        
                }
            }
        }
    
},
      column: {
          pointPadding: 0.2,
          borderWidth: 0
      } 
  },
  series: [{
      name: ' ',
      type: 'column',

      data: []

  }]
});
  getGraphData(){
    this.NgxUiLoaderService.start()
      // Current date is 25th March 2020
    this.date1 = new Date();
 // now increment it by 1 day
 
 this.date1.setDate(this.date1.getDate() - 1);
 this.date1 = this.datepipe.transform(this.date1, 'yyyy-MM-dd');
    this.date2 = this.date1;
//discom_id:any,project_type:any,supplyhr:any,date1:any,date2:any
    this.AuthService.getSupplyGraph(this.discom,this.project,this.supplyhr,this.date1,this.date2).subscribe(res=>{
      this.graphData = res;
      this.NgxUiLoaderService.stop()
      if(this.discom != 'ALL'){
        let catagory = []
        let data1=[]
        let data2 =[]
  for(const data in res.graph_data_project){
    if(data != 'TEHSIL' && data != 'MIX'){

 
  catagory.push(data);
  data1.push(res.graph_data_project[data]);
  data2.push(res.graph_data_project_outage[data])
}
  }
  this.chart1Function(data1,catagory)
  this.chart2Function(data2,catagory)

      }else{
        let catagory = []
        let data1=[]
        let data2=[]
  for(const data in res.graph_data_discom){
  catagory.push(data);
  data1.push(res.graph_data_discom[data]);
  data2.push(res.graph_data_discom_outage[data])
  }
  this.chart1Function(data1,catagory)
  this.chart2Function(data2,catagory)

      }
  
    })
  }
  chart2Function(data:any,count:any){
     

    this.chart2 = new Chart({
      chart: {
          type: 'column'
      },
      title: {
          text: 'Outage Count'
      },
      subtitle: {
          text: ''
      },
      xAxis: {
          categories:count,
          crosshair: true
      },
      yAxis: {
          min: 0,
          title: {
              text: 'Outage Count'
          }
      },
      tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">Outage Count: </td>' +
              '<td style="padding:0"><b>{point.y} </b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
      },
      plotOptions: {
        series: {
          cursor: 'pointer',
  
           
        
    },
          column: {
              pointPadding: 0.2,
              borderWidth: 0
          }
      },
      series:[
       { name: 'Outage  ',
        type: 'column',

        data:data}
      ]
  });
   }
  chart1Function(data:any,count:any){
     

    this.chart1 = new Chart({
      chart: {
          type: 'column'
      },
      title: {
          text: 'Supply'
      },
      subtitle: {
          text: ''
      },
      xAxis: {
          categories:count,
          crosshair: true
      },
      yAxis: {
          min: 0,
          title: {
              text: 'Feeder Count'
          }
      },
      tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">Feeder Count: </td>' +
              '<td style="padding:0"><b>{point.y} </b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
      },
      plotOptions: {
        series: {
          cursor: 'pointer',
  
            point: {
                events: {
                    click: (e)=> {
                      var category =e.point.category
                             if(category == 'PVVNL' || category == 'KESCo' || category == 'DVVNL'|| category =='PuVVNL'|| category =='MVVNL'){
                               this.getData('discom',category)
                            }else{
                              this.getData('project',category)

                            }
                    } 
                }
            }
        
    },
          column: {
              pointPadding: 0.2,
              borderWidth: 0
          }
      },
      series:[
       { name: 'Supply  ',
        type: 'column',

        data:data}
      ]
  });
   }
   hour=[{
    value:15,
    namevalue:"15-16"
  },{
    value:16,
    namevalue:"16-17"
  },{
    value:17,
    namevalue:"17-18"
  },{
    value:18,
    namevalue:"18-19"
  },{
    value:19,
    namevalue:"19-18"
  },{
    value:20,
    namevalue:"20-21"
  },{
    value:21,
    namevalue:"21-22"
  },{
    value:22,
    namevalue:"22-23"
  },{
    value:23,
    namevalue:"23-24"
  },{
    value:24,
    namevalue:"24"
  }]
  _sendDataToParent(data: string) {
    this.service.changeData(data);  //invoke new Data

  }
  openDialog(data:any){
     
    const dialogRef = this.dialog.open(SupplyDialogComponent, {
      width: '80%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });

  }
  getData(type:any,data:any){
    if(type =='discom'){
        this.openDialog(this.graphData.DISCOM_Data[data])
    }else {
       this.openDialog(this.graphData.PROJECT_Data[data])

    }
console.log(this.graphData)

  }
}
