<div class=" ">
    <app-header></app-header>
    <p style=" margin-bottom: 13px;text-align: center;color: black;background: black;padding: 10px;color: white;"><span style="font-size: 28px;margin-bottom: 5px;font-weight: 500;border-radius: 10px;;">UPPCL Feeder Monitoring Dashboard</span></p>
    <hr>
    <div class="container2" style="margin: 9px 40px 0px 40px;">
        <div fxLayout="row wrap">
            <div fxFlex="100" style="height: 100%;">
                <div fxLayout="row wrap">
                    <div fxFlex="100">
                        <div fxLayout="row wrap">
                            <div fxFlex="25">
                                <mat-form-field appearance="outline" style="font-size: 13px;">
                                    <mat-label class="zoneLabe" style="font-size: 14px; color:black">Select Zone
                                    </mat-label>
                                    <mat-select [(ngModel)]="zone" name="zone" [ngModelOptions]="{standalone: true}" [disabled]="zonedisabled"
                                        (selectionChange)="selectedzone()">
                                        <mat-option>
                                            <ngx-mat-select-search [(ngModel)]="zoneCtrl"
                                                [placeholderLabel]="'Search...'" [noEntriesFoundLabel]="'Not found'"
                                                name="search" [ngModelOptions]="{standalone: true}">
                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option [value]="'ALL'">
                                            ALL
                                        </mat-option>
                                        <mat-option *ngFor="let list of zoneData   |stringFilterBy:zoneCtrl"
                                            [value]="list">
                                            {{list}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div fxFlex="25">
                                <mat-form-field appearance="outline">
                                    <mat-label style="font-size: 14px; color:black">Select Circle</mat-label>
                                    <mat-select [(ngModel)]="circle" name="circle" [ngModelOptions]="{standalone: true}" [disabled]="circledisabled"
                                        (selectionChange)="selectedcircle()">
                                        <mat-option>
                                            <ngx-mat-select-search [(ngModel)]="circleCtrl"
                                                [placeholderLabel]="'Search...'" [noEntriesFoundLabel]="'Not found'"
                                                name="search" [ngModelOptions]="{standalone: true}">
                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option [value]="'ALL'">
                                            ALL
                                        </mat-option>
                                        <mat-option *ngFor="let list of cirlcleListData|stringFilterBy:circleCtrl"
                                            [value]="list">
                                            {{list}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div fxFlex="25">
                                <mat-form-field appearance="outline">
                                    <mat-label style="font-size: 14px; color:black">Select Division</mat-label>
                                    <mat-select [(ngModel)]="division" name="division"
                                        [ngModelOptions]="{standalone: true}" (selectionChange)="selecteddivision()">
                                        <mat-option>
                                            <ngx-mat-select-search [(ngModel)]="divisionCtrl"
                                                [placeholderLabel]="'Search...'" [noEntriesFoundLabel]="'Not found'"
                                                name="search" [ngModelOptions]="{standalone: true}">
                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option [value]="'ALL'">
                                            ALL
                                        </mat-option>
                                        <mat-option *ngFor="let list of divisionListData|stringFilterBy:divisionCtrl"
                                            [value]="list">
                                            {{list}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div fxFlex="25">
                                <mat-form-field>
                                    <mat-label>Search</mat-label>
                                    <mat-icon matPrefix>search</mat-icon>

                                    <input matInput [(ngModel)]="meterNo" (keyup.enter)="getMeterSearch()"
                                        [ngModelOptions]="{standalone: true}">
                                </mat-form-field>
                            </div>
                        </div>

                    </div>
                </div>
                <div fxLayout="row wrap">

                    <div fxFlex="50">
                        <div fxFlex="50" >
                            <mat-form-field appearance="outline">
                                <mat-label style="font-size: 14px; color:black">Select Sub Division</mat-label>
                                <mat-select [(ngModel)]="subdivision" name="subdivision"
                                    [ngModelOptions]="{standalone: true}"
                                    (selectionChange)="selectedSubStation(subdivision)">
                                    <mat-option>
                                        <ngx-mat-select-search [(ngModel)]="subdivisionCtrl"
                                            [placeholderLabel]="'Search...'" [noEntriesFoundLabel]="'Not found'"
                                            name="search" [ngModelOptions]="{standalone: true}"></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option [value]="'ALL'">
                                        ALL
                                    </mat-option>
                                    <mat-option *ngFor="let list of subdivisionListData|stringFilterBy:subdivisionCtrl"
                                        [value]="list">
                                        {{list}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>


                        <div fxFlex="50">
                            <mat-form-field appearance="outline">
                                <mat-label style="font-size: 14px; color:black">Select Sub Station</mat-label>
                                <mat-select [(ngModel)]="substation" name="substation"
                                    [ngModelOptions]="{standalone: true}"
                                    (selectionChange)="drawMapsubstation(subdivision)">
                                    <mat-option>
                                        <ngx-mat-select-search [(ngModel)]="substasionCtrl"
                                            [placeholderLabel]="'Search...'" [noEntriesFoundLabel]="'Not found'"
                                            name="search" [ngModelOptions]="{standalone: true}"></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option [value]="'ALL'">
                                        ALL
                                    </mat-option>
                                    <mat-option *ngFor="let list of subStationListData|stringFilterBy:substasionCtrl"
                                        [value]="list">
                                        {{list}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                    </div>
                    <!-- <div fxFlex="30" >
            <mat-form-field class="my-form-field"  style="    float: right;
              font-size: 15px" [color]="'rgb(41, 51, 104)'" appearance="outline">
                <mat-label class ="customSelec2" style="  font-size: 20px;color:#293368 ;">Select Type</mat-label>

              
                <mat-select   [(ngModel)]="type" name="select"   [ngModelOptions]="{standalone: true}"   (selectionChange) ="_sendselectType()">
                  
                    <mat-option      [value]="'ALL'">
                        All
                    </mat-option>
                    <mat-option      [value]="'INCOMING'">
                        Incommer
                    </mat-option>
                    <mat-option      [value]="'OUTGOING'">
                        Outgoing
                    </mat-option>
                  </mat-select>
                
            </mat-form-field>

        </div> -->
                </div>
                <div id="mapid"></div>

            </div>
        </div>
    </div>
</div>