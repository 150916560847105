import { Component, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

import { MatTableDataSource } from '@angular/material/table';

import { MatPaginator } from '@angular/material/paginator';
import { CommonService } from '../common.service';
import { FeederSearchDialogComponent } from '../feeder-search-dialog/feeder-search-dialog.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-outage-roaster',
  templateUrl: './outage-roaster.component.html',
  styleUrls: ['./outage-roaster.component.scss']
})
export class OutageRoasterComponent implements OnInit {
  data = {
    forMonth: '99',
    project_id: 'REC',
    supply_hour: ''

  };
  meterNo:any;
  openDialog(data: any) {

    const dialogRef = this.dialog.open(FeederSearchDialogComponent, {
      width: '80%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  getMeterSearch() {


    this.AuthService.getByMeterNo(this.meterNo).subscribe(res => {
      this.openDialog(res.data)
    })
  }

  hour: any;
  minutes: any;
  secound: any;
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  @ViewChild('picker', { static: false }) picker: any;
  dataSource: any;
  @ViewChild(MatPaginator) paginator: MatPaginator | any;

  constructor(private CommonService:CommonService,private Router: Router, private AuthService: AuthService,public dialog: MatDialog) {
    setInterval(() => {
      this.hour = new Date().getHours();
      this.minutes = new Date().getMinutes();
      this.secound = new Date().getSeconds();
    }, 1000)

  }
  route(data: string) {
    this.Router.navigateByUrl(data);
  }
  ngOnInit(): void {
    this.getOutageRoaster();
  }
  saveData() {
       this.data.supply_hour = this.picker.defaultTime + ':' + '00';
      this.AuthService.saveOutageRoaster(this.data).subscribe(res => {
        alert(res['message']);
        this.getOutageRoaster();
  
      })
   
  
  }
  _sendDataToParent(data:string) {
    this.CommonService.changeData(data);  //invoke new Data

}
  outageList: any;
  getOutageRoaster() {
    this.dataSource = [];
    this.AuthService.getOutageRoaster('ALL').subscribe(res => {
      console.log(JSON.stringify(res))
      for (var i = 0; i < res['feeders'].length; i++) {
        res['feeders'][i].position = i + 1;
      }

      this.dataSource = new MatTableDataSource<any>(res['feeders']);
      this.dataSource.paginator = this.paginator;


    })
  }

}
