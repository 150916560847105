

<div class=" ">
    
   <app-header></app-header>
   <p style="       margin-bottom: 13px;
text-align: center;
color: black;
background: black;
padding: 10px;
color: white;"><span style="        font-size: 28px;
    
    margin-bottom: 5px;
    font-weight: 500;;
    border-radius: 10px;;">Outage Roaster</span>

</p>
<hr>

<div fxLayout="row wrap "  >

    <div fxFlex>
        <mat-card style="width: 60%; margin: auto;">
          
            <p style="color: #145782; font-weight: 600 ; font-size: 16px;">
                <span> 
                        
                    <button mat-icon-button aria-label="Example icon button with a vertical three dot icon" [routerLink]="['/dashboard']">
                    <mat-icon>arrow_back</mat-icon>
                  </button></span>
                Outage Roaster
            
               
            </p>
            <hr>
            <div fxLayout="row wrap">

                <div fxFlex="40" style="margin: auto;">
                    <mat-form-field appearance="outline" style="width: 100%;">
                        <mat-label>Project</mat-label>
                        <mat-select  [(ngModel)]="data.project_id">

                            <mat-option   [value]="'REC'">
                             REC
                            </mat-option>
                            <mat-option   [value]="'RAPDRP'">
                                RAPDRP
                               </mat-option>
                               <mat-option   [value]="'NTPF'">
                                NTPF
                               </mat-option>
                               <mat-option   [value]="'IPDS'">
                                IPDS
                               </mat-option>
                               <mat-option   [value]="'EODB'">
                                EODB
                               </mat-option>
                          </mat-select>

                       </mat-form-field>
                  
<mat-form-field class="example-full-width" appearance="outline" style="width: 100%;">
    <mat-label>Supply Hour</mat-label>
    <input matInput    [ngxTimepicker]="picker"  [format]="24"   >
  </mat-form-field>
                        <ngx-material-timepicker #picker    ></ngx-material-timepicker>
                        
                       <mat-form-field appearance="outline" style="width: 100%;">
                        <mat-label>For Month</mat-label>
                        <mat-select  [(ngModel)]="data.forMonth">
                            <mat-option   [value]="'99'">
                             All
                            </mat-option>
                            <mat-option   [value]="'1'">
                                Jan
                               </mat-option>
                               <mat-option   [value]="'2'">
                                Feb
                               </mat-option>
                               <mat-option   [value]="'3'">
                                March
                               </mat-option>
                               <mat-option   [value]="'4'">
                                April
                               </mat-option>
                               <mat-option   [value]="'5'">
                                May
                               </mat-option>
                               <mat-option   [value]="'6'">
                                   June
                                  </mat-option>
                                  <mat-option   [value]="'7'">
                                   July
                                  </mat-option>
                                  <mat-option   [value]="'8'">
                                   Aug
                                  </mat-option>
                                  <mat-option   [value]="'9'">
                                   Sept
                                  </mat-option>
                                  <mat-option   [value]="'10'">
                                    Oct
                                   </mat-option>
                                   <mat-option   [value]="'11'">
                                    Nov
                                   </mat-option>
                                   <mat-option   [value]="'12'">
                                    Dec
                                   </mat-option>
                                    
                          </mat-select>

                       </mat-form-field>
                      <button mat-raised-button color="primary" (click) ="saveData()">Save</button>


                </div>

            </div>
            <div fxLayout="row wrap">

                <div fxFlex="100" style="margin: auto;">
                    <p style="color: #145782; font-weight: 600 ; font-size: 16px;">Defined Outage Roaster</p>
                    <hr>
                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                        <!--- Note that these columns can be defined in any order.
                              The actual rendered columns are set as a property on the row definition" -->
                      
                        <!-- Position Column -->
                        <ng-container matColumnDef="position">
                            <th mat-header-cell *matHeaderCellDef> Sr No. </th>
                            <td mat-cell *matCellDef="let element">{{element.position}}</td>
                          </ng-container>
                        
                      
                        <!-- Name Column -->
                        <ng-container matColumnDef="name">
                          <th mat-header-cell *matHeaderCellDef> Project Name </th>
                          <td mat-cell *matCellDef="let element"> {{element.project_id}} </td>
                        </ng-container>
                      
                        <!-- Weight Column -->
                        <ng-container matColumnDef="weight">
                          <th mat-header-cell *matHeaderCellDef> Supply Hour (HH:MM:SS) </th>
                          <td mat-cell *matCellDef="let element"> {{element.supply_duration}} </td>
                        </ng-container>
                      
                        <!-- Symbol Column -->
                        <ng-container matColumnDef="symbol">
                          <th mat-header-cell *matHeaderCellDef> Applicable For Month </th>
                          <td mat-cell *matCellDef="let element"> 
                              
                            <span *ngIf = "element.applicableMonth =='99'">All</span>
                            <span *ngIf = "element.applicableMonth =='1'">January</span>
                            <span *ngIf = "element.applicableMonth =='2'">February</span>
                            <span *ngIf = "element.applicableMonth =='3'">March</span>
                            <span *ngIf = "element.applicableMonth =='4'">April</span>
                            <span *ngIf = "element.applicableMonth =='5'">May</span>
                            <span *ngIf = "element.applicableMonth =='6'">June</span>
                            <span *ngIf = "element.applicableMonth =='7'">July</span>
                            <span *ngIf = "element.applicableMonth =='8'">August</span>
                            <span *ngIf = "element.applicableMonth =='9'">September</span>
                            <span *ngIf = "element.applicableMonth =='10'">October</span>
                            <span *ngIf = "element.applicableMonth =='11'">November</span>
                            <span *ngIf = "element.applicableMonth =='12'">December</span>

                            </td>
                        </ng-container>
                      
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                      </table>
                      <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

                </div>
            </div>
        </mat-card>
    </div>

</div></div>