import { Component, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { count } from 'console';
import { AuthService} from '../auth.service';
import { FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-load',
  templateUrl: './load.component.html',
  styleUrls: ['./load.component.scss']
})
export class LoadComponent implements OnInit {
  discom_id: any = 'ALL';
  zone_id: any = 'ALL';
  circle_id: any = 'ALL';
  division_id:any ='ALL';
  subdivision_id:any ='ALL';
  substation_id:any ='ALL';
  discom_name:any='ALL';
  date = new FormControl(new Date());
  fromdate:any;
  todate:any;
  curr_date= new Date();
  constructor(public auth_service : AuthService ,private datepipe: DatePipe) { }

  ngOnInit(): void {
    var date_new=this.datepipe.transform(this.curr_date, 'yyyy-MM-dd');

    this.graph_data('ALL',date_new,date_new);
  }
  discomList = [
    { name: "ALL", value: "ALL" },
    { name: "PuVVNL", value: "5a71b954cf5e58.55295458" },
    { name: "MVVNL", value: "5a71b8e6167566.89648438" },
    { name: "DVVNL", value: "5a71b6d35156d7.70878980" },
    { name: "PVVNL", value: "5a71b98adb88d0.91464350" },
    { name: "KESCo", value: "5a71b8a355c2d4.24012768" }
  ]

  zoneData: any = [];
  onlyUnique(value: any, index: any, self: any) {
    return self.indexOf(value) === index;
  }
  cirlcleListData: any;
  divisionListData:any;
  subdivisionListData:any;
  subStationListData: any;

  selectedzone(id:any){
   let  projectType="ALL";
   let  districtType="ALL";
   let type="OUTGOING"
   this.discom_name=this.discomList.filter(res => res.value == id)[0].name;
   this.discom_id = id ;
    this.auth_service.getZoneList(projectType,id,districtType,type).subscribe(res => {
    this.zoneData = res.Data.filter(this.onlyUnique);
   });
  }

  selectedcircle(id:any){
    this.zone_id = id
    this.auth_service.getCircleList(id).subscribe(res => {
        this.cirlcleListData = res.Data.filter(this.onlyUnique);
    })
  }

  selecteddivision(id:any){
    this.circle_id = id
    this.auth_service.getdivision(id).subscribe(res => {
    this.divisionListData = res.Data.filter(this.onlyUnique);
    })
  }

  selectedsubdivision(id:any){
    this.division_id=id;
    this.auth_service.getsubdivision(id).subscribe(res => {
    this.subdivisionListData = res.Data.filter(this.onlyUnique);
    })
  }

  selectedsubstation(subdivision_name:any){
        this.subdivision_id=subdivision_name;
        this.auth_service.getsubstation(subdivision_name,this.division_id,this.circle_id,this.zone_id).subscribe(res => {
        this.subStationListData = res.Data.filter(this.onlyUnique);
        })
  }
 
  graph_data(id:any='ALL',fromdate:any,todate:any){
    this.fromdate= this.datepipe.transform(fromdate, 'yyyy-MM-dd');
    this.todate= this.datepipe.transform(todate, 'yyyy-MM-dd');
    this.substation_id=id;
    this.getGraphData();
  }

  //------------------chart code---------------------
  chart2 = new Chart({
    chart: {
        type: 'line',
        height:400,
        width:1100,
    },
    title: {
        text: ''
    },
    subtitle: {
        text: ''
    },
    xAxis: {
        categories: [
        
        ],
        crosshair: true
    },
    yAxis: {
        min: 0,
        title: {
            text: ''
        }
    },
   
    plotOptions: {
      series: {
        cursor: 'pointer',
  
          point: {
              events: {
                  click: function () {
                          
                  }
              }
          }
      
  },
        column: {
            pointPadding: 0.2,
            borderWidth: 0
        } 
    },
    series: [{
        name: ' ',
        type: 'line',
  
        data: []
  
    }]
  });

  getGraphData(){  
    
    this.auth_service.getloadcurvedata(this.fromdate,this.todate,this.discom_name,this.zone_id,this.circle_id,this.division_id,this.subdivision_id,this.substation_id).subscribe(res=>{
    if(res.STATUS=='error.'){
      alert(res.MESSAGE);
      return;
    }
    var data= res.Data;
    this.chart2Function(data.INSTANT_LOAD_KVA,data.INSTANT_LOAD_KW,data.CREATION_TIME,data.TEMPERATURE,data.APPARENT_TEMPERATURE);
     })
    
   
  }
  chart2Function(data1:any,data2:any,data3:any,data4:any,data5:any){
    this.chart2 = new Chart({
      chart: {
          type: 'line',
          zoomType: 'x'
      },
      title: {
          text: ''
      },
      subtitle: {
          text: ''
      },
      xAxis: {
          categories: data3,
          crosshair: true
      },
      yAxis: {
        title: {
          text:'Average Load'
        },
          min: 0
          
      },
      
      plotOptions: {
        series: {
          cursor: 'pointer',
    
            point: {
                events: {
                    click: function () {
                            
                    }
                }
            }
        
    },
          column: {
              pointPadding: 0.2,
              borderWidth: 0
          } 
      },
      series: [{
          name: 'Instant kVA',
          type: 'area',
          color:'#EB5406',
          data: data1
    
      },{
        name: 'Instant kW',
        type: 'area',
        color:'#B8860B',
        data: data2
  
    },{
      name: 'Temperature',
      type: 'line',
      color:'#FF0000',
      data: data4

  },{
    name: 'Apparent Temperature',
    type: 'line',
    color:'#020096',
    data: data5

}]
    
    });
  }


}
