<form [formGroup]="FormGroup">

<div class="row">
    <div class="col-md-6 mx-auto p-0">
        <div class="card" style="margin:auto;">
            <div class="login-box">
                <div class="login-snip"> <input id="tab-1" type="radio" name="tab" class="sign-in" checked><label
                        for="tab-1" class="tab">Login</label> <input id="tab-2" type="radio" name="tab"
                        class="sign-up"><label for="tab-2" class="tab"></label>
                    <div class="login-space">
                        <div class="login">
                            <div style="width: 100%;text-align: center;margin-bottom: 14px;">
                                <img src="../../assets/elements/LOGO.png" style="width: 153px;"   >
                            </div >
                            <div class="group"> <label for="user" class="label">Login Id</label> <input id="user"
                                    type="text" class="input" placeholder="Enter your username" formControlName="login_id" autocomplete="off"> </div>
                            <div class="group"> <label for="pass" class="label">Password</label> <input id="pass"
                                    type="password" class="input" data-type="password"
                                    placeholder="Enter your password" formControlName="password"  autocomplete="off"> </div>
                            <div class="group"> 
                                <input type="submit" class="button" value="Sign In" (click) ="signIn();">
                                <h5 style="text-align:center;color:white;margin-top:9%;font-size:18px;font-family: 'Gill Sans', sans-serif;">STATE FEEDER MONITORING SYSTEM</h5>
                                <h5 style="text-align:center;color:white;font-size:18px;font-family: 'Gill Sans', sans-serif;">(SFMS)</h5>
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
        </div>
    </div>
</div>
</form>