import { NgModule } from "@angular/core";
import {MatStepperModule} from "@angular/material/stepper"
import {MatTabsModule} from "@angular/material/tabs"
import {MatRippleModule, MatNativeDateModule} from "@angular/material/core"
import {MatExpansionModule} from "@angular/material/expansion"
import {MatButtonToggleModule} from "@angular/material/button-toggle"
import {MatChipsModule} from "@angular/material/chips"
import {MatIconModule} from "@angular/material/icon"
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner"
import {MatProgressBarModule} from "@angular/material/progress-bar"
import {MatDialogModule} from "@angular/material/dialog"
import {MatTooltipModule} from "@angular/material/tooltip"
import {MatSnackBarModule} from "@angular/material/snack-bar"
import {MatTableModule} from "@angular/material/table"
import {MatSortModule} from "@angular/material/sort"
import {MatPaginatorModule} from "@angular/material/paginator"
import {MatTreeModule} from "@angular/material/tree"
import {MatBadgeModule} from "@angular/material/badge"
import {MatBottomSheetModule} from "@angular/material/bottom-sheet"
import {MatDividerModule} from "@angular/material/divider"
import {MatButtonModule} from "@angular/material/button"
import {MatInputModule} from "@angular/material/input"
import {MatAutocompleteModule} from "@angular/material/autocomplete"
import {MatDatepickerModule} from "@angular/material/datepicker"

import {MatFormFieldModule} from "@angular/material/form-field"
import {MatRadioModule} from "@angular/material/radio"
import {MatSelectModule} from "@angular/material/select"
import {MatSliderModule} from "@angular/material/slider"
import {MatSlideToggleModule} from "@angular/material/slide-toggle"
import {MatMenuModule} from "@angular/material/menu"
import {MatSidenavModule} from "@angular/material/sidenav"
import {MatToolbarModule} from "@angular/material/toolbar"
import {MatGridListModule} from "@angular/material/grid-list"
import {MatCheckboxModule} from "@angular/material/checkbox"
import {MatListModule} from "@angular/material/list"
import {MatCardModule} from '@angular/material/card';
import { ChartModule } from 'angular-highcharts';

import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from "@angular/common/http";
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

// for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';

// for Core import:
import { LoadingBarModule, LOADING_BAR_CONFIG } from '@ngx-loading-bar/core';
import { FormsModule ,ReactiveFormsModule} from "@angular/forms";
import { NgxUiLoaderModule } from 'ngx-ui-loader';
 

@NgModule({
  declarations: [ 
     
  ],
  exports: [  NgxUiLoaderModule,NgxMatSelectSearchModule, ChartModule,NgxMaterialTimepickerModule, FlexLayoutModule, MatCardModule,HttpClientModule,LoadingBarHttpClientModule,LoadingBarRouterModule,LoadingBarModule,FormsModule ,ReactiveFormsModule,
    MatCheckboxModule,  
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
     
    MatStepperModule,
    MatTabsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatNativeDateModule,
     
    MatTreeModule,
    MatRippleModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatDividerModule 
  ], imports:[  
  ],
  providers: [{ provide: LOADING_BAR_CONFIG, useValue: { latencyThreshold: 100 } }],

})
export class SharedMaterialModule {}
