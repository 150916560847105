import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { DialogContentExampleDialog, HomeComponent } from './home/home.component';
import { SharedMaterialModule } from './shared.material';
import { MainComponent } from './main/main.component';
import { OutageRoasterComponent } from './outage/outage-roaster.component';
import { FeederSearchDialogComponent } from './feeder-search-dialog/feeder-search-dialog.component';
import { StringFilterByPipe } from "src/string.filter";
import { FeederfilterllistComponent } from './feederfilterllist/feederfilterllist.component';
import { GraphicalDashboardComponent } from './graphical-dashboard/graphical-dashboard.component';
import { SigninComponent } from './signin/signin.component';
import { SupplyDialogComponent } from './supply-dialog/supply-dialog.component';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { DatePipe } from '@angular/common';
import { SharedComponentModule } from './shared-component/shared-component.module';
import { NoModemDashComponent } from './no-modem-dash/no-modem-dash.component';
import { VendorDetailsComponent } from './vendor-details/vendor-details.component';


import { NgxPrintModule} from 'ngx-print';
import { ServiceproviderChartComponent } from './serviceprovider-chart/serviceprovider-chart.component';
import { RedirectComponent } from './redirect/redirect.component';
import { OutagedetailpopupComponent } from './outagedetailpopup/outagedetailpopup.component';
import { FilterPipe } from './filter.pipe';
import { RoasterDetailsComponent } from './roaster-details/roaster-details.component';
import { AddRoasterComponent } from './add-roaster/add-roaster.component';
import { LoadComponent } from './load/load.component';
 
@NgModule({
  declarations: [
    AppComponent,DialogContentExampleDialog,FeederfilterllistComponent,
    HomeComponent,StringFilterByPipe,
    MainComponent ,OutageRoasterComponent, FeederSearchDialogComponent, GraphicalDashboardComponent, SigninComponent, SupplyDialogComponent, NoModemDashComponent, VendorDetailsComponent, ServiceproviderChartComponent, RedirectComponent, OutagedetailpopupComponent, RoasterDetailsComponent, AddRoasterComponent, LoadComponent,  
   ],
  imports: [SharedComponentModule,NgxMatDatetimePickerModule, NgxMatTimepickerModule,
    BrowserModule, 
    NgxPrintModule,
    AppRoutingModule, 
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    BrowserAnimationsModule,SharedMaterialModule,
    NgxMatNativeDateModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],entryComponents:[FeederSearchDialogComponent,DialogContentExampleDialog,FeederfilterllistComponent,SupplyDialogComponent,ServiceproviderChartComponent],
  providers:[DatePipe],
  bootstrap: [AppComponent],
 

})
export class AppModule { }
